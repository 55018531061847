import * as moment from 'moment-timezone';
import { ReservationStatus } from './modules/park-place-select/classes/ReservationStatus';
import { ErrorDetails } from './classes/ErrorDetails';
import { HttpErrorResponse } from '@angular/common/http';

export class Helper {
  static validateByRegex(plateNumber: string, regex: string): boolean {
    let regExp = new RegExp(regex, 'g');
    return regExp.test(plateNumber);
  }

  static getReservationElapsedSec(reservationStatus: ReservationStatus): number {
    const startTimeTz = reservationStatus.created_at_formatted;
    const startTime = moment(startTimeTz).tz(moment.tz.guess()).unix();
    const currentTime = moment().tz(moment.tz.guess()).unix();

    return currentTime - startTime;
  }

  static getErrorDetails(error: HttpErrorResponse): ErrorDetails {
    let errorBody = error.error;
    let errorDetails: ErrorDetails = {};

    if (errorBody.error) {
      errorDetails['message'] = errorBody.error;

      if (errorBody.error_detail) {
        errorDetails['detail'] = errorBody.error_detail;
      }
    } else if (errorBody.message) {
      errorDetails['message'] = errorBody.message;
    }

    if (!errorDetails['message']) {
      errorDetails['message'] = 'server_error_happened';
    }

    return errorDetails;
  }

  static getFileNameFromPath(path: string): string {
    return path.replace(/^.*[\\\/]/, '');
  }
}
