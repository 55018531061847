import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../services/modal.service';
import { ReleaseSlotDatePickerModalComponent } from './components/release-slot-date-picker-modal/release-slot-date-picker-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-release-parking-slot',
  templateUrl: './release-parking-slot.component.html',
  styleUrls: ['./release-parking-slot.component.scss'],
})
export class ReleaseParkingSlotComponent implements OnInit {
  token: string;

  constructor(private modalService: ModalService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.getToken();

    this.modalService.openCustomModal(
      ReleaseSlotDatePickerModalComponent,
      { token: this.token },
      'date-picker-modal'
    );
  }

  private getToken(): void {
    this.token = this.activatedRoute.snapshot.queryParams?.token;
  }
}
