import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Log } from '../components/logs/classes/Log';
import { CONSTS } from '../../../constants';
import { LogCategory } from '../components/logs/classes/LogCategory';
import { GridOptions } from 'ag-grid-community';
import { HttpService } from '../../../services/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  protected selectedGridOptions: GridOptions;

  constructor(private httpService: HttpService) {}

  getLogList(from: string, to: string, categoryId: string): Observable<Log[]> {
    return this.httpService.get(CONSTS.API_END_POINT.LOG, null, null, { from, to, categoryId });
  }

  getLogCategories(): Observable<LogCategory[]> {
    return this.httpService.get(`${CONSTS.API_END_POINT.LOG}/categories`);
  }

  setSelectedGridOptions(gridOptions: GridOptions): void {
    this.selectedGridOptions = gridOptions;
  }

  getSelectedGridOptions(): GridOptions {
    return this.selectedGridOptions;
  }
}
