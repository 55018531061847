<app-modal-header [activeModal]="activeModal"></app-modal-header>

<form #userForm="ngForm" class="edit-user-form">
    <div class="modal-body">
        <img alt="user" class="user-icon" [src]="CONSTS.ICON_URL.USER"/>

        <div class="row form-row">
            <div class="col-12">
                <div class="form-group form-title">
                    {{ editMode ? "Edit user data" : "Add new user" }}
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <input [ngClass]="{
                                'has-error':
                                 isEmpty(tmpUser.name) &&
                                 (name.dirty || name.touched || saveClicked)
                            }"
                           #name="ngModel"
                           class="form-control"
                           type="text"
                           name="name"
                           placeholder="Name"
                           [(ngModel)]="tmpUser.name"/>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <input [ngClass]="{
                              'has-error':
                                    isGarageUser &&
                                    isEmpty(tmpUser.card_number) &&
                                    (card_number.dirty || card_number.touched || saveClicked)
                            }"
                           #card_number="ngModel"
                           class="form-control"
                           type="text"
                           name="card_number"
                           placeholder="Parking Card ID"
                           [(ngModel)]="tmpUser.card_number"/>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <app-role-dropdown-list-select
                        [(isVisitor)]="isVisitor"
                        [(isGarageUser)]="isGarageUser"
                        [saveClicked]="saveClicked"
                        [(selectedRoles)]="tmpUser.roles">
                    </app-role-dropdown-list-select>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <input [ngClass]="{
                              'has-error':
                                    !isVisitor &&
                                    (isEmpty(tmpUser.email) || !!email.errors) &&
                                    (email.dirty || email.touched || saveClicked)
                            }"
                           class="form-control"
                           type="text"
                           #email="ngModel"
                           [pattern]="CONSTS.PATTERNS.EMAIL"
                           placeholder="Email"
                           name="email"
                           [(ngModel)]="tmpUser.email"/>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <app-lpn-dropdown-list-edit
                        [isGarageUser]="isGarageUser"
                        [isVisitor]="isVisitor"
                        [saveClicked]="saveClicked"
                        [(dataList)]="tmpUser.licence_plate_numbers">
                    </app-lpn-dropdown-list-edit>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <input [ngClass]="{
                              'has-error':
                                    (isEmpty(tmpUser.phone) || !!phone.errors) &&
                                 (phone.dirty || phone.touched || saveClicked)
                            }"
                           #phone="ngModel"
                           class="form-control"
                           type="text"
                           [pattern]="CONSTS.PATTERNS.PHONE"
                           placeholder="Phone"
                           name="phone"
                           [(ngModel)]="tmpUser.phone"/>
                </div>
            </div>

            <div class="col-12">
                <div class="form-divider"></div>
            </div>

            <div class="col-12 special-flag-selector">
                <div class="form-group">
                    <app-special-flag-dropdown-list-select
                        [(selectedFlags)]="tmpUser.flags">
                    </app-special-flag-dropdown-list-select>
                </div>
            </div>

            <div class="col-12 private-slot-selector">
                <div class="form-group"
                     [container]="'.edit-user-form'"
                     [delay]="500"
                     [tooltip]="!tmpUser.email ? 'User must have email address.' : undefined">

                    <app-private-slot-dropdown-list-select
                        [disabled]="!tmpUser.email"
                        [(selectedParkingSlotNumber)]="tmpUser.private_parking_slot_number">
                    </app-private-slot-dropdown-list-select>
                </div>
            </div>

            <div class="col-12">
                <div *ngIf="tmpUser.private_parking_slot_number" class="form-group">
                    <input [ngClass]="{'has-error': !!assistant_email.errors}"
                           class="form-control"
                           type="text"
                           #assistant_email="ngModel"
                           [pattern]="CONSTS.PATTERNS.EMAIL"
                           placeholder="Assistant email address"
                           name="assistant_email"
                           [(ngModel)]="tmpUser.assistant_email"/>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div (click)="activeModal.dismiss()" class="modal-button-link">
            <a>CANCEL</a>
        </div>
        <div (click)="saveUser(userForm.valid)" class="modal-button-link">
            <a>SAVE</a>
        </div>
    </div>
</form>
