<div *ngIf="showLoader" class="loader-container">
    <app-loader [showLoader]="true"></app-loader>
</div>

<div *ngIf="!showLoader">
    <ag-grid-angular
        id="took-my-place-table"
        class="ag-theme-bootstrap"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
