import { Component, Input } from '@angular/core';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
  @Input() mobile = false;

  constructor(public appConfig: AppConfig) {}
}
