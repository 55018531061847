import { Component, OnInit } from '@angular/core';
import { AuthService } from './modules/auth/auth.service';
import { DeviceService } from './services/device.service';
import { environment } from '../environments/environment';
import { AppConfig } from './app.config';
import { FirebaseMessagingService } from './services/firebase-messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  isMobile: boolean;
  message: any;

  constructor(
    private deviceService: DeviceService,
    public authService: AuthService,
    private appConfig: AppConfig,
    private messagingService: FirebaseMessagingService
  ) {}

  ngOnInit(): void {
    this.messagingService.receiveMessage();

    this.isMobile = this.deviceService.isMobile();
    const version = this.appConfig.getVersion();

    console.log('ENV: ', environment.name);
    console.log('VERSION: ', version);

    if (environment.production) {
      window.console.info = () => {
        // disable console log
      };
      window.console.log = () => {
        // disable console log
      };
      window.console.warn = () => {
        // disable console log
      };
    }
  }
}
