import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SpecialFlag } from '../../../../../../../classes/SpecialFlag';
import { SpecialFlagService } from '../../../../../services/special-flag.service';

@Component({
  selector: 'app-special-flag-dropdown-list-select',
  templateUrl: './special-flag-dropdown-list-select.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SpecialFlagDropdownListSelectComponent implements OnInit {
  specialFlags: SpecialFlag[] = [];

  @Input() selectedFlags: SpecialFlag[] = [];
  @Output() selectedFlagsChange = new EventEmitter<SpecialFlag[]>();

  constructor(private flagService: SpecialFlagService) {}

  ngOnInit() {
    this.getSpecialFlagList();
  }

  selectFlag(flag: SpecialFlag): void {
    const index = this.selectedFlags.findIndex((item) => item.id === flag.id);

    if (index === -1) {
      this.selectedFlags.push(flag);
    } else {
      this.selectedFlags.splice(index, 1);
    }

    this.selectedFlagsChange.emit(this.selectedFlags);
  }

  getSelectTitle() {
    let title = 'Choose special Flag';

    if (this.selectedFlags?.length === 1) {
      title = this.selectedFlags[0].name;
    } else if (this.selectedFlags?.length > 1) {
      title = this.selectedFlags.length + ' Flags';
    }

    return title;
  }

  private getSpecialFlagList(): void {
    this.flagService.getSpecialFlagList().subscribe({
      next: (flags) => {
        this.specialFlags = flags;
      },
    });
  }
}
