<app-modal-header [activeModal]="activeModal"></app-modal-header>
<div class="modal-body">
    <div class="image"><img src="{{ imageURL }}" alt="alert icon"/></div>
    <div class="title" [innerHTML]="message"></div>

    <div *ngIf="detail" class="text" [innerHTML]="detail"></div>
</div>
<div class="modal-footer">
    <div (click)="activeModal.close()" class="modal-button-link yellow-bg">
        <a>OK</a>
    </div>
</div>
