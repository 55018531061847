import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminMenuService } from '../../../../../services/admin-menu.service';
import { TookMyPlaceParams } from '../../../../../classes/TookMyPlaceParams';

@Component({
  selector: 'app-invalidate-report-modal',
  templateUrl: './invalidate-report-modal.component.html',
  styleUrls: ['./invalidate-report-modal.component.scss'],
})
export class InvalidateReportModalComponent implements OnInit {
  @Input() data: TookMyPlaceParams;

  reportId: number;
  fromDate: string;
  toDate: string;
  onlyValid: boolean;
  description: string;

  constructor(public activeModal: NgbActiveModal, private adminMenuService: AdminMenuService) {}

  ngOnInit() {
    this.reportId = this.data.reportId;
    this.fromDate = this.data.fromDate;
    this.toDate = this.data.toDate;
    this.onlyValid = this.data.onlyValid;
  }

  invalidate(): void {
    this.adminMenuService
      .invalidateTookMyPlaceReport(
        this.reportId,
        false,
        this.fromDate,
        this.toDate,
        this.onlyValid,
        this.description
      )
      .subscribe({
        next: (modifiedRow) => {
          this.activeModal.close(modifiedRow);
        },
      });
  }
}
