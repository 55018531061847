import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { AdminComponent } from './admin.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LogsComponent } from './components/logs/logs.component';
import { UsersComponent } from './components/users/users.component';
import { AgGridModule } from 'ag-grid-angular';
import { AdminMenuService } from './services/admin-menu.service';
import { SharedModule } from '../shared/shared.module';
import { AgStatusUserCellRendererComponent } from './components/users/ag-status-user-cell-renderer/ag-status-user-cell-renderer.component';
import { FormsModule } from '@angular/forms';
import { EditUserModalComponent } from './components/users/edit-user-modal/edit-user-modal.component';
import { LpnDropdownListEditComponent } from './components/users/edit-user-modal/components/lpn-dropdown-list-edit/lpn-dropdown-list-edit.component';
import { RoleDropdownListSelectComponent } from './components/users/edit-user-modal/components/role-dropdown-list-select/role-dropdown-list-select.component';
import { NightStandsReportComponent } from './components/reports/night-stands-report/night-stands-report.component';
import { CommonComponent } from './components/common/common.component';
import { TookMyPlaceReportComponent } from './components/reports/took-my-place-report/took-my-place-report.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpecialFlagsComponent } from './components/special-flags/special-flags.component';
import { IsSelectedTabPipe } from './pipes/is-selected-tab.pipe';
import { EditSpecialFlagModalComponent } from './components/special-flags/edit-special-flag-modal/edit-special-flag-modal.component';
import { SpecialFlagDropdownListSelectComponent } from './components/users/edit-user-modal/components/special-flag-dropdown-list-select/special-flag-dropdown-list-select.component';
import { PrivateSlotDropdownListSelectComponent } from './components/users/edit-user-modal/components/private-slot-dropdown-list-select/private-slot-dropdown-list-select.component';
import { InvalidateReportModalComponent } from './components/reports/took-my-place-report/components/invalidate-report-modal/invalidate-report-modal.component';
import { LogTableComponent } from './components/logs/components/log-table/log-table.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    AgGridModule.withComponents([]),
    TooltipModule,
  ],
  providers: [AdminMenuService, JsonPipe],
  declarations: [
    AdminComponent,
    ReportsComponent,
    LogsComponent,
    UsersComponent,
    AgStatusUserCellRendererComponent,
    EditUserModalComponent,
    LpnDropdownListEditComponent,
    RoleDropdownListSelectComponent,
    NightStandsReportComponent,
    CommonComponent,
    TookMyPlaceReportComponent,
    SpecialFlagsComponent,
    IsSelectedTabPipe,
    EditSpecialFlagModalComponent,
    SpecialFlagDropdownListSelectComponent,
    PrivateSlotDropdownListSelectComponent,
    InvalidateReportModalComponent,
    LogTableComponent,
  ],
  exports: [AdminComponent],
})
export class AdminModule {}
