<div class="header">
    <div class="floor-title"
         (click)="isFloor1 || switchFloor(1)"
         [ngClass]="{ 'floor-select': !isFloor1 }">
        <div class="circle-floor" [ngClass]="{ 'fade-in': isFloor1 }">Floor</div>
        <span class="number-circle-header circle-title">
            <span class="number-content">-1</span>
        </span>
    </div>

    <div (click)="toFloorSelect()"
         title="go to floor select"
         class="home-icon hover-bigger">
        <img alt="home" src="/assets/images/icon_Home.svg"/>
    </div>
    <div class="white-space">
        <div *ngIf="isAdminOrReceptionist()"
             (click)="refreshMap()"
             title="refresh parking slots"
             class="refresh-map-icon hover-bigger">
            <img alt="refresh"
                 src="/assets/images/refresh.svg"
                 [ngStyle]="{ transform: 'rotate(' + rotateRefresh + 'deg)' }"
            />
        </div>

        <div *ngIf="authService.isAdminOrReceptionist"
             (click)="openPreReservationTab()"
             class="all-booking hover-bigger-less">
            <img alt="reservations"
                 src="/assets/images/icon_reservations.svg"/>
            all bookings
        </div>
    </div>
    <div (click)="zoomIn()" title="zoom in" class="plus-icon">
        <img alt="zoom in" src="/assets/images/zoom_in.svg"/>
    </div>
    <div (click)="zoomOut()" title="zoom out" class="minus-icon">
        <img alt="zoom out" src="/assets/images/zoom_out.svg"/>
    </div>

    <div class="floor-title"
         (click)="!isFloor1 || switchFloor(2)"
         [ngClass]="{ 'floor-select': isFloor1 }">
        <div class="circle-floor" [ngClass]="{ 'fade-in': !isFloor1 }">Floor</div>
        <span class="number-circle-header circle-title">
            <span class="number-content">-2</span>
        </span>
    </div>
</div>

<app-all-pre-reservation-tab *ngIf="authService.isAdminOrReceptionist">
</app-all-pre-reservation-tab>
