import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { UserResetPasswordData } from '../../classes/UserResetPasswordData';
import { ModalService } from '../../../../services/modal.service';
import { NgModel } from '@angular/forms';
import { LoaderService } from '../../../../services/loader.service';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-new-password-form',
  templateUrl: './login-new-password-form.component.html',
  styleUrls: ['./login-new-password-form.component.scss'],
})
export class LoginNewPasswordFormComponent {
  @ViewChild('new_password_confirm') new_password_confirm: NgModel;
  @ViewChild('new_password') new_password: NgModel;

  userResetPasswordData: UserResetPasswordData = {
    password: '',
    password_confirm: '',
  };

  protected readonly CONSTS = CONSTS;

  constructor(
    private modalService: ModalService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private router: Router
  ) {}

  openPasswordTooltip(template: TemplateRef<any>) {
    this.modalService.openFromTemplate(template);
  }

  changePassword() {
    this.loaderService.showLoader();

    this.authService.changePassword(this.userResetPasswordData).subscribe({
      next: () => {
        this.loaderService.hideLoader();
        this.router.navigate([CONSTS.PAGE_URL.FLOOR_SELECT]);
      },
      error: () => {
        this.loaderService.hideLoader();
      },
    });
  }

  logout() {
    this.authService.logout();

    if (this.authService.isAdminOrReceptionistRole()) {
      this.router.navigate([CONSTS.PAGE_URL.ADMIN_LOGIN]);
    } else {
      this.router.navigate([CONSTS.PAGE_URL.LOGIN]);
    }
  }

  get newPasswordConfirmationHasError(): boolean {
    return (
      (this.new_password_confirm?.errors ||
        this.userResetPasswordData.password !== this.userResetPasswordData.password_confirm) &&
      (this.new_password_confirm.dirty || this.new_password_confirm.touched)
    );
  }

  get newPasswordHasError(): boolean {
    if (!this.new_password?.errors) {
      return null;
    }
    return this.new_password?.errors && (this.new_password.dirty || this.new_password.touched);
  }

  get areUserPasswordsSame(): boolean {
    return this.userResetPasswordData.password !== this.userResetPasswordData.password_confirm;
  }
}
