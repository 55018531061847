import { tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { CONSTS } from '../constants';
import { AuthService } from '../modules/auth/auth.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { Helper } from '../helper';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  ignoreErrorMessage = ['licence_plate_number_does_not_exists'];

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthService);

    // ignore error for the requests having ignore header, remove this header from ws call
    let ignoreErrorHeader = false;
    let requestHeaders = request.headers.getAll(CONSTS.HTTP.IGNORE_ERROR);

    if (requestHeaders && requestHeaders.length > 0) {
      ignoreErrorHeader = Boolean(request.headers.getAll(CONSTS.HTTP.IGNORE_ERROR)[0]);
    }

    let withCredentials = false;
    let header: { [key: string]: string } = {};

    if (auth.authToken) {
      header['Authorization'] = `Bearer ${auth.authToken}`;
      withCredentials = true;
    }
    request = request.clone({
      setHeaders: header,
      withCredentials: withCredentials,
    });

    return next.handle(request).pipe(
      tap({
        error: (err) => {
          // handle errors
          if (err instanceof HttpErrorResponse) {
            this.handleError(err, request, auth, ignoreErrorHeader);
          }
        },
      })
    );
  }

  handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    authService: AuthService,
    ignoreErrorHeader: boolean
  ): Observable<any> {
    let modalService = this.injector.get(ModalService);

    if (error.status !== 0) {
      if (error.status !== 401 && error.status !== 403) {
        if (!ignoreErrorHeader) {
          let errorDetails = Helper.getErrorDetails(error);

          if (!this.isIgnoreErrorMessage(errorDetails['message'])) {
            modalService.openAlertModal(errorDetails['message'], errorDetails['detail']);
          }
        }
      } else {
        let authKey = localStorage.getItem('auth-token');
        if (authKey) {
          const router = this.injector.get(Router);

          // set variables to show popup after logged in user automatic logged out!
          if (request.url.indexOf('signin') === -1 && request.url.indexOf('login') === -1) {
            if (error.status === 401) {
              authService.tokenExpired = true;
            } else {
              let errorDetails = Helper.getErrorDetails(error);
              authService.permissionDeniedMessage = errorDetails['message'];
            }
          }

          let wasAdminOrReceptionist = authService.isAdminOrReceptionistRole();
          authService.logout();

          // close custom modal before navigate to prevent modal opened on login page when 401 or 403 ws happened
          modalService.closeCustomModal();

          if (wasAdminOrReceptionist) {
            router.navigate([CONSTS.PAGE_URL.ADMIN_LOGIN]);
          } else {
            router.navigate([CONSTS.PAGE_URL.LOGIN]);
          }
        }
      }
    } else {
      modalService.openAlertModal('Undefined error happened!');
    }

    // hide loader on all ws error
    let loaderService = this.injector.get(LoaderService);
    loaderService.hideLoader();

    return throwError(() => error);
  }

  isIgnoreErrorMessage(message: string) {
    for (const ignoreMessage of this.ignoreErrorMessage) {
      if (message === ignoreMessage) {
        return true;
      }
    }

    return false;
  }
}
