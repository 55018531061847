import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CONSTS } from '../../../../constants';
import { AuthService } from '../../../auth/auth.service';
import Hammer from 'hammerjs';

@Component({
  selector: 'app-floor-map',
  templateUrl: './floor-map.component.html',
  styleUrls: ['./floor-map.component.scss'],
})
export class FloorMapComponent implements OnInit {
  @ViewChild('floorSwitcher1', { static: true }) floorSwitcher1: ElementRef;
  @ViewChild('floorSwitcher2', { static: true }) floorSwitcher2: ElementRef;

  @Input() floorNumber: number;

  isGarageUser: boolean;
  floorName: string = null;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit() {
    let that = this;
    this.isGarageUser = this.authService.isGarageUser;

    this.route.params.subscribe((params: Params) => {
      this.setFloor(params);
    });

    new Hammer(this.floorSwitcher1.nativeElement).on('panright', () => {
      that.switchFloor(1);
    });
    new Hammer(this.floorSwitcher2.nativeElement).on('panleft', () => {
      that.switchFloor(2);
    });
  }

  setFloor(params: Params) {
    this.floorName = params['floor'];
  }

  get isFloor1() {
    return this.floorName === CONSTS.PAGE_URL_PARAM.FLOOR1;
  }

  switchFloor(floor: number) {
    this.router.navigate([CONSTS.PAGE_URL.FLOOR[floor.toString()]]);
  }
}
