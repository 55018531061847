import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CellClickedEvent,
  GridOptions,
  ICellRendererParams,
  RowDataTransaction,
  GetRowIdParams,
} from 'ag-grid-community';
import { User } from '../../../../classes/User';
import { AdminMenuService } from '../../services/admin-menu.service';
import { AgStatusUserCellRendererComponent } from './ag-status-user-cell-renderer/ag-status-user-cell-renderer.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { ModalService } from '../../../../services/modal.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalConfig } from '../../../shared/classes/ModalConfig';
import { CONSTS } from '../../../../constants';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { CommonComponent } from '../common/common.component';
import { Role } from '../../../../classes/Role';
import { AgGridHelper } from '../../../../utils/ag-grid-helper.util';
import { AgGridColumnContent } from '../../../shared/util/ag-grid-column-content';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersComponent extends CommonComponent implements OnInit {
  gridOptions: GridOptions;
  showLoader: boolean = true;
  filterValue: string;
  CONSTS = CONSTS;

  constructor(
    private adminMenuService: AdminMenuService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.initGridOptions();

    this.getUserList();
  }

  setUserListTableFilter(filterValue: string) {
    this.gridOptions.api.setQuickFilter(filterValue);
  }

  getUserList() {
    this.clearGetListSub();

    this.getListSub = this.adminMenuService.getUserList().subscribe({
      next: (users: Array<User>) => {
        this.gridOptions.rowData = users;
        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  addUser() {
    this.modalService.openCustomModal(EditUserModalComponent, { editMode: false }, 'edit-item-modal').then(
      (user: User) => {
        // remove row from ag-grid
        let transaction: RowDataTransaction = {
          add: [user],
        };
        this.gridOptions.api.applyTransaction(transaction);

        this.loaderService.hideLoader();
      },
      () => {
        // reject ignored
      }
    );
  }

  deleteUser(userID: number) {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.DELETE_USER, CONSTS.MODAL.DELETE_USER))
      .then(
        () => {
          this.adminMenuService.deleteUser(userID).subscribe(() => {
            // remove row from ag-grid
            let transaction: RowDataTransaction = {
              remove: [{ id: userID }],
            };
            this.gridOptions.api.applyTransaction(transaction);

            this.loaderService.hideLoader();
          });
        },
        () => {
          // reject ignored
        }
      );
  }

  resetPassword(userID: number) {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.SEND_MAIL, CONSTS.MODAL.RESET_PASSWORD))
      .then(
        () => {
          this.adminMenuService.resetUserPassword(userID).subscribe(() => {
            this.loaderService.hideLoader();
          });
        },
        () => {
          // reject ignored
        }
      );
  }

  editUser(editUser: User) {
    this.modalService
      .openCustomModal(
        EditUserModalComponent,
        {
          editMode: true,
          user: editUser,
        },
        'edit-item-modal'
      )
      .then(
        (user: User) => {
          let transaction: RowDataTransaction = {
            update: [user],
          };
          this.gridOptions.api.applyTransaction(transaction);
          editUser = user;

          this.loaderService.hideLoader();
        },
        () => {
          // reject ignored
        }
      );
  }

  switchBanUser(params: CellClickedEvent) {
    this.adminMenuService.switchBanUser(params, this.gridOptions);
  }

  private initGridOptions(): void {
    const componentInstance = this;

    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      cacheQuickFilter: true,
      rowHeight: 50,
      headerHeight: 50,
      rowData: [],
      rowSelection: 'single',
      onGridReady: () => {
        if (componentInstance.gridOptions.api) {
          componentInstance.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (componentInstance.gridOptions.api) {
          componentInstance.gridOptions.api.sizeColumnsToFit();
        }
      },
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as User;
        return rowData.id.toString();
      },
      onCellClicked: (params: CellClickedEvent) => {
        const eventTarget = params.event.target as HTMLElement;
        const rowData = params.data as User;

        if (eventTarget.className.indexOf('delete-cell-icon') !== -1) {
          componentInstance.deleteUser(rowData.id);
        } else if (eventTarget.className === 'reset-password-user-cell') {
          componentInstance.resetPassword(rowData.id);
        } else if (eventTarget.className.indexOf('edit-cell-icon') !== -1) {
          componentInstance.editUser(rowData);
        } else if (eventTarget.className.indexOf('ban-btn') !== -1) {
          componentInstance.switchBanUser(params);
        }
      },
      columnDefs: [
        {
          width: 90,
          suppressSizeToFit: true,
          headerName: 'User ID',
          resizable: true,
          sortable: true,
          field: 'id',
          cellClass: 'col-grey-color',
        },
        {
          width: 150,
          suppressSizeToFit: true,
          headerName: 'Card Number',
          resizable: true,
          sortable: true,
          field: 'card_number',
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Name',
          field: 'name',
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Email',
          field: 'email',
          resizable: true,
          sortable: true,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Phone',
          field: 'phone',
          resizable: true,
          sortable: true,
        },
        Object.assign(AgGridHelper.plateNumberColDef, {
          width: 165,
          resizable: true,
        }),
        {
          headerName: 'Permission',
          field: 'roles',
          resizable: true,
          sortable: true,
          cellRenderer: (params: ICellRendererParams) => {
            const cellValue = params.value as Array<Role>;
            return cellValue.map((role) => role.name).join(', ');
          },
          getQuickFilterText: (params) => {
            const cellValue = params.value as Array<Role>;
            return cellValue.map((role) => role.name).join(' ');
          },
          cellClass: 'col-grey-color',
          comparator: (valueA: Array<Role>, valueB: Array<Role>) => {
            let val1 = valueA.map((lpn) => lpn.name).join(', ');
            let val2 = valueB.map((lpn) => lpn.name).join(', ');

            return val1.localeCompare(val2);
          },
        },
        {
          headerName: 'Password Reset',
          field: 'roles',
          width: 145,
          resizable: true,
          sortable: false,
          filter: false,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            let isVisitor = false;
            for (let role of params.data.roles) {
              if (role.slug === CONSTS.ROLE.VISITOR) {
                isVisitor = true;
                break;
              }
            }
            if (!isVisitor) {
              return (
                '<div class="reset-password-user-cell" title="reset user password">' +
                '<img class="normal-icon" src="' +
                CONSTS.ICON_URL.SEND_MAIL +
                '"> ' +
                '<img class="hover-icon" src="' +
                CONSTS.ICON_URL.SEND_MAIL_WHITE +
                '"> ' +
                'RESET' +
                '</div>'
              );
            } else {
              return ' ';
            }
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 130,
          resizable: false,
          sortable: true,
          filter: false,
          suppressSizeToFit: true,
          cellRenderer: AgStatusUserCellRendererComponent,
          getQuickFilterText: (params) => {
            return params.value ? 'active' : 'Inactive';
          },
        },
        {
          headerName: 'Ban/Enable',
          field: 'banned',
          width: 120,
          resizable: false,
          filter: false,
          suppressSizeToFit: true,
          cellRenderer: this.adminMenuService.banEnableCellRenderer,
          getQuickFilterText: this.adminMenuService.banEnableFilterText,
        },
        {
          headerName: '',
          field: 'id',
          width: 100,
          resizable: false,
          filter: false,
          sortable: false,
          suppressSizeToFit: true,
          cellRenderer: () => AgGridColumnContent.crudActionsCell(),
        },
      ],
      rowClassRules: {
        'resolved-row': (params) => {
          return params.data.handled === true;
        },
      },
    };
  }
}
