import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Reservation } from '../classes/Log';
import { LogTableCommonColumns } from '../classes/LogTableCommonColumns';
import * as moment from 'moment/moment';
import { LogTableComponent } from '../components/log-table/log-table.component';
import { LogTableConfigUtil } from '../../../classes/LogTableConfigUtil';

export class ReservationLogTableConfig {
  static getGridOptions(instance: LogTableComponent): GridOptions {
    return {
      ...LogTableConfigUtil.getCommonOptions<Reservation>(instance),
      columnDefs: LogTableCommonColumns.concat([
        {
          headerName: 'Slot User ID',
          field: 'slot_user_id',
          resizable: true,
          sortable: true,
          cellClass: 'col-grey-color',
          width: 120,
        },
        {
          headerName: 'Slot User Name',
          field: 'slot_user_name',
          resizable: true,
          sortable: true,
          cellClass: 'col-grey-color',
          width: 150,
        },
        {
          headerName: 'Slot Number',
          field: 'slot_number',
          resizable: true,
          sortable: true,
          width: 120,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Is Final',
          field: 'is_final',
          resizable: true,
          sortable: true,
          width: 80,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Time',
          field: 'created_at',
          resizable: true,
          sortable: true,
          width: 200,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return moment(params.value).format('MM-DD HH:mm');
          },
        },
        // Hidden columns for Export
        {
          headerName: 'All Slot Count',
          field: 'all_slot_count',
          hide: true,
        },
        {
          headerName: 'Reserved Slot Count',
          field: 'reserved_slot_count',
          hide: true,
        },
        {
          headerName: 'Pre-Reserved Slot Count',
          field: 'pre_reserve_slot_count',
          hide: true,
        },
        {
          headerName: 'All special Slot Count',
          field: 'all_special_slot_count',
          hide: true,
        },
        {
          headerName: 'Reserved special Slot Count',
          field: 'reserved_special_slot_count',
          hide: true,
        },
        {
          headerName: 'Pre-Reserved special Slot Count',
          field: 'pre_reserved_special_parking_slot_count',
          hide: true,
        },
        {
          headerName: 'Reserved Users',
          field: 'reserved_users',
          hide: true,
        },
      ]),
    };
  }
}
