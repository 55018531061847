import { Component, Input } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { GridOptions } from 'ag-grid-community';
import { LogService } from '../../../admin/services/log.service';

@Component({
  selector: 'app-grid-export',
  templateUrl: './grid-export.component.html',
  styleUrls: ['./grid-export.component.scss'],
})
export class GridExportComponent {
  @Input() gridOptions: GridOptions;
  @Input() csvName: string;
  @Input() allColumns: boolean = false;

  CONSTS = CONSTS;

  constructor(private logService: LogService) {}

  exportCSV() {
    this.logService.getSelectedGridOptions().api.exportDataAsCsv({
      fileName: 'parking_' + this.csvName + '.csv',
      allColumns: this.allColumns,
    });
  }
}
