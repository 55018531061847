<div class="clickable-image {{ disableAnimation ? '' : 'animated fadeIn' }}"
     [ngClass]="{ 'column-direction': columnDirection, disabled: disabled }"
>
    <a (click)="clickImage()"
       [attr.href]="imageLinkHref"
       class="cursor-pointer {{ disabled ? '' : hoverClass }}">
        <img alt="imageText"
             [style.width]="imageWidth"
             [style.height]="imageHeight"
             [src]="imageSrc"
        />
    </a>
    <a *ngIf="!isMobile || showTextOnMobile"
       class="clickable-text"
       [attr.href]="imageLinkHref">
        <div [innerHTML]="imageText" class="float-right"></div>
    </a>
</div>
