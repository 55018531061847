import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  loaderVisible = new Subject<boolean>();

  showLoader() {
    this.loaderVisible.next(true);
  }

  hideLoader() {
    this.loaderVisible.next(false);
  }

  getLoaderVisible() {
    return this.loaderVisible.asObservable();
  }
}
