import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DateTimePickerEvent } from '../date-time-picker/classes/DateTimePickerEvent';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit {
  @ViewChild('dpInput', { static: true }) dpInput: ElementRef;
  @ViewChild('dp', { static: true }) datePicker: BsDatepickerDirective;
  @ViewChild('dpToInput') dpToInput: ElementRef;
  @ViewChild('dpTo') datePickerTo: BsDatepickerDirective;

  @Input() rangePicker: boolean = false;
  @Input() defaultRangeInMonth: number = 1;
  @Input() maxRangeInMonth: number;

  @Output() onDateSelect: EventEmitter<DateTimePickerEvent> = new EventEmitter<DateTimePickerEvent>();

  minDate: Date;
  maxDate: Date;
  maxToDate: Date = new Date();
  minToDate: Date = new Date();

  selectedDate: Date;
  selectedToDate: Date;

  selectedDay: string;
  selectedToDay: string;

  bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-default' };

  ngOnInit() {
    if (this.rangePicker) {
      let date = new Date();
      date.setMonth(date.getMonth() - this.defaultRangeInMonth);

      this.selectedDate = date;
      this.selectedToDate = new Date();

      this.selectedToDay = moment(this.selectedToDay).format('dddd, DD MMMM');

      this.maxDate = new Date();
    } else {
      this.selectedDate = new Date();
    }

    this.selectedDay = moment(this.selectedDate).format('dddd, DD MMMM');
  }

  onValueChanged(selectedDate: Date) {
    let data = this.getReturnData(selectedDate, this.selectedToDate);
    this.onDateSelect.emit(data);

    if (!this.rangePicker) {
      this.dpInput.nativeElement.value = moment(selectedDate).format('DD/MM/YYYY');
    }
    this.selectedDay = moment(selectedDate).format('dddd, DD MMMM');

    if (this.rangePicker) {
      this.minToDate = selectedDate;

      if (this.maxRangeInMonth) {
        const maxDate = new Date(selectedDate);
        maxDate.setMonth(maxDate.getMonth() + this.maxRangeInMonth);

        this.maxToDate = maxDate;
      }
    }
  }

  onValueToChanged(selectedDate: Date) {
    let data = this.getReturnData(this.selectedDate, selectedDate);
    this.onDateSelect.emit(data);

    this.selectedToDay = moment(selectedDate).format('dddd, DD MMMM');
    this.maxDate = selectedDate;

    if (this.rangePicker && this.maxRangeInMonth) {
      const minDate = new Date(selectedDate);
      minDate.setMonth(minDate.getMonth() - this.maxRangeInMonth);

      this.minDate = minDate;
    }
  }

  setPrevDay() {
    this.selectedDate.setDate(this.selectedDate.getDate() - 1);
    this.onValueChanged(this.selectedDate);
  }

  setNextDay() {
    this.selectedDate.setDate(this.selectedDate.getDate() + 1);
    this.onValueChanged(this.selectedDate);
  }

  getReturnData(selectedDate: Date, selectedToDate: Date): DateTimePickerEvent {
    let data;
    if (this.rangePicker) {
      data = {
        date: selectedDate,
        toDate: selectedToDate,
      };
    } else {
      data = selectedDate;
    }

    return data;
  }
}
