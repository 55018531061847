<div class="selector-container">
    <div class="selector"
         [ngClass]="{ active: selectedReport === CONSTS.REPORTS.NIGHT_STAND }"
         (click)="selectReport(CONSTS.REPORTS.NIGHT_STAND)">
        Night Stands
    </div>
    <div class="selector"
         [ngClass]="{ active: selectedReport === CONSTS.REPORTS.TOOK_MY_PLACE }"
         (click)="selectReport(CONSTS.REPORTS.TOOK_MY_PLACE)">
        Took My Place
    </div>
</div>

<app-date-picker
        *ngIf="selectedReport !== ''"
        [rangePicker]="true"
        (onDateSelect)="getReportList($event)">

    <button
        *ngIf="selectedReport === CONSTS.REPORTS.TOOK_MY_PLACE"
        class="dont-show-invalidated-button h-100 px-3 ml-2"
        (click)="switchShowInvalidated()"
        [textContent]="showInvalidBtnText"
        filterElement>
    </button>
</app-date-picker>

<div class="report-container">
    <app-night-stands-report
        [selectedDateRange]="selectedDateRange"
        *ngIf="selectedReport === CONSTS.REPORTS.NIGHT_STAND">
    </app-night-stands-report>

    <app-took-my-place-report
        [onlyValid]="onlyValid"
        [selectedDateRange]="selectedDateRange"
        *ngIf="selectedReport === CONSTS.REPORTS.TOOK_MY_PLACE">
    </app-took-my-place-report>
</div>
