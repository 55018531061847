import { Component } from '@angular/core';
import { FooterGarageUserComponent } from '../footer-garage-user.component';

@Component({
  selector: 'app-footer-garage-user-mobile',
  templateUrl: '../footer-garage-user-mobile.component.html',
  styleUrls: ['../footer-garage-user.component.scss'],
})
export class FooterGarageUserMobileComponent extends FooterGarageUserComponent {
  mode = 'MOBILE';
}
