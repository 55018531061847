import { Component, Input } from '@angular/core';
import { CONSTS } from '../../../../../../../constants';
import { Tooltip } from '../../../../../../../classes/Tooltip';
import { AuthService } from '../../../../../../auth/auth.service';
import { User } from '../../../../../../../classes/User';

@Component({
  selector: 'app-tooltip-garage-user',
  templateUrl: './tooltip-garage-user.component.html',
  styleUrls: ['./tooltip-garage-user.component.scss'],
})
export class TooltipGarageUserComponent {
  @Input() tooltipData: Tooltip;
  @Input() floorNumber: number;

  currentUser: User;

  CONSTS = CONSTS;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.getCurrentUser();
  }
}
