import { Component } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { LoaderService } from '../../../../services/loader.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../../services/modal.service';
import { Helper } from '../../../../helper';
import { CONSTS } from '../../../../constants';
import { FirebaseMessagingService } from '../../../../services/firebase-messaging.service';

@Component({
  selector: 'app-login-base',
  template: '<ng-content></ng-content>',
})
export class LoginBaseComponent<FormDataType> {
  formData: FormDataType;
  redirectUrl = CONSTS.PAGE_URL.FLOOR_SELECT;
  endpoint: string;

  constructor(
    protected messagingService: FirebaseMessagingService,
    protected authService: AuthService,
    protected loaderService: LoaderService,
    protected router: Router,
    protected modalService: ModalService
  ) {}

  login() {
    this.authService.login(this.formData, this.endpoint).subscribe({
      next: () => {
        this.messagingService.requestPermission();
        this.loaderService.hideLoader();
        this.router.navigate([this.redirectUrl]);
      },
      error: (error) => {
        this.loaderService.hideLoader();

        if (error.status === 401) {
          this.modalService.openAlertModal('invalid_credentials');
        } else if (error.status === 403) {
          const errorDetails = Helper.getErrorDetails(error);
          this.modalService.openAlertModal(errorDetails['message'], errorDetails['detail']);
        }
      },
    });
  }
}
