import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInSelectedList',
  pure: false,
})
export class IsInSelectedListPipe<T extends { [id: string]: string | number }> implements PipeTransform {
  transform(elementList: T[], element: T): boolean {
    let isInList = false;

    for (const item of elementList) {
      if (item?.id === element?.id) {
        isInList = true;
      }
    }

    return isInList;
  }
}
