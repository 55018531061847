import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelectedTab',
})
export class IsSelectedTabPipe implements PipeTransform {
  transform(activeTab: string, selectedTab: string): boolean {
    return activeTab === selectedTab;
  }
}
