import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() isFullScreen: boolean;
  @Input() showLoader: boolean;
  @Input() loaderImageWidth: string = '10rem';

  constructor(private loaderService: LoaderService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.isFullScreen) {
      this.loaderService.getLoaderVisible().subscribe((loaderVisible) => {
        this.showLoader = loaderVisible;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  agInit() {
    this.showLoader = true;
  }
}
