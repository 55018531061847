<form #userLoginForm=ngForm>
    <div class="title">LOGIN</div>

    <div class="form-group">
        <input required
               type="email"
               class="form-control"
               id="email"
               name="email"
               placeholder="email"
               [pattern]="CONSTS.PATTERNS.EMAIL"
               [(ngModel)]="formData.email"/>
    </div>

    <div class="form-group">
        <input type="password"
               class="form-control"
               id="user_password"
               name="user_password"
               autocomplete
               required
               placeholder="password"
               [(ngModel)]="formData.password"/>
    </div>

    <button [disabled]="userLoginForm.invalid"
            (click)="userLogin()"
            class="btn btn-primary btn-block">
        {{CONSTS.FORM.SUBMIT_TITLE}}
    </button>
</form>
