<app-modal-header [activeModal]="activeModal"></app-modal-header>

<div class="modal-body">
    <div class="modal-title my-4">Invalidation</div>

    <textarea
        [(ngModel)]="description"
        class="description-textarea"
        placeholder="The reason for invalidation here"
        [rows]="10">
    </textarea>

    <div class="modal-footer mx-5">
        <div (click)="activeModal.dismiss()" class="modal-button-link">
            <a>CANCEL</a>
        </div>

        <div (click)="invalidate()" class="modal-button-link">
            <a>SAVE</a>
        </div>
    </div>
</div>
