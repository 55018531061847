import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Log } from '../../classes/Log';
import { GridOptions } from 'ag-grid-community';
import { LogService } from '../../../../services/log.service';
import { CommonComponent } from '../../../common/common.component';
import { LogCategory } from '../../classes/LogCategory';
import { LogCategoryTypes } from '../../classes/LogCategoryTypes';
import { DebugLogTableConfig } from '../../table-configs/DebugLogTableConfig';
import { ReservationLogTableConfig } from '../../table-configs/ReservationLogTableConfig';
import { CardSyncLogTableConfig } from '../../table-configs/CardSyncLogTableConfig';
import { ExceptionLogTableConfig } from '../../table-configs/ExceptionLogTableConfig';

@Component({
  selector: 'app-log-table',
  templateUrl: './log-table.component.html',
  styleUrls: ['./log-table.component.scss'],
})
export class LogTableComponent extends CommonComponent implements OnInit, OnChanges {
  @Input() fromDate: string;
  @Input() toDate: string;
  @Input() category: LogCategory;
  @Input() filterText: string;

  showLoader: boolean = true;
  gridOptions: GridOptions;
  logList: Log[];

  readonly LOG_CATEGORY_TYPES = LogCategoryTypes;

  constructor(protected logService: LogService) {
    super();
  }

  ngOnInit() {
    this.setGridOptions();
    this.getLogList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('category')) {
      this.showLoader = true;
      this.setGridOptions();
      this.getLogList();
    }
    if (changes.hasOwnProperty('filterText') && this.filterText.length) {
      this.gridOptions.api?.setQuickFilter(this.filterText);
    }
    if (changes.hasOwnProperty('fromDate') || changes.hasOwnProperty('toDate')) {
      this.getLogList();
    }
  }

  getLogList(): void {
    if (this.gridOptions.api && this.gridOptions) {
      this.gridOptions.api.showLoadingOverlay();
    }

    this.clearGetListSub();

    this.getListSub = this.logService
      .getLogList(this.fromDate, this.toDate, this.category.id.toString())
      .subscribe({
        next: (logList) => {
          this.logList = logList;

          this.setTableData();
          this.showLoader = false;
        },
        error: () => {
          this.showLoader = false;
        },
      });
  }

  setTableData(): void {
    if (this.gridOptions?.api) {
      this.gridOptions.api.setRowData(this.logList);
    } else {
      this.gridOptions.rowData = this.logList;
    }
  }

  private setGridOptions(): void {
    let instance = this;

    switch (this.category.slug) {
      case this.LOG_CATEGORY_TYPES.Debug: {
        this.gridOptions = DebugLogTableConfig.getGridOptions(instance);
        break;
      }
      case this.LOG_CATEGORY_TYPES.Reservation: {
        this.gridOptions = ReservationLogTableConfig.getGridOptions(instance);
        break;
      }
      case this.LOG_CATEGORY_TYPES.CardSync: {
        this.gridOptions = CardSyncLogTableConfig.getGridOptions(instance);
        break;
      }
      case this.LOG_CATEGORY_TYPES.Exception: {
        this.gridOptions = ExceptionLogTableConfig.getGridOptions(instance);
        break;
      }
    }

    this.logService.setSelectedGridOptions(this.gridOptions);
  }
}
