<div class="content with-footer center" [ngClass]="{ mobile: isMobile }">
    <router-outlet></router-outlet>
</div>
<div class="footer" [ngClass]="{ mobile: isMobile }">
    <app-footer-login
        *ngIf="!authService.authenticated">
    </app-footer-login>

    <app-footer
            *ngIf="authService.authenticated && authService.isGarageUser">
    </app-footer>

    <app-footer-admin
            *ngIf="authService.authenticated && authService.isAdminOrReceptionist">
    </app-footer-admin>
</div>


<app-loader [isFullScreen]="true"></app-loader>
