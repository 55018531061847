<div class="alerts-icon">
    <app-clickable-image
            title="open alerts"
            [imageHeight]="'5rem'"
            [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
            [imageText]="'ISSUES'"
            (onImageClick)="openTab()"
            [columnDirection]="true"
    ></app-clickable-image>
    <div class="unhandled-count-indicator">
        {{ unhandledCount }}
    </div>
</div>

<div #alertTabContainer class="alerts-tab-container">
    <div class="alerts-tab-header">
        <div class="tab-title">ISSUES</div>

        <div class="tab-header-right">
            <div (click)="switchUnhandledMode()"
                 class="tab-title-switch"
                 title="switch to TO-DO list"
                 [ngClass]="{ active: showOnlyUnhandled }">
                TO-DO
            </div>
            <div (click)="switchHistoryMode()"
                 class="tab-title-switch"
                 title="switch to history mode"
                 [ngClass]="{ active: !showOnlyUnhandled }">
                CHOOSE DATE
            </div>

            <div class="tab-title-close hover-bigger">
                <img alt="close alerts"
                     title="close alerts"
                     (click)="hideTab()"
                     [src]="CONSTS.ICON_URL.BACK"
                />
            </div>
        </div>
    </div>
    <div class="alerts-tab-content">
        <app-date-picker
                *ngIf="!showOnlyUnhandled"
                (onDateSelect)="getAlertList($event)">
        </app-date-picker>
        <div *ngIf="showOnlyUnhandled" class="empty-header"></div>

        <div *ngIf="showLoader" class="loader">
            <app-loader [showLoader]="true"></app-loader>
        </div>
        <div *ngIf="!showLoader">
            <ag-grid-angular
                    class="ag-theme-bootstrap alerts-tab-table"
                    [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>

    <div class="alerts-tab-icon">
        <app-clickable-image
                title="close alerts"
                [imageHeight]="'5rem'"
                [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
                [imageText]="'ISSUES'"
                (onImageClick)="hideTab()"
                [columnDirection]="true"
                [disableAnimation]="true"
        ></app-clickable-image>
        <div class="unhandled-count-indicator">
            {{ unhandledCount }}
        </div>
    </div>
</div>
<div *ngIf="tabOpened" class="alerts-tab-backdrop"></div>
