import { takeWhile } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CONSTS } from '../../constants';
import { AlertsService } from './services/alerts.service';
import { Alert } from './classes/Alert';
import { CellClickedEvent, GridOptions, ICellRendererParams, GetRowIdParams } from 'ag-grid-community';
import * as moment from 'moment';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { Subscription, timer } from 'rxjs';

declare const $: JQueryStatic;

@Component({
  selector: 'app-alerts-tab',
  templateUrl: './alerts-tab.component.html',
  styleUrls: ['./alerts-tab.component.scss'],
})
export class AlertsTabComponent implements OnInit, OnDestroy {
  @ViewChild('alertTabContainer', { static: true }) alertTabContainer: ElementRef;

  showLoader: boolean = true;
  showResolveLoader: { [key: string]: boolean } = {};

  showOnlyUnhandled: boolean = true;

  refreshData: boolean;

  alertList: Array<Alert>;
  gridOptions: GridOptions;

  unhandledCount: number;
  CONSTS = CONSTS;

  tabOpened: boolean;

  scrollbarOptions: { axis: string; theme: string };
  lastSelectedDate: string;

  getListSub: Subscription;

  constructor(private alertService: AlertsService) {}

  ngOnInit() {
    let that = this;
    this.alertList = [];
    this.tabOpened = false;

    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      cacheQuickFilter: true,
      rowHeight: 50,
      headerHeight: 50,
      rowData: [],
      onGridReady: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as Alert;
        return rowData.id.toString();
      },
      onCellClicked: (data: CellClickedEvent) => {
        if (data.colDef.field === 'handled' && !data.data.handled) {
          that.resolveAlert(data.data.id, data.rowIndex);
        }
      },
      onCellMouseOver: (params) => {
        if (params.colDef.field === 'message') {
          $(params.event.target).find('.cell-tooltip').tooltip('show');
        }
      },
      onCellMouseOut: (params) => {
        if (params.colDef.field === 'message') {
          $(params.event.target).find('.cell-tooltip').tooltip('dispose');
        }
      },
      columnDefs: [
        {
          headerName: 'Event',
          field: 'message',
          resizable: true,
          sortable: true,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              "<span class='cell-tooltip' data-placement='right' title='" +
              params.value +
              "'>" +
              params.value +
              '</span>'
            );
          },
        },
        {
          headerName: 'Time',
          field: 'created_at',
          width: 125,
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return moment(params.value).format('MM-DD HH:mm');
          },
        },
        {
          headerName: 'Status',
          field: 'handled',
          resizable: true,
          sortable: true,
          cellClassRules: {
            'handled-cell-resolved': 'x === true',
            'handled-cell-resolve': 'x === false',
          },
          width: 110,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value ? 'RESOLVED' : 'RESOLVE';
          },
        },
      ],
      rowClassRules: {
        'resolved-row': (params) => {
          return params.data.handled === true;
        },
      },
    };
    this.refreshData = true;
    this.scrollbarOptions = { axis: 'y', theme: 'metro' };

    this.initGetUnhandledCountInterval();
  }

  getAlertList(selectedDate: Date = null) {
    let selectedDateStr: string;

    if (!selectedDate) {
      if (this.lastSelectedDate) {
        selectedDateStr = moment(this.lastSelectedDate).format(CONSTS.DATE_FORMAT);
      } else {
        selectedDateStr = moment(new Date()).format(CONSTS.DATE_FORMAT);
      }
    } else {
      selectedDateStr = moment(selectedDate).format(CONSTS.DATE_FORMAT);
    }

    this.lastSelectedDate = selectedDateStr;

    this.showGridLoader();

    this.clearGetListSub();
    this.getListSub = this.alertService.getAlertlist(selectedDateStr).subscribe({
      next: (alerts: Array<Alert>) => {
        this.setGridData(alerts);
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  getAlertListUnhandled() {
    this.showGridLoader();

    this.clearGetListSub();
    this.getListSub = this.alertService.getAlertlistUnhandled().subscribe({
      next: (alerts: Array<Alert>) => {
        this.setGridData(alerts);
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  showGridLoader() {
    if (this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
    }
  }

  setGridData(data: Array<Alert>) {
    this.alertList = data;

    if (this.gridOptions.api) {
      this.gridOptions.api.setRowData(data);
    } else {
      this.gridOptions.rowData = data;
    }

    this.showLoader = false;
  }

  openTab() {
    this.showLoader = true;
    this.tabOpened = true;
    this.alertTabContainer.nativeElement.style.left = 0;

    // wait open animation to run smoothly
    setTimeout(() => {
      if (this.showOnlyUnhandled) {
        this.getAlertListUnhandled();
      } else {
        this.getAlertList();
      }
    }, 501);
  }

  hideTab() {
    this.tabOpened = false;
    this.alertTabContainer.nativeElement.style.left = '-100%';
    this.clearGetListSub();
  }

  resolveAlert(alertId: number, index: number) {
    const alertIdStr = alertId.toString();
    this.showResolveLoader[alertIdStr] = true;

    this.alertService.resolveAlert(alertIdStr).subscribe({
      next: (alert: Alert) => {
        this.alertList[index] = alert;

        // update row in ag-grid
        let rowNode = this.gridOptions.api.getRowNode(alertIdStr);
        rowNode.setData(alert);

        this.showResolveLoader[alertIdStr] = false;

        // force unhandled count refresh
        this.getUnhandledCount();
      },
      error: () => {
        this.showResolveLoader[alertIdStr] = false;
      },
    });
  }

  getUnhandledCount() {
    this.alertService.getUnhandledCount().subscribe((count) => {
      this.unhandledCount = count;
    });
  }

  initGetUnhandledCountInterval() {
    // refresh parking slots for map intervally
    timer(0, CONSTS.WS_REFRESH_ALERT_INDICATOR)
      .pipe(takeWhile(() => this.refreshData))
      .subscribe(() => {
        this.getUnhandledCount();
      });
  }

  switchUnhandledMode() {
    this.showOnlyUnhandled = true;
    this.getAlertListUnhandled();
  }

  switchHistoryMode() {
    this.showOnlyUnhandled = false;
    this.getAlertList();
  }

  clearGetListSub() {
    if (this.getListSub) {
      this.getListSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.clearGetListSub();
    this.refreshData = false;
  }
}
