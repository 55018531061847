import { ICellRendererParams } from 'ag-grid-community';
import { LicencePlateNumber } from '../classes/LicencePlateNumber';
import { GetQuickFilterTextParams } from 'ag-grid-community/dist/lib/entities/colDef';

export class AgGridHelper {
  public static plateNumberColDef = {
    suppressSizeToFit: true,
    headerName: 'Plate Number(s)',
    field: 'licence_plate_numbers',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => {
      const cellValue = params.value as Array<LicencePlateNumber>;
      return cellValue.map((lpn) => lpn.licence_plate_number).join(', ');
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
      const cellValue = params.value as Array<LicencePlateNumber>;
      return cellValue.map((lpn) => lpn.licence_plate_number).join(' ');
    },
    comparator: (valueA: Array<LicencePlateNumber>, valueB: Array<LicencePlateNumber>) => {
      let val1 = valueA.map((lpn) => lpn.licence_plate_number).join(', ');
      let val2 = valueB.map((lpn) => lpn.licence_plate_number).join(', ');

      return val1.localeCompare(val2);
    },
  };
}
