<div class="add-icon">
    <div (click)="addUser()" title="add new user" class="add-icon-div">
        <img [src]="CONSTS.ICON_URL.ADD_ICON" alt="add"/>
        <div class="add-text">ADD USER</div>
    </div>

    <input class="filter-list"
           type="text"
           title="search in user list"
           placeholder="Search"
           (ngModelChange)="setUserListTableFilter($event)"
           [ngModel]="filterValue"
           name="filterUserList"/>
</div>

<div *ngIf="showLoader" class="loader-container">
    <app-loader [showLoader]="true"></app-loader>
</div>

<div *ngIf="!showLoader">
    <ag-grid-angular
            class="ag-theme-bootstrap users-table"
            [gridOptions]="gridOptions"
    >
    </ag-grid-angular>
</div>
