import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPassed',
})
export class IsPassedPipe implements PipeTransform {
  transform(date: Date): boolean {
    if (date.toDateString() === new Date().toDateString()) {
      // need to compare the actual date separately, because of the timestamp
      return false;
    }

    return date < new Date();
  }
}
