<app-modal-header [activeModal]="activeModal"></app-modal-header>

<div class="modal-body" [class.mobile]="isMobile">
    <div class="modal-text title">Choose Date</div>

    <span class="my-4 modal-description">Choose the days of the week when you want to use our service.</span>

    <div class="date-picker d-flex justify-content-between w-100 px-3 my-5">
        <div class="d-flex align-items-center">
            <img [src]="'/assets/images/arrow_date_picker_left.svg'"
                 [class.disabled]="isCurrentWeek"
                 (click)="switchWeek('prev')"
                 class="date-picker-arrow"
                 alt="datepicker-arrow"/>
            <span class="modal-text date-range-label ml-3 d-flex">{{startDate | date: 'EEEE, d MMMM'}}</span>
        </div>

        <span class="modal-text date-range-label">-</span>

        <div class="d-flex align-items-center">
            <span class="modal-text date-range-label mr-3 d-flex">{{endDate | date: 'EEEE, d MMMM'}}</span>
            <img [src]="'/assets/images/arrow_date_picker_right.svg'"
                 [class.disabled]="weekLimitReached"
                 (click)="switchWeek('next')"
                 class="date-picker-arrow"
                 alt="datepicker-arrow"/>
        </div>
    </div>

    <div class="week-days d-flex flex-column justify-content-center">
        <div *ngFor="let day of weekDays" class="day-selector d-flex align-items-center mb-3" [class.mb-5]="isMobile" [class.disabled]="day.value | isPassed">
            <img [src]="getCheckboxType(day)" (click)="selectDay(day)" class="date-selection-checkbox" [class.mr-2]="isMobile" alt="checkbox_alt">

            <span class="modal-text weekday-label" [class.mobile]="isMobile">{{day.label}}</span>
        </div>

    </div>

    <div class="modal-footer w-100">
        <div (click)="cancelRelease()" class="modal-button-link">
            <a>CANCEL</a>
        </div>
        <div (click)="saveReleaseDates()" class="modal-button-link">
            <a>SAVE</a>
        </div>
    </div>
</div>
