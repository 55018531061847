<div #preReservationTabContainer class="pre-reservation-tab-container">
    <div class="pre-reservation-tab-header">
        <div class="tab-title">BOOKINGS for spot:</div>

        <div class="parking-slot-text">
            <div class="parking-place">{{ parkingSlot.slot_number }}</div>
            <div class="floor-number">
                Floor
                <span class="number-circle">
                    <span class="number-content"> -{{ parkingSlot.floor }} </span>
                </span>
            </div>
        </div>

        <div class="tab-header-right">
            <div (click)="switchScheduleMode()"
                 class="tab-title-switch"
                 title="switch to Scheduled list"
                 [ngClass]="{ active: showScheduled }">
                SCHEDULED
            </div>
            <div (click)="switchHistoryMode()"
                 class="tab-title-switch"
                 title="switch to history mode"
                 [ngClass]="{ active: !showScheduled }">
                CHOOSE DATE
            </div>

            <div class="tab-title-close hover-bigger">
                <img title="close pre-reservation"
                     alt="close tab"
                     (click)="closeTab()"
                     [src]="CONSTS.ICON_URL.BACK_RIGHT"
                />
            </div>
        </div>
    </div>

    <div class="pre-reservation-tab-content">
        <div class="add-icon">
            <div (click)="addPreReservation()"
                 title="add new booking"
                 class="add-icon-div">
                <img [src]="CONSTS.ICON_URL.ADD_ICON" alt="add"/>
                <div class="add-text">BOOK</div>
            </div>
        </div>

        <app-date-picker
                *ngIf="!showScheduled"
                (onDateSelect)="getPreReservationList($event)">
        </app-date-picker>

        <div *ngIf="showLoader" class="loader">
            <app-loader [showLoader]="true"></app-loader>
        </div>

        <div *ngIf="!showLoader">
            <ag-grid-angular
                    class="ag-theme-bootstrap pre-reservation-tab-table"
                    [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>
</div>
<div *ngIf="preReservationTabOpened" class="pre-reservation-tab-backdrop"></div>
