<form #userResetPasswordForm=ngForm class="d-flex flex-column justify-content-between h-100">

    <div class="d-flex align-items-center mt-2">
        <div>
            <img alt="back-to-login"
                 class="sub-title-back hover-bigger"
                 title="logout"
                 [src]="'/assets/images/logout_white.svg'"
                 (click)="backToLoginPage()"/>
        </div>

        <div class="sub-title">
            {{CONSTS.RESET_PASSWORD_TEXT}}

            <p *ngIf="!emailSendingWasSuccessful"
               class="reset-password-subtitle mt-2">
                We'll send you a new password.
            </p>
        </div>
    </div>

    <div *ngIf="!emailSendingWasSuccessful">
        <div class="form-group">
            <input required
                   id="email"
                   type="email"
                   name="email"
                   class="form-control"
                   placeholder="Add your e-mail address"
                   [pattern]="CONSTS.PATTERNS.EMAIL"
                   [(ngModel)]="userResetPasswordFormData.email"/>
        </div>

        <button class="btn btn-primary btn-block"
                [disabled]="userResetPasswordForm.invalid"
                (click)="sendPasswordResetEmail()">
            SEND
        </button>
    </div>

    <div *ngIf="emailSendingWasSuccessful" class="d-flex align-items-center h-100">
        <div>
            <img alt="password-reset-icon"
                 class="password-reset-icon"
                 title="password-reset-icon"
                 [src]="'/assets/images/success_tick.svg'"/>
        </div>

        <div class="password-reset-message">
            {{ CONSTS.PASSWORD_RESET_SUCCESSFUL_MESSAGE }}
        </div>
    </div>
</form>
