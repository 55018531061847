import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'app-clickable-image',
  templateUrl: './clickable-image.component.html',
  styleUrls: ['./clickable-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClickableImageComponent implements OnInit {
  @Input() imageWidth = 'auto';
  @Input() imageHeight = 'auto';
  @Input() imageSrc: string;
  @Input() imageText = '';
  @Input() imageLinkHref: string = null;
  @Input() columnDirection: boolean = null;
  @Input() showTextOnMobile: boolean = false;
  @Input() disabled: boolean = false;
  @Input() disableAnimation: boolean = false;
  @Input() hoverClass: string = 'hover-bigger-less';

  @Output() onImageClick: EventEmitter<any> = new EventEmitter<any>();

  isMobile: boolean;

  constructor(private deviceService: DeviceService) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  clickImage() {
    if (!this.disabled) {
      this.onImageClick.emit(true);
    }
  }
}
