import { Component, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminMenuService } from '../../../services/admin-menu.service';
import { CommonReportComponent } from '../common-report/common-report.component';
import * as _ from 'underscore';
import { ICellRendererParams, RowDataTransaction, GetRowIdParams, RowDataChangedEvent } from 'ag-grid-community';
import { RowClickedEvent } from 'ag-grid-community/dist/lib/events';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { Overnights } from '../../../classes/Overnights';
import { AgGridHelper } from '../../../../../utils/ag-grid-helper.util';

@Component({
  selector: 'app-night-stands-report',
  templateUrl: './night-stands-report.component.html',
  styleUrls: ['./night-stands-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NightStandsReportComponent extends CommonReportComponent implements OnInit, OnChanges {
  constructor(protected adminMenuService: AdminMenuService) {
    super(adminMenuService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.extendGridOptions();
  }

  protected override setQueryEndpoint() {
    this.queryEndpoint = this.adminMenuService.getOverNightReport(this.fromDate, this.toDate);
  }

  extendGridOptions() {
    let that = this;

    this.gridOptions = _.extend(this.gridOptions, {
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as Overnights;
        return rowData.id.toString();
      },
      onRowClicked: (params: RowClickedEvent) => {
        if (!params.data.expanded) {
          // row height + padding + table header height + row heights
          params.node.setRowHeight(
            this.DEFAULT_ROW_HEIGHT +
              this.INNER_TABLE_MARGIN +
              params.data.overnights_count * this.INNER_TABLE_ROW_HEIGHT
          );
          params.data.expanded = true;
        } else {
          params.node.setRowHeight(this.DEFAULT_ROW_HEIGHT);
          params.data.expanded = false;
        }

        let transaction: RowDataTransaction = {
          update: [params.data],
        };

        that.gridOptions.api.applyTransaction(transaction);
        params.api.onRowHeightChanged();
      },
      onRowDataChanged: (params: RowDataChangedEvent) => {
        params.api.getRenderedNodes().forEach((node) => {
          node.setRowHeight(this.DEFAULT_ROW_HEIGHT);
        });

        params.api.refreshClientSideRowModel();
      },
      columnDefs: [
        {
          headerName: '',
          width: 50,
          suppressSizeToFit: true,
          field: 'expanded',
          sortable: true,
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params: ICellRendererParams) => {
            let html =
              '<div title="show details" class="overnight-toggle-icon"></div>' +
              '<div class="full-width-row">' +
              '<div class="full-width-row-title">Overnight dates</div> ' +
              '<table class="table">';

            for (let overnight of params.data.overnights) {
              html += '<tr>' + '<td>' + overnight.day + '</td>' + '</tr>';
            }
            html += '</table>' + '</div>';

            return html;
          },
          cellClass: 'full-width-row-cell',
          cellClassRules: {
            expanded: (params: CellClassParams) => {
              return params.value === true;
            },
          },
        },
        {
          headerName: 'Card number',
          field: 'card_number',
          suppressMovable: true,
          resizable: false,
          sortable: true,
        },
        AgGridHelper.plateNumberColDef,
        {
          headerName: 'Overnight Count',
          field: 'overnights_count',
          sortable: true,
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value + ' nights spent in garage';
          },
        },
        {
          headerName: 'User name',
          field: 'name',
          sortable: true,
          suppressMovable: true,
          resizable: false,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'User Phone',
          field: 'phone',
          sortable: true,
          suppressMovable: true,
          resizable: false,
          cellClass: 'col-grey-color',
        },
      ],
    });
  }
}
