interface ConstsType {
  [key: string]: { [key: string]: { [key: string]: any } | any } | any;
}

export const CONSTS: ConstsType = {
  HTTP: {
    IGNORE_ERROR: 'ignore-error',
  },
  PARKING_SLOT_TYPES: {
    NORMAL: 'normal',
    VISITOR: 'visitor',
    PRIVATE: 'private',
    NONEY: 'non-ey',
  },
  API_END_POINT: {
    USER: 'user',
    PARKING_SLOT: 'parking-slot',
    RESERVATION: 'reservation',
    LICENCE_PLATE_NUMBER: 'licence-plate-number',
    ALERT: 'alert',
    LOG: 'log',
    REPORT: '',
    PRE_RESERVATION: 'pre-reservation',
    FAVOURITE: 'favourite-parking-slot',
    USER_LOGIN: 'signin',
    ADMIN_LOGIN: 'login',
    SPECIAL_FLAGS: 'flags',
    USER_RELEASE_DATES: 'user-release-dates',
  },
  ROLE: {
    ADMIN: 'admin',
    RECEPTIONIST: 'receptionist',
    USER: 'garage-user',
    VISITOR: 'visitor',
  },
  ROUTES: {
    LOGIN: 'login',
    ADMIN_LOGIN: 'login/admin',
    FLOOR_SELECT: 'floor-select',
    PARK_PLACE_SELECT: 'park-place-select',
    RESET_PASSWORD: 'reset-password',
    RELEASE_SLOT: 'release-private-slot',
  },
  PAGE_URL: {
    LOGIN: '/login',
    ADMIN_LOGIN: '/login/admin',
    FLOOR_SELECT: '/floor-select',
    FLOOR: {
      '1': '/park-place-select/floor-1',
      '2': '/park-place-select/floor-2',
    },
    RESET_PASSWORD: '/reset-password',
    RELEASE_SLOT: '/release-private-slot',
  },
  PAGE_URL_PARAM: {
    FLOOR1: 'floor-1',
    FLOOR2: 'floor-2',
  },

  RECEPTION_PHONE_NUMBER: '+36-1-451-8100',
  RECEPTION_SHORT_PHONE_NUMBER: '1-451-8100',

  WS_REFRESH_RESERVATION_CALL_INTERVAL: 10000,
  WS_REFRESH_PARKING_SLOTS_CALL_INTERVAL: 30000,
  WS_REFRESH_PARKING_SLOTS_DELAY: 10050,
  WS_REFRESH_ALERT_INDICATOR: 60000,

  WS_FLOOR_SELECT_CALL_INTERVAL: 10000,

  RESERVATION_TIME_MIN: 60,
  RESERVATION_DELETE_LIMIT_MIN: 10,

  RELEASE_PARKING_SPOT_WEEK_LIMIT: 3,

  ICON_URL: {
    CANCEL_RESERVATION: '/assets/images/icon_CancelReservation.svg',
    RECEPTION_CALL: '/assets/images/icon_headset.svg',
    PARKED: '/assets/images/icon_Parked.svg',
    THEY_TOOK_MY_PLACE: '/assets/images/icon_TheyTookMyPlace.svg',
    PICK_PARKING_PLACE: '/assets/images/pick_parking_place.svg',
    PICK_PARKING_PLACE_BLUE: '/assets/images/pick_parking_place_blue.svg',
    EXTEND_COUNTER: '/assets/images/icon_extendCounter.svg',
    SHOW_MY_RESERVATION: '/assets/images/login_logo.svg',
    BACK: '/assets/images/back.svg',
    BACK_DOWN: '/assets/images/back_down.svg',
    BACK_RIGHT: '/assets/images/back_right.svg',
    BACK_UP: '/assets/images/back_up.svg',
    CHECKBOX_CHECKED: 'assets/images/checkbox_checked.svg',
    CHECKBOX_UNCHECKED: 'assets/images/checkbox_unchecked.svg',

    LOGS: '/assets/images/icon-logs.svg',
    USERS: '/assets/images/icon_users.svg',
    REPORTS: '/assets/images/icon_reports.svg',
    SPECIAL_FLAGS: '/assets/images/icon_flag_edit.svg',
    PRIVATE_PROFILE: '/assets/images/icon_private_profile.svg',

    DELETE_USER: '/assets/images/icon_user_remove.svg',
    USER: '/assets/images/icon_user.svg',
    USER_ICON_TOOLTIP: '/assets/images/icon_users_tooltip.svg',
    VISITOR: '/assets/images/icon_visitor.svg',

    ADD_ICON: '/assets/images/add_icon.svg',
    ARROW_UP: '/assets/images/arrow_up.svg',
    ARROW_DOWN: '/assets/images/arrow_down.svg',
    RADIO_CHECKED: '/assets/images/radio_checked.svg',
    RADIO_UNCHECKED: '/assets/images/radio_unchecked.svg',

    SEND_MAIL: '/assets/images/icon_send_mail.svg',
    SEND_MAIL_WHITE: '/assets/images/icon_send_mail_white.svg',

    DROPDOWN_REMOVE: '/assets/images/dropdown_element_remove.svg',
    STAR_BLUE: '/assets/images/icon_plate_default.svg',
    STAR: '/assets/images/icon_plate_not_default.svg',

    INFO: '/assets/images/info.svg',
    INFO_BLUE: '/assets/images/info_lightblue.svg',
    SWAP_PLACE: '/assets/images/icon_swap_place.svg',
    CLOSE_WHITE: '/assets/images/close_white.svg',
    CLOSE: '/assets/images/close.svg',
    MORE: 'assets/images/more.svg',

    BICYCLE: '/assets/images/bicycle_icon.svg',
    BICYCLE_YELLOW: '/assets/images/bicycle_yellow_icon.svg',
    CAR: '/assets/images/car_icon.svg',

    PRE_RESERVATION: '/assets/images/icon_pre_booking.svg',
    PRE_RESERVATION_BLACK: '/assets/images/icon_reservations_black.svg',

    FAVOURITES: '/assets/images/favourites_icon.svg',

    DELETE_BLACK: '/assets/images/icon_cancel_black.svg',
    USER_TRANSPARENT: '/assets/images/user.svg',
    USER_TRANSPARENT_BLACK: '/assets/images/user_black.svg',
    USER_TRANSPARENT_WHITE: '/assets/images/user_white.svg',

    CSV_EXPORT: '/assets/images/CSV_export.svg',

    ICON_BAN: '/assets/images/icon_BAN.svg',
    ICON_ENABLE: '/assets/images/icon_ENABLE.svg',
  },
  MODAL: {
    CANCEL_RESERVATION: {
      TITLE: 'Are you sure to cancel your reservation?',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'red-bg',
    },
    CANCEL_RESERVATION_EXCEED: {
      TITLE: 'Are you sure to cancel your reservation?',
      TEXT: 'New reservation will not be possible.',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'red-bg',
    },
    CANCEL_PRE_RESERVATION: {
      TITLE: 'Are you sure to remove this booking?',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'red-bg',
    },
    FREE_UP_RESERVATION: {
      TITLE: 'Would you free up this parking place?',
      CONFIRM_BUTTON_CLASS: 'red-bg',
    },
    CANCEL_ADMIN_RESERVATION: {
      TITLE: 'Are you sure to cancel this reservation?',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'red-bg',
    },
    PARKED: {
      TITLE: 'Have you parked your car?',
      TEXT: 'Please check the number of parking place',
      CONFIRM_BUTTON_CLASS: 'green-bg',
    },
    EXTEND_COUNTER: {
      TITLE: 'Extend reservation counter?',
      TEXT: 'You can do this only once!',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
    },
    PICK_PARKING_PLACE: {
      TITLE: 'You reserve this parking place for the next 60 minutes:',
      SUB_TITLE:
        'The reservation can be changed within 10 minutes, afterwards if deleted new reservation will not be possible.',
      CONFIRM_BUTTON_TEXT: 'OK',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
      CANCEL_BUTTON_TEXT: 'CANCEL',
    },
    PICK_FAVOURITE_PLACE: {
      TITLE: 'You will add this parking place to your favourites list: ',
      CONFIRM_BUTTON_TEXT: 'OK',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
      CANCEL_BUTTON_TEXT: 'CANCEL',
    },
    PICK_PARKING_PLACE_IN_GARAGE: {
      TITLE: 'You reserve this parking place:',
      CONFIRM_BUTTON_TEXT: 'OK',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
      CANCEL_BUTTON_TEXT: 'CANCEL',
    },
    SWAP_PICK_PARKING_PLACE: {
      TITLE: 'You want to swap your reservation to this parking place?',
      CONFIRM_BUTTON_TEXT: 'OK',
      CANCEL_BUTTON_TEXT: 'CANCEL',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    ALREADY_HAVE_RESERVATION: {
      TITLE: 'You already have reservation:',
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: true,
      FOOTER_CUSTOM_CLASS: 'parked-park-place-footer',
      PARKING_SLOT_CUSTOM_CLASS: 'parked-slot-text',
      JUMP_TO_PARKING_PLACE_LINK: true,
    },
    YOUR_RESERVATION: {
      TITLE: 'Your automatic reservation:',
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: true,
      FOOTER_CUSTOM_CLASS: 'parked-park-place-footer',
      PARKING_SLOT_CUSTOM_CLASS: 'parked-slot-text',
      JUMP_TO_PARKING_PLACE_LINK: true,
      SHOW_WITH_BICYCLE: true,
    },
    CAR_PARKED: {
      TITLE: 'Car parked succesfully here:',
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: true,
      FOOTER_CUSTOM_CLASS: 'parked-park-place-footer',
      PARKING_SLOT_CUSTOM_CLASS: 'parked-slot-text',
      JUMP_TO_PARKING_PLACE_LINK: true,
    },
    THEY_TOOK_MY_PLACE: {
      TITLE: 'Send us the licence plate number of the car occupying your place',
      TITLE_TEXT: 'use format: {{PLATE_NUMBER_FORMAT}}',
      TEXT: "If there's a reservation for this vehicle, your reservations will be swapped",
      PLATE_NUMBER_INPUT: true,
      CONFIRM_BUTTON_TEXT: 'SEND',
      CANCEL_BUTTON_TEXT: 'CANCEL',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    THEY_TOOK_MY_PLACE_ADMIN: {
      TITLE: 'Send the licence plate number of the car occupying this place',
      TITLE_TEXT: 'use format: {{PLATE_NUMBER_FORMAT}}',
      TEXT: "If there's a reservation for this vehicle, this reservations will be swapped",
      PLATE_NUMBER_INPUT: true,
      CONFIRM_BUTTON_TEXT: 'SEND',
      CANCEL_BUTTON_TEXT: 'CANCEL',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    THEY_TOOK_MY_PLACE_CONFIRM_VISITOR: {
      TITLE: 'There is no other parking place left! ',
      TEXT: 'Are you sure you want to cancel this parking place? ',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
    },
    NEW_PARKING_PLACE: {
      TITLE: 'Your new parking place',
      CONFIRM_BUTTON_TEXT: 'ACCEPT',
      CANCEL_BUTTON_TEXT: 'DISCARD',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    SELECT_NEW_PARKING_PLACE: {
      TITLE: 'Please select new parking place',
      DISABLE_CANCEL_BUTTON: true,
      CONFIRM_BUTTON_TEXT: 'OK',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    SWAP_SELECT_NEW_PARKING_PLACE: {
      TITLE: 'Please select new parking place',
      TEXT: 'You can select new place with double click',
      DISABLE_CANCEL_BUTTON: true,
      CONFIRM_BUTTON_TEXT: 'OK',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    SELECT_NEW_PARKING_PLACE_ADMIN: {
      TITLE: 'Please select new parking place for this plate number',
      DISABLE_CANCEL_BUTTON: true,
      CONFIRM_BUTTON_TEXT: 'OK',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    HAVE_TO_LEAVE: {
      TITLE: 'No parking place left',
      TEXT: 'We are sorry but no other parking place left, you have to leave.',
      DISABLE_CANCEL_BUTTON: true,
      CONFIRM_BUTTON_TEXT: 'OK',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    FINALIZE_RESERVATION: {
      TITLE: 'Finalise reservation for (plate No):',
      CONFIRM_BUTTON_TEXT: 'OK',
      CANCEL_BUTTON_TEXT: 'CANCEL',
      CONFIRM_BUTTON_CLASS: 'green-bg',
    },
    RESERVE_FOR_GROUP: {
      TITLE: 'Reserve for:',
      TEXT_INPUT: true,
      CONFIRM_BUTTON_TEXT: 'RESERVE',
      CANCEL_BUTTON_TEXT: 'CANCEL',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    DELETE_USER: {
      TITLE: 'Are you sure to remove this user from the system?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
    },
    RESET_PASSWORD: {
      TITLE: 'Generate and send a new password for this user?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    NOT_HUNGARIAN_PLATE: {
      TITLE: 'The entered plate number is not hungarian',
      TEXT: 'Are you sure you want to save it?',
      CONFIRM_BUTTON_TEXT: 'CONTINUE',
      CANCEL_BUTTON_TEXT: 'REPAIR',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    CONFIRM_WITH_BICYCLE: {
      TITLE: 'Are you with Bike?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'green-bg',
    },
    CONFIRM_WITH_CAR: {
      TITLE: 'Are you with Car?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'green-bg',
    },
    CANT_RESERVE_IN_BIKE_MODE: {
      TITLE: "You can' reserve parking slot if you are with Bike",
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: 'true',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    RESERVATION_SUSPENDED: {
      TITLE: 'Due to parking policy violation reservations are suspended.',
      SUB_TITLE: 'Reservation will be available next<br/> when entering the building,<br/> or at 6 or 12 PM',
      SUB_TITLE_CLASS: 'one-button-width whiter-bg mt-0',
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: 'true',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
      FOOTER_CUSTOM_CLASS: 'new-park-place-footer',
    },
    PLATE_NUMBER_NOT_EXIST: {
      TITLE: 'The entered plate number does not exist in our database',
      TEXT: 'Are you sure you entered it correctly?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'MODIFY',
      CONFIRM_BUTTON_CLASS: 'yellow-bg',
    },
    ALL_FAVOURITE_IS_MANUAL: {
      TITLE: 'You can have maximum 5 favourites.',
      TEXT: 'Please remove one of your favourites to add more',
      CONFIRM_BUTTON_TEXT: 'OK',
      DISABLE_CANCEL_BUTTON: true,
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
      FOOTER_CUSTOM_CLASS: 'parked-park-place-footer',
    },
    BAN_USER: {
      TITLE: 'Are you sure to BAN this user out from the garage?',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
    },
    ENABLE_USER: {
      TITLE: 'Are you sure you want to enable this user to use the garage?',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
    },
    DELETE_SPECIAL_FLAG: {
      TITLE: 'Are you sure to remove this special flag from the system?',
      CONFIRM_BUTTON_TEXT: 'YES',
      CANCEL_BUTTON_TEXT: 'NO',
      CONFIRM_BUTTON_CLASS: 'cian-blue-bg',
    },
  },
  ERROR_MESSAGES: {
    parking_slot_already_reserved: 'This parking place already reserved! <br /> Please take another one!',
    reservation_not_found_or_already_finalized: 'The selected reservation not found, or already finalized',
    server_error_happened: 'We are sorry, server error happened, please try again later!',
    server_error: 'We are sorry, server error happened, please try again later!',
    parking_slot_not_available: "You can't reserve group for private parking place!",
    existing_licence_plate_number: 'Licence Plate Number already exists',
    existing_user_email: 'Email already exists',
    existing_user_phone: 'Phone already exists',
    existing_user_card_number: 'Parking Card Number already exists',
    invalid_user_status: 'User is deactivated',
    phone_already_exists: 'Phone already exists',
    user_already_has_reservation: 'User already has reservation',
    licence_plate_number_already_exists: 'Licence plate number already exists',
    parking_slot_already_has_reservation: 'One of the selected parking slots already have reservation',
    reservation_not_found: 'Reservation not found',
    login_expired: 'Your login is expired! <br /> Please login again.',
    missing_parking_slot: 'Missing parking place from reservation data!',
    missing_licence_plate_number: 'Missing Licence Plate Number for user!',
    wrong_old_password: 'The given generated password is wrong!',
    authentication_failed: 'Authentication failed!',
    permission_denied: 'Permission Denied!',
    invalid_credentials: 'Invalid Credentials',
    pre_reservation_already_exist: 'Booking already exist on this date interval.',
    cannot_pre_reserve_for_this_slot_type: "You can't book for this slot type.",
    reservation_already_exist_on_date: 'Reservation already exist on this date.',
    favourite_parking_slot_already_exists: 'This parking place already exist in your favourites list',
    favourite_parking_slot_not_available: 'This parking place is not available',
    parking_slot_not_found: 'No parking place exists with this number',
    user_banned:
      'Due to repeated violations your parking access has been revoked in accordance with the current Parking Policy',
    token_invalid: 'The provided token is invalid.',
    token_expired: 'The provided token is expired.',
  },
  ADMIN_TAB: {
    USERS: 'users',
    LOGS: 'logs',
    REPORTS: 'reports',
    SPECIAL_FLAGS: 'special-flags',
  },
  PATTERNS: {
    PLATE_NUMBER: '^[a-zA-Z0-9-]+$',
    PLATE_NUMBER_HUNGARY: '^[a-zA-Z]{3,4}[\\-]{0,1}[0-9]{3}$',
    PHONE: '^\\+[0-9]+$',
    EMAIL: '^[a-zA-Z0-9._-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}',
    PASSWORD: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^\\s"\']{8,}$',
  },
  REPORTS: {
    NIGHT_STAND: 'night_stand',
    TOOK_MY_PLACE: 'took_my_place',
    REPORT_2: 'report_2',
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_EXPORT_FORMAT: 'YYYY-MM-DD hh:mm:ss',
  PLATE_NUMBER_FORMAT: 'ABC123',
  FORM: {
    SUBMIT_TITLE: 'ENTER',
  },
  PASSWORD_RESET_HINT_MODAL: {
    PASSWORD_VALIDATION_HINT_TEXT: `Password must be at least 8 characters long, must contain at least
    one UPPERCASE, one lowercase character and one number`,
  },
  RESET_PASSWORD_TEXT: 'Reset password',
  PASSWORD_RESET_SUCCESSFUL_MESSAGE: 'Email successfully sent, please follow the instructions in the email.',
  RESET_PASSWORD_ROUTE: {
    RESET_PASSWORD_SEND_SUCCESSFUL: 'New password sent to your email address successfully.',
  },
};
