import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LoginBaseComponent } from './components/login-base/login-base.component';
import { LoginPasswordResetFormComponent } from './components/login-password-reset-form/login-password-reset-form.component';
import { LoginPasswordResetCallComponent } from './components/login-password-reset-call/login-password-reset-call.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    LoginPasswordResetFormComponent,
    LoginPasswordResetCallComponent,
    LoginBaseComponent,
  ],
})
export class LoginModule {}
