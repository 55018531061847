<div class="inner-footer">
    <div *ngIf="!isMobile" class="login-reception-call d-inline-block">
        <app-clickable-image
                [title]="loginReceptionImage.title"
                [imageHeight]="_mediumImageSize"
                [imageSrc]="CONSTS.ICON_URL.RECEPTION_CALL"
                [imageText]="loginReceptionImage.text"
                [imageLinkHref]="loginReceptionImage.link"
        ></app-clickable-image>

        <app-version></app-version>
    </div>

    <div *ngIf="isMobile" class="mobile-reception-call-container d-inline-block">
        <div class="mobile-login-reception-call d-inline-block">
            <div class="mobile-reception-call-text">reception</div>

            <div class="mobile-reception-call-image">
                <a href="{{ loginReceptionImage.link }}">
                    <img alt="reception call"
                         [title]="loginReceptionImage.title"
                         src="{{ CONSTS.ICON_URL.RECEPTION_CALL }}"
                    />
                </a>
            </div>

            <div class="mobile-reception-call-text">{{ receptionNumber }}</div>
        </div>

        <app-version [mobile]="true"></app-version>
    </div>
</div>
