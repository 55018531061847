<app-modal-header
        [activeModal]="activeModal"
        [whiteCloseIcon]="!!modalConfig.floorPlace"
></app-modal-header>
<div class="modal-body">
    <div *ngIf="modalConfig.imageURL" class="image">
        <img src="{{ modalConfig.imageURL }}" alt="confirm icon"/>
    </div>
    <div class="title">
        {{ modalConfig.title }}

        <div *ngIf="modalConfig.titleText" class="text pt-2">
            {{ modalConfig.titleText | replaceConstants }}
        </div>
    </div>

    <div *ngIf="showContainer" class="text-container">
        <div *ngIf="modalConfig.plateNumberInput" class="plate-number-input">
            <input [ngClass]="{
                      'has-error': plateNumber !== '' && !checkPlateNumberInput()
                    }"
                   name="plateNumber"
                   [(ngModel)]="plateNumber"
                   type="text"
                   required
                   [placeholder]="CONSTS.PLATE_NUMBER_FORMAT"
                   class="form-control whiter-placeholder"
            />
        </div>

        <div *ngIf="modalConfig.text" class="text">{{ modalConfig.text }}</div>

        <div *ngIf="modalConfig.textInput" class="text-input">
            <input name="text" [(ngModel)]="text" type="text" class="form-control"/>
        </div>

        <div *ngIf="modalConfig.floorPlace"
             class="parking-slot-text  {{ modalConfig.parkingSlotCustomClass }}"
        >
            <div class="floor-number">
                Floor
                <span class="number-circle">
                    <span class="number-content">
                        -{{ modalConfig.floorPlace.floor }}
                    </span>
                </span>
            </div>
            <div class="parking-place">{{ modalConfig.floorPlace.place }}</div>

            <div *ngIf="modalConfig.jumpToParkingPlaceLink"
                 (click)="jumpToParkingPlaceFloor()"
                 class="show-map">
                SHOW MAP
            </div>
        </div>

        <div *ngIf="modalConfig.subTitle"
             class="sub-text {{ modalConfig.subTitleClass }}">
            <div class="sub-text-img">
                <img [src]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE" alt="they took my place"/>
            </div>
            <span [innerHTML]="modalConfig.subTitle"></span>
        </div>

        <div *ngIf="modalConfig.showWithBicycle"
             (click)="iAmWithBike()"
             class="i-am-with-bicycle-icon">
            <img [src]="CONSTS.ICON_URL.BICYCLE" alt="i am with bike"/>
            <div class="iwb-text">I'm with bike</div>
        </div>

        <div *ngIf="modalConfig.licencePlateNumber" class="licence-plate-number">
            {{ modalConfig.licencePlateNumber }}
        </div>
    </div>
</div>
<div class="modal-footer {{ modalConfig.footerCustomClass }}">
    <div *ngIf="!modalConfig.disableCancelButton"
         (click)="activeModal.dismiss()"
         class="modal-button-link cancel">
        <a class="modal-button">{{ modalConfig.cancelButtonText }}</a>
    </div>
    <div (click)="!checkPlateNumberInput() || confirmModal()"
         [ngClass]="{ 'disabled-link': !checkPlateNumberInput() }"
         class="modal-button-link {{ modalConfig.confirmButtonClass }}">
        <a class="modal-button">{{ modalConfig.confirmButtonText }}</a>
    </div>
</div>
