import { Component, EventEmitter, Output } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { UserResetFormData } from '../../classes/user-reset-form-data';
import { LoaderService } from '../../../../services/loader.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-login-password-reset-form',
  templateUrl: './login-password-reset-form.component.html',
  styleUrls: ['./login-password-reset-form.component.scss'],
})
export class LoginPasswordResetFormComponent {
  @Output() switchBackToLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

  emailSendingWasSuccessful: boolean = false;
  userResetPasswordFormData: UserResetFormData = {
    email: '',
  };

  protected readonly CONSTS = CONSTS;

  constructor(private loaderService: LoaderService, private authService: AuthService) {}

  backToLoginPage() {
    this.switchBackToLogin.next(true);
  }

  sendPasswordResetEmail() {
    this.loaderService.showLoader();

    this.authService.resetPasswordGenerateToken(this.userResetPasswordFormData).subscribe({
      next: () => {
        this.emailSendingWasSuccessful = true;
        this.loaderService.hideLoader();
      },
      error: () => {
        this.emailSendingWasSuccessful = false;
        this.loaderService.hideLoader();
      },
    });
  }
}
