import { ParkingSlot } from './ParkingSlot';
import { Reservation } from './Reservation';

export class PreReservation {
  reservation?: Reservation;

  constructor(
    public id: number = undefined,
    public name: string = '',
    public parking_slot_id: number = null,
    public from_time: string = null,
    public to_time: string = null,
    public alert_sent: boolean = undefined,
    public parking_slot: ParkingSlot = undefined
  ) {}
}
