import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CONSTS } from '../../../../../../../constants';
import { Tooltip } from '../../../../../../../classes/Tooltip';

@Component({
  selector: 'app-tooltip-admin',
  templateUrl: './tooltip-admin.component.html',
  styleUrls: ['./tooltip-admin.component.scss'],
})
export class TooltipAdminComponent {
  @Input() tooltipData: Tooltip;
  @Input() swapTooltip: Tooltip;
  @Input() swapPlaceMode: boolean;
  @Input() floorNumber: number;

  @Output() onSwap = new EventEmitter();

  CONSTS = CONSTS;

  emitOnSwap() {
    this.onSwap.emit();
  }
}
