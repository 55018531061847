import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParkPlaceSelectComponent } from './park-place-select.component';
import { RouterModule } from '@angular/router';
import { FloorMapComponent } from './components/floor-map/floor-map.component';
import { BrowserModule } from '@angular/platform-browser';
import { FloorHeaderComponent } from './components/floor-header/floor-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';
import { MapChartFloor1Component } from './components/floor-map/components/map-chart-floor-1/map-chart-floor-1.component';
import { MapChartFloor2Component } from './components/floor-map/components/map-chart-floor-2/map-chart-floor-2.component';
import { MapChartFloor1AdminComponent } from './components/floor-map/components/map-chart-floor-1/map-chart-floor-1-admin.component';
import { MapChartFloor2AdminComponent } from './components/floor-map/components/map-chart-floor-2/map-chart-floor-2-admin.component';
import { ParkPlaceSelectAdminService } from './services/park-place-select-admin.service';
import { ParkPlaceSelectGarageUserService } from './services/park-place-select-garage-user.service';
import { ParkPlaceSelectCommonService } from './services/park-place-select-common.service';
import { MapChartAdminComponent } from './components/floor-map/components/map-chart/map-chart-admin/map-chart-admin.component';
import { MapChartCommonComponent } from './components/floor-map/components/map-chart/map-chart-common/map-chart-common.component';
import { MapChartGarageUserComponent } from './components/floor-map/components/map-chart/map-chart-garage-user/map-chart-garage-user.component';
import { TooltipGarageUserComponent } from './components/floor-map/components/map-chart/tooltip-garage-user/tooltip-garage-user.component';
import { TooltipAdminComponent } from './components/floor-map/components/map-chart/tooltip-admin/tooltip-admin.component';
import { AdminPreReservationTabComponent } from './components/admin-pre-reservation-tab/admin-pre-reservation-tab.component';
import { AdminPreReservationEditModalComponent } from './components/admin-pre-reservation-tab/components/admin-pre-reservation-edit-modal/admin-pre-reservation-edit-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AdminReservationModalComponent } from './components/admin-reservation-modal/admin-reservation-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AllPreReservationTabComponent } from './components/all-pre-reservation-tab/all-pre-reservation-tab.component';
import { D3GanttChartComponent } from './components/all-pre-reservation-tab/d3-gantt-chart/d3-gantt-chart.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { AddFlagToParkingSlotModalComponent } from './components/add-flag-to-parking-slot-modal/add-flag-to-parking-slot-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReleaseParkingSlotComponent } from './components/release-parking-slot/release-parking-slot.component';
import { ReleaseSlotDatePickerModalComponent } from './components/release-parking-slot/components/release-slot-date-picker-modal/release-slot-date-picker-modal.component';
import { IsPassedPipe } from './pipes/is-passed.pipe';
import { TooltipGarageUserReservationComponent } from './components/floor-map/components/map-chart/tooltip-garage-user-reservation/tooltip-garage-user-reservation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    DragScrollModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule,
  ],
  declarations: [
    ParkPlaceSelectComponent,
    FloorMapComponent,
    FloorHeaderComponent,
    MapChartGarageUserComponent,
    MapChartFloor1Component,
    MapChartFloor2Component,
    MapChartCommonComponent,
    MapChartAdminComponent,
    MapChartFloor1AdminComponent,
    MapChartFloor2AdminComponent,
    TooltipGarageUserComponent,
    TooltipAdminComponent,
    AdminPreReservationTabComponent,
    AdminPreReservationEditModalComponent,
    AdminReservationModalComponent,
    AllPreReservationTabComponent,
    D3GanttChartComponent,
    AddFlagToParkingSlotModalComponent,
    ReleaseParkingSlotComponent,
    ReleaseSlotDatePickerModalComponent,
    IsPassedPipe,
    TooltipGarageUserReservationComponent,
  ],
  providers: [ParkPlaceSelectCommonService, ParkPlaceSelectAdminService, ParkPlaceSelectGarageUserService],
  exports: [AdminPreReservationTabComponent],
})
export class ParkPlaceSelectModule {}
