import { first } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FooterCommonComponent } from '../footer-common/footer-common.component';
import { ClickableImageConfig } from '../../../modules/shared/components/clickable-image/classes/clickableImageConfig';
import { ReservationStatus } from '../../../modules/park-place-select/classes/ReservationStatus';
import { Subscription } from 'rxjs';
import { ModalConfig } from '../../../modules/shared/classes/ModalConfig';
import { FloorPlace } from '../../../modules/shared/classes/FloorPlace';
import { CONSTS } from '../../../constants';
import { FloorPlaceCounts } from '../../../modules/floor-select/classes/floorPlaceCounts';
import { FavouritesModalComponent } from '../../../modules/floor-select/components/favourites-modal/favourites-modal.component';
import { Helper } from '../../../helper';
import { ModalConfigConsts } from '../../../modules/shared/classes/ModalConfigConsts';

@Component({
  selector: 'app-footer-garage-user',
  template: '<ng-content></ng-content>',
})
export class FooterGarageUserComponent extends FooterCommonComponent implements OnInit, OnDestroy {
  loginReceptionImage: ClickableImageConfig = {};
  floorSelectReceptionImage: ClickableImageConfig = {};
  pickParkingPlaceImage: ClickableImageConfig = {};
  pickNewParkingPlaceImage: ClickableImageConfig = {};
  bicycleImage: ClickableImageConfig = {};
  carImage: ClickableImageConfig = {};
  favouritesImage: ClickableImageConfig = {};
  favouriteSelectOkImage: ClickableImageConfig = {};
  favouriteSelectOkImageMobile: ClickableImageConfig = {};
  favouriteCancelImage: ClickableImageConfig = {};

  userWithBike: boolean;
  favouriteSelect: boolean;

  reservationStatus: ReservationStatus;

  userWithBikeSub: Subscription;
  reservationStatusSub: Subscription;
  favouriteSelectSub: Subscription;
  favouriteOpenModalSub: Subscription;

  private yourReservationModalOpened = false;

  mode: string;

  ngOnInit() {
    this.initCommon();
    this.initUserWithBike();
    this.initFavouritesSubs();

    this.reservationStatusSub = this.parkPlaceSelectGarageUserService
      .getReservationStatus()
      .subscribe((reservationStatus) => {
        this.reservationStatus = reservationStatus;

        if (this.reservationStatus && this.reservationStatus.user) {
          if (this.reservationStatus.user.popup === 'show_reservation') {
            if (this.reservationStatus.parking_slot) {
              let floorPlace = new FloorPlace(
                this.reservationStatus.parking_slot.floor,
                this.reservationStatus.parking_slot.slot_number
              );

              let config = new ModalConfig(null, CONSTS.MODAL.YOUR_RESERVATION);
              config.floorPlace = floorPlace;

              if (!this.yourReservationModalOpened) {
                this.yourReservationModalOpened = true;

                this.modalService.openConfirmModal(config).then(
                  (result) => {
                    if (result.jump_to_floor) {
                      this.router.navigateByUrl(CONSTS.PAGE_URL.FLOOR[config.floorPlace.floor]);
                    } else if (result.i_am_with_bike) {
                      this.sendIamWithBike(true);
                    }

                    this.parkPlaceSelectGarageUserService.removeUserPopup().subscribe();
                    this.yourReservationModalOpened = false;
                  },
                  () => {
                    this.yourReservationModalOpened = false;
                    // reject ignored
                  }
                );
              }
            }
          } else if (this.reservationStatus.user.popup === 'have_to_leave') {
            let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.HAVE_TO_LEAVE);
            this.modalService.openConfirmModal(config).then(
              () => {
                this.parkPlaceSelectGarageUserService.removeUserPopup().subscribe();
              },
              () => {
                // reject ignored
              }
            );
          }
        }
      });

    // get reservation Status if not exist
    if (this.reservationStatus === undefined) {
      this.parkPlaceSelectGarageUserService.getCurrentReservation(true).pipe(first()).subscribe();
    }

    this.floorSelectReceptionImage = {
      text: 'Reception <div class="reception-lower-number d-block d-xl-none">' + this.receptionNumber + '</div>',
      title: 'call reception',
    };
    this.pickParkingPlaceImage = {
      text: 'Pick a Parking Place',
      title: 'pick a parking place with double click on a parking place',
    };
    this.pickNewParkingPlaceImage = {
      text: 'Pick new Parking Place',
    };
    this.theyTookMyPlaceImage = {
      text: 'they took my place',
      title: 'report somebody took my place',
    };
    this.bicycleImage = {
      text: "I'm with bike",
      link: '',
      title: 'Press this button if you come with Bike today',
    };
    this.carImage = {
      text: "I'm with car",
      link: '',
      title: 'Press this button if you come with Car today',
    };
    this.favouritesImage = {
      text: 'Recent spots',
      link: '',
      title: 'You can view recent posts and set favourite spots here',
    };
    this.favouriteSelectOkImage = {
      text: 'Pick your favourite spot',
      link: '',
      title: 'Please select your favourite spot',
    };
    this.favouriteSelectOkImageMobile = {
      text: 'Pick your<br/> favourite spot',
    };
    this.favouriteCancelImage = {
      text: 'Cancel',
      link: '',
      title: 'Cancel pick favourite spot',
    };
  }

  get customMode() {
    return this.userWithBike || this.favouriteSelect;
  }

  get showParkPlaceSelect() {
    return (
      this.reservationStatus &&
      !this.reservationStatus.user.restricted_at &&
      (!this.reservationStatus.exist || this.reservationStatus.changeParkingSlot)
    );
  }

  get showParked() {
    return this.reservationStatus.user.in_garage && !this.reservationStatus.final;
  }

  get showTheyTookMyPlace() {
    return this.reservationStatus.user.in_garage && !this.reservationStatus.changeParkingSlot;
  }

  get reservationStatusExist() {
    return this.reservationStatus && this.reservationStatus.exist;
  }

  get showCancel() {
    return !this.reservationStatus.user.in_garage && !this.reservationStatus.final;
  }

  get showCancelSwap() {
    return this.reservationStatus.user.in_garage && this.reservationStatus.changeParkingSlot;
  }

  get showMyReservationParkedIcon() {
    return this.reservationStatusExist && this.reservationStatus.final;
  }

  get showMyReservationNotFinishedIcon() {
    return this.reservationStatusExist && !this.reservationStatus.final;
  }

  get showIamWithBikeIcon() {
    return !(this.reservationStatusExist && !this.reservationStatus.final) && !this.userWithBike;
  }

  initUserWithBike() {
    this.userWithBikeSub = this.parkPlaceSelectGarageUserService
      .getUserWithBikeStatus()
      .subscribe((userWithBike) => {
        this.userWithBike = userWithBike;
      });

    this.parkPlaceSelectGarageUserService.setUserWithBikeStatus(this.authService.getCurrentUser().bike);
  }

  initFavouritesSubs() {
    this.favouriteSelectSub = this.parkPlaceSelectGarageUserService
      .getSelectFavouriteParkingPlaceStatus()
      .subscribe((favouriteSelect) => {
        this.favouriteSelect = favouriteSelect;
      });

    this.favouriteOpenModalSub = this.favouriteService.getOpenFavouriteModalObs().subscribe(() => {
      this.openFavouritesModal();
    });
  }

  cancelReservation() {
    const diff = Helper.getReservationElapsedSec(this.reservationStatus);
    const exceedDeleteLimit = diff >= CONSTS.RESERVATION_DELETE_LIMIT_MIN * 60000;

    this.modalService
      .openConfirmModal(
        new ModalConfig(
          CONSTS.ICON_URL.CANCEL_RESERVATION,
          exceedDeleteLimit ? CONSTS.MODAL.CANCEL_RESERVATION_EXCEED : CONSTS.MODAL.CANCEL_RESERVATION
        )
      )
      .then(
        () => {
          this.parkPlaceSelectGarageUserService.cancelReservation().subscribe();
        },
        () => {
          // reject ignored
        }
      );
  }

  cancelChangeReservation() {
    this.reservationStatus.changeParkingSlot = false;
    this.parkPlaceSelectGarageUserService.setReservationStatus(this.reservationStatus);
  }

  freeUpReservation() {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.PARKED, CONSTS.MODAL.FREE_UP_RESERVATION))
      .then(
        () => {
          this.parkPlaceSelectGarageUserService.cancelReservation().subscribe();
        },
        () => {
          // reject ignored
        }
      );
  }

  finalizeReservation() {
    this.modalService.openConfirmModal(new ModalConfig(CONSTS.ICON_URL.PARKED, CONSTS.MODAL.PARKED)).then(
      () => {
        this.parkPlaceSelectGarageUserService.finalizeReservation().subscribe(() => {
          let floorPlace = new FloorPlace(
            this.reservationStatus.parking_slot.floor,
            this.reservationStatus.parking_slot.slot_number
          );
          let config = new ModalConfig(null, CONSTS.MODAL.CAR_PARKED);
          config.floorPlace = floorPlace;

          this.modalService.openConfirmModal(config);
        });
      },
      () => {
        // reject ignored
      }
    );
  }

  showMyReservation() {
    if (this.reservationStatus && this.reservationStatus.parking_slot) {
      this.router.navigateByUrl(CONSTS.PAGE_URL.FLOOR[this.reservationStatus.parking_slot.floor.toString()]);
    } else {
      this.modalService.openAlertModal('missing_parking_slot');
    }
  }

  theyTookMyPlace() {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.THEY_TOOK_MY_PLACE))
      .then(
        (result) => {
          this.parkPlaceSelectGarageUserService.theyTookMyPlace(result.plateNumber).subscribe((response) => {
            this.handleTheyTookMyPlaceResult(response, () => {
              if (response.reservation.parking_slot.type === CONSTS.PARKING_SLOT_TYPES.VISITOR) {
                let config = new ModalConfig(
                  CONSTS.ICON_URL.THEY_TOOK_MY_PLACE,
                  CONSTS.MODAL.THEY_TOOK_MY_PLACE_CONFIRM_VISITOR
                );

                this.modalService.openConfirmModal(config).then(
                  () => {
                    this.chooseNewParkingPlace(CONSTS.MODAL.HAVE_TO_LEAVE);
                  },
                  () => {
                    // reject ignored
                  }
                );
              } else {
                this.chooseNewParkingPlace(CONSTS.MODAL.SELECT_NEW_PARKING_PLACE);
              }
            });
          });
        },
        () => {
          // reject ignored
        }
      );
  }

  chooseNewParkingPlace(modalType: Partial<ModalConfigConsts>) {
    // if user discarded his new place, we display choose new parking place!
    this.parkPlaceSelectGarageUserService.cancelReservation().subscribe(() => {
      let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, modalType);

      this.modalService.openConfirmModal(config);
    });
  }

  changeParkingSlot() {
    this.loaderService.showLoader();

    this.floorSelectService.getFreePlaceCount().subscribe({
      next: (result) => {
        let freePlaceCount = <FloorPlaceCounts>result;

        if (freePlaceCount.free_places.floor_1 > 0 || freePlaceCount.free_places.floor_2 > 0) {
          let config = new ModalConfig(CONSTS.ICON_URL.SWAP_PLACE, CONSTS.MODAL.SWAP_SELECT_NEW_PARKING_PLACE);

          this.modalService.openConfirmModal(config).then(
            () => {
              this.reservationStatus.changeParkingSlot = true;
              this.parkPlaceSelectGarageUserService.setReservationStatus(this.reservationStatus);
            },
            () => {
              // reject ignored
            }
          );
        } else {
          let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.HAVE_TO_LEAVE);

          this.modalService.openConfirmModal(config);
        }

        this.loaderService.hideLoader();
      },
      error: () => {
        this.loaderService.hideLoader();
      },
    });
  }

  iAmWithBike(withBike: boolean) {
    let config = new ModalConfig(
      withBike ? CONSTS.ICON_URL.BICYCLE : CONSTS.ICON_URL.CAR,
      withBike ? CONSTS.MODAL.CONFIRM_WITH_BICYCLE : CONSTS.MODAL.CONFIRM_WITH_CAR
    );

    this.modalService.openConfirmModal(config).then(
      () => {
        this.sendIamWithBike(withBike);
      },
      () => {
        // reject ignored
      }
    );
  }

  sendIamWithBike(withBike: boolean) {
    this.parkPlaceSelectGarageUserService.iAmWithBike(withBike);
  }

  openFavouritesModal() {
    this.modalService.openCustomModal(FavouritesModalComponent, null, 'dark-modal favourites-modal');
  }

  cancelAddFavourite() {
    this.router.navigate([CONSTS.PAGE_URL.FLOOR_SELECT]);
  }

  ngOnDestroy(): void {
    if (this.reservationStatusSub) this.reservationStatusSub.unsubscribe();
    if (this.userWithBikeSub) this.userWithBikeSub.unsubscribe();
    if (this.favouriteSelectSub) this.favouriteSelectSub.unsubscribe();
    if (this.favouriteOpenModalSub) this.favouriteOpenModalSub.unsubscribe();
  }
}
