<div *ngIf="selectedParkingSlot">
    <app-modal-header [activeModal]="activeModal"></app-modal-header>

    <div class="modal-body">
        <ng-container *ngIf="flagList">
            <div class="modal-text title">
                Choose special flags
            </div>

            <span class="my-4 modal-description">select the special flags for the parking space</span>

            <div class="flag-list w-100 d-flex flex-column align-items-center overflow-auto">
                <div *ngFor="let flag of flagList" class="d-flex align-items-center form-row mb-3 flag-list-item">
                    <img src="{{getCheckboxType(flag)}}" (click)="selectFlag(flag)" class="flag-selection-checkbox"
                         alt="checkbox_alt">

                    <img class="flag-icon mx-2" src="{{flag.icon}}" alt="flag_icon">
                    <span class="modal-text flag-name overflow-ellipsis">{{flag.name}}</span>
                </div>
            </div>
        </ng-container>

        <app-loader *ngIf="!flagList" [showLoader]="true"></app-loader>
    </div>

    <div class="modal-footer">
        <div (click)="activeModal.dismiss()" class="modal-button-link">
            <a>CANCEL</a>
        </div>
        <div (click)="addSelectedFlagsToParkingSlot()" class="modal-button-link">
            <a>SAVE</a>
        </div>
    </div>
</div>
