import { Component, OnInit } from '@angular/core';
import { CONSTS } from '../../../../constants';
import {
  CellClickedEvent,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  RowDataTransaction,
} from 'ag-grid-community';
import { SpecialFlagService } from '../../services/special-flag.service';
import { CommonComponent } from '../common/common.component';
import { SpecialFlag } from '../../../../classes/SpecialFlag';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { ModalService } from '../../../../services/modal.service';
import { LoaderService } from '../../../../services/loader.service';
import { ModalConfig } from '../../../shared/classes/ModalConfig';
import { EditSpecialFlagModalComponent } from './edit-special-flag-modal/edit-special-flag-modal.component';
import { AgGridColumnContent } from '../../../shared/util/ag-grid-column-content';

@Component({
  selector: 'app-special-flags',
  templateUrl: './special-flags.component.html',
  styleUrls: ['./special-flags.component.scss'],
})
export class SpecialFlagsComponent extends CommonComponent implements OnInit {
  gridOptions: GridOptions;
  filterValue: string;
  showLoader: boolean = true;

  protected readonly CONSTS = CONSTS;
  protected readonly FLAG_ICON_SIZE = '32px';

  constructor(
    private specialFlagService: SpecialFlagService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.initGridOptions();

    this.getSpecialFlagList();
  }

  addSpecialFlag() {
    this.modalService
      .openCustomModal(EditSpecialFlagModalComponent, { editMode: false }, 'edit-item-modal')
      .then((flag: SpecialFlag) => {
        const transaction: RowDataTransaction = {
          add: [flag],
        };
        this.gridOptions.api.applyTransaction(transaction);

        this.loaderService.hideLoader();
      });
  }

  getSpecialFlagList(): void {
    this.clearGetListSub();

    this.getListSub = this.specialFlagService.getSpecialFlagList().subscribe({
      next: (flags: SpecialFlag[]) => {
        this.gridOptions.rowData = flags;
        this.showLoader = false;
      },
      error: () => {
        this.showLoader = false;
      },
    });
  }

  setSpecialFlagListTableFilter(filterValue: string): void {
    this.gridOptions.api.setQuickFilter(filterValue);
  }

  private deleteSpecialFlag(id: number): void {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.DELETE_BLACK, CONSTS.MODAL.DELETE_SPECIAL_FLAG))
      .then(() => {
        this.specialFlagService.deleteFlag(id).subscribe({
          next: () => {
            const transaction: RowDataTransaction = {
              remove: [{ id: id }],
            };
            this.gridOptions.api.applyTransaction(transaction);

            this.loaderService.hideLoader();
          },
        });
      });
  }

  private editSpecialFlag(editFlag: SpecialFlag): void {
    this.modalService
      .openCustomModal(EditSpecialFlagModalComponent, { editMode: true, flag: editFlag }, 'edit-item-modal')
      .then((flag: SpecialFlag) => {
        const transaction: RowDataTransaction = {
          update: [flag],
        };
        this.gridOptions.api.applyTransaction(transaction);
        editFlag = flag;

        this.loaderService.hideLoader();
      });
  }

  private initGridOptions(): void {
    const componentInstance = this;

    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      cacheQuickFilter: true,
      rowHeight: 50,
      headerHeight: 50,
      rowData: [],
      rowSelection: 'single',
      onGridReady: () => {
        if (componentInstance.gridOptions.api) {
          componentInstance.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (componentInstance.gridOptions.api) {
          componentInstance.gridOptions.api.sizeColumnsToFit();
        }
      },
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as SpecialFlag;
        return rowData.id.toString();
      },
      onCellClicked: (params: CellClickedEvent) => {
        const eventTarget = params.event.target as HTMLElement;
        const rowData = params.data as SpecialFlag;

        if (eventTarget.className.indexOf('delete-cell-icon') !== -1) {
          componentInstance.deleteSpecialFlag(rowData.id);
        } else if (eventTarget.className.indexOf('edit-cell-icon') !== -1) {
          componentInstance.editSpecialFlag(rowData);
        }
      },
      columnDefs: [
        {
          width: 90,
          suppressSizeToFit: true,
          headerName: 'Icons',
          resizable: true,
          sortable: false,
          field: 'icon',
          cellRenderer: (rowData: ICellRendererParams) => {
            return (
              '<img class="inverted-image" src="' +
              rowData.data.icon +
              '" width="' +
              this.FLAG_ICON_SIZE +
              '" height="' +
              this.FLAG_ICON_SIZE +
              '" alt="flag_icon"> '
            );
          },
        },
        {
          width: 300,
          headerName: 'Name',
          field: 'name',
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Manage Flags',
          field: 'id',
          resizable: false,
          filter: false,
          sortable: false,
          cellRenderer: () => AgGridColumnContent.crudActionsCell(),
        },
      ],
      rowClassRules: {
        'resolved-row': (params) => {
          return params.data.handled === true;
        },
      },
    };
  }
}
