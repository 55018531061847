import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Favourite } from '../components/favourites-modal/classes/favourite';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';
import { LoaderService } from '../../../services/loader.service';

@Injectable()
export class FavouritesService {
  openFavouriteModal = new Subject<boolean>();

  constructor(private httpService: HttpService, private loaderService: LoaderService) {}

  getOpenFavouriteModalObs(): Observable<boolean> {
    return this.openFavouriteModal.asObservable();
  }

  triggerOpenFavouriteModal() {
    this.openFavouriteModal.next(true);
  }

  getFavourites(): Observable<Favourite[]> {
    return this.httpService.get(CONSTS.API_END_POINT.FAVOURITE).pipe(
      first(),
      map((result) => {
        return result.favourites_parking_slots;
      })
    );
  }

  addFavourite(slotNumber: string): Observable<Favourite> {
    this.loaderService.showLoader();

    const postData = {
      slot_number: slotNumber,
      manually_added: true,
    };

    return this.httpService.post(CONSTS.API_END_POINT.FAVOURITE, postData).pipe(first());
  }

  removeFavourite(slotNumber: number): Observable<any> {
    this.loaderService.showLoader();

    const postData = {
      slot_number: slotNumber,
      manually_added: false,
    };

    return this.httpService.post(CONSTS.API_END_POINT.FAVOURITE, postData).pipe(first());
  }
}
