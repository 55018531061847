import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common',
  template: '<div></div>',
  styleUrls: [],
})
export class CommonComponent implements OnDestroy {
  getListSub: Subscription;

  clearGetListSub() {
    if (this.getListSub) {
      this.getListSub.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.clearGetListSub();
  }
}
