import { Reservation } from './Reservation';
import { SpecialFlag } from '../../../classes/SpecialFlag';
import { User } from '../../../classes/User';

export class ParkingSlot {
  id: number;
  floor: number;
  slot_number: number;
  type: string;

  flags: SpecialFlag[];

  reservation?: Reservation;
  reservation_count?: boolean;
  pre_reservation_count?: number;
  private_user?: User;

  favourites_count?: number;
}
