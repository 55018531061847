<div #adminMenuContainer class="admin-menu-container">
    <div class="admin-menu-header">
        <div class="header-link"
             [ngClass]="{ active: activeTab | isSelectedTab: CONSTS.ADMIN_TAB.SPECIAL_FLAGS }"
             (click)="setActiveTab(CONSTS.ADMIN_TAB.SPECIAL_FLAGS)">
            SPECIAL FLAGS
        </div>

        <div class="header-link"
             [ngClass]="{ active: activeTab | isSelectedTab: CONSTS.ADMIN_TAB.REPORTS }"
             (click)="setActiveTab(CONSTS.ADMIN_TAB.REPORTS)">
            REPORTS
        </div>

        <div class="header-link"
             [ngClass]="{ active: activeTab | isSelectedTab: CONSTS.ADMIN_TAB.LOGS }"
             (click)="setActiveTab(CONSTS.ADMIN_TAB.LOGS)">
            LOGS
        </div>

        <div class="header-link"
             [ngClass]="{ active: activeTab | isSelectedTab: CONSTS.ADMIN_TAB.USERS }"
             (click)="setActiveTab(CONSTS.ADMIN_TAB.USERS)">
            USERS
        </div>

        <div class="header-close">
            <img class="hover-bigger"
                 title="close admin menu"
                 alt="close tab"
                 (click)="hideTab()"
                 [src]="CONSTS.ICON_URL.BACK_DOWN"
            />
        </div>
    </div>
    <div class="admin-menu-content">
        <div *ngIf="activeTab | isSelectedTab: CONSTS.ADMIN_TAB.USERS" class="admin-content">
            <app-users></app-users>
        </div>
        <div *ngIf="activeTab | isSelectedTab: CONSTS.ADMIN_TAB.REPORTS" class="admin-content">
            <app-reports></app-reports>
        </div>
        <div *ngIf="activeTab | isSelectedTab: CONSTS.ADMIN_TAB.LOGS" class="admin-content">
            <app-logs></app-logs>
        </div>
        <div *ngIf="activeTab | isSelectedTab: CONSTS.ADMIN_TAB.SPECIAL_FLAGS" class="admin-content">
            <app-special-flags></app-special-flags>
        </div>
    </div>
</div>
