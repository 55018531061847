<div class="row bs-date-picker-row">
    <div class="col bs-date-picker position-static">
        <div (click)="dp.toggle()" title="select date" class="dp-container">
            <div class="dp-icon">
                <img alt="datepicker" src="/assets/images/date_picker_icon.svg"/>
            </div>

            <div class="dp-input">
                <input type="text"
                       [disabled]="true"
                       [minDate]="minDate"
                       [maxDate]="maxDate"
                       class="form-control"
                       #dp="bsDatepicker"
                       #dpInput
                       bsDatepicker
                       [bsConfig]="bsConfig"
                       (bsValueChange)="onValueChanged($event)"
                       [(bsValue)]="selectedDate"/>
            </div>
        </div>

        <div *ngIf="rangePicker" class="dp-separator"></div>

        <ng-content></ng-content>

        <div (click)="dpTo.toggle()"
             *ngIf="rangePicker"
             title="select date"
             class="dp-container">

            <div class="dp-icon">
                <img alt="datepicker" src="/assets/images/date_picker_icon.svg"/>
            </div>

            <div class="dp-input">
                <input type="text"
                       [disabled]="true"
                       class="form-control"
                       [minDate]="minToDate"
                       [maxDate]="maxToDate"
                       #dpTo="bsDatepicker"
                       #dpToInput
                       bsDatepicker
                       [bsConfig]="bsConfig"
                       (bsValueChange)="onValueToChanged($event)"
                       [(bsValue)]="selectedToDate"/>
            </div>
        </div>

        <ng-content select="[filterElement]"></ng-content>
    </div>

    <div *ngIf="!rangePicker" class="col bs-day-picker">
        <div (click)="setPrevDay()"
             title="previous day"
             class="icon-switch icon-switch-prev">

            <img alt="datepicker-arrow" src="/assets/images/arrow_date_picker_left.svg"/>
        </div>

        <div class="display-day">
            {{ selectedDay }}
        </div>

        <div (click)="setNextDay()"
             title="next day"
             class="icon-switch icon-switch-next">

            <img alt="datepicker-arrow" src="/assets/images/arrow_date_picker_right.svg"/>

        </div>
    </div>

    <div *ngIf="rangePicker" class="col bs-day-picker">
        <div class="display-day range">
            {{ selectedDay }}
        </div>
        <div class="dp-separator"></div>
        <div class="display-day range">
            {{ selectedToDay }}
        </div>
    </div>
</div>
