import { Pipe, PipeTransform } from '@angular/core';
import { CONSTS } from '../../../constants';

@Pipe({
  name: 'replaceConstants',
})
export class ReplaceConstantsPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    console.log('value: ', value);

    const regex = /{{[a-zA-Z0-9._-]*}}/g;
    const keys = value.match(regex);

    console.log('keys: ', keys);

    for (const key of keys) {
      const pureKey = key.substring(2, key.length - 2);

      const regexp = new RegExp(key, 'gi');
      value = value.replace(regexp, CONSTS[pureKey]);
    }

    return value;
  }
}
