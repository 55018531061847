import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsTabComponent } from './alerts-tab.component';
import { SharedModule } from '../shared/shared.module';
import { AlertsService } from './services/alerts.service';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  imports: [CommonModule, SharedModule, AgGridModule.withComponents([])],
  declarations: [AlertsTabComponent],
  exports: [AlertsTabComponent],
  providers: [AlertsService],
})
export class AlertsTabModule {}
