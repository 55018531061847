<div class="map-container floor-1" [ngClass]="{ 'hide-left': !isFloor1 }">
    <div #floorSwitcher2
         (click)="switchFloor(2)"
         title="switch to floor -2"
         class="floor-vertical-switcher float-right">
        <img alt="arrow right" src="/assets/images/arrow_right.svg"/>
    </div>

    <div *ngIf="isGarageUser" class="map float-left floor-1">
        <app-map-chart-floor-1 [floorNumber]="1"></app-map-chart-floor-1>
    </div>
    <div *ngIf="!isGarageUser" class="map float-left floor-1">
        <app-map-chart-floor-1-admin
                [floorNumber]="1">
        </app-map-chart-floor-1-admin>
    </div>
</div>

<div class="map-container floor-2" [ngClass]="{ 'hide-right': isFloor1 }">
    <div #floorSwitcher1
         (click)="switchFloor(1)"
         title="switch to floor -1"
         class="floor-vertical-switcher float-left">
        <img alt="arrow left" src="/assets/images/arrow_left.svg"/>
    </div>

    <div *ngIf="isGarageUser" class="map float-right floor-2">
        <app-map-chart-floor-2 [floorNumber]="2"></app-map-chart-floor-2>
    </div>
    <div *ngIf="!isGarageUser" class="map float-left floor-2">
        <app-map-chart-floor-2-admin
                [floorNumber]="2"
        ></app-map-chart-floor-2-admin>
    </div>
</div>
