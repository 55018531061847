<div class="login-form-container animated zoomIn">
    <div class="login-logo">
        <img alt="login" [src]="'/assets/images/login_logo.svg'"/>
    </div>

    <div class="login-form"
         [ngClass]="{ 'password-reset': loggedInUser }">

        <ng-container
            *ngIf="!loggedInUser"
            class="form-content">

            <ng-container *ngIf="!isResetPasswordCallPage && !showResetPasswordForm">
                <app-login-admin-form
                    *ngIf="isUserAdminLoginPage">
                </app-login-admin-form>

                <app-login-user-form
                    *ngIf="isUserLoginPage">
                </app-login-user-form>

                <div *ngIf="isUserLoginPage && !showResetPasswordForm" class="mt-3">
                    <a (click)="switchToResetPasswordForm()" class="reset-password">
                        {{CONSTS.RESET_PASSWORD_TEXT}}
                    </a>
                </div>
            </ng-container>

            <app-login-password-reset-form
                *ngIf="showResetPasswordForm"
                (switchBackToLogin)="backToLoginPage()">
            </app-login-password-reset-form>

            <app-login-password-reset-call
                *ngIf="isResetPasswordCallPage">
            </app-login-password-reset-call>
        </ng-container>

        <div *ngIf="loggedInUser && loggedInUser.password_is_temporary">
            <app-login-new-password-form>
            </app-login-new-password-form>
        </div>
    </div>

    <div class="login-bottom-triangle"></div>
</div>
