import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTS } from '../../../../constants';
import { ParkPlaceSelectCommonService } from '../../services/park-place-select-common.service';
import { ParkPlaceSelectAdminService } from '../../services/park-place-select-admin.service';
import { AuthService } from '../../../auth/auth.service';
import { AllPreReservationTabComponent } from '../all-pre-reservation-tab/all-pre-reservation-tab.component';

@Component({
  selector: 'app-floor-header',
  templateUrl: './floor-header.component.html',
  styleUrls: ['./floor-header.component.scss'],
})
export class FloorHeaderComponent implements OnInit {
  @ViewChild(AllPreReservationTabComponent) allPreReservationTab: AllPreReservationTabComponent;

  floorName: string;
  rotateRefresh = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private parkPlaceSelectCommonService: ParkPlaceSelectCommonService,
    private parkPlaceSelectAdminService: ParkPlaceSelectAdminService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.floorName = params['floor'];
    });
  }

  get isFloor1() {
    return this.floorName === CONSTS.PAGE_URL_PARAM.FLOOR1;
  }

  toFloorSelect() {
    this.router.navigate([CONSTS.PAGE_URL.FLOOR_SELECT]);
  }

  zoomIn() {
    this.parkPlaceSelectCommonService.setZoom(1.2);
  }

  zoomOut() {
    this.parkPlaceSelectCommonService.setZoom(0.8);
  }

  switchFloor(floor: number) {
    this.router.navigate([CONSTS.PAGE_URL.FLOOR[floor.toString()]]);
  }

  isAdminOrReceptionist() {
    return this.authService.isAdminOrReceptionist;
  }

  refreshMap() {
    this.parkPlaceSelectAdminService.forceParkingSlotsRefresh();
    this.rotateRefresh += 360;
  }

  openPreReservationTab() {
    this.allPreReservationTab.openTab();
  }
}
