<form #adminLoginForm="ngForm">
    <div class="title">
        LOGIN <span class="title-for-admin">- for Admins</span>
    </div>

    <div class="form-group">
        <input required
               type="email"
               name="email"
               class="form-control"
               placeholder="email"
               [(ngModel)]="formData.email"
               [pattern]="CONSTS.PATTERNS.EMAIL"/>
    </div>

    <div class="form-group">
        <input autocomplete
               required
               type="password"
               class="form-control"
               name="password"
               placeholder="password"
               [(ngModel)]="formData.password"/>
    </div>

    <button class="btn btn-primary btn-block"
            [disabled]="adminLoginForm.invalid"
            (click)="adminLogin()">
        {{CONSTS.FORM.SUBMIT_TITLE}}
    </button>
</form>
