import { first, takeWhile } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTS } from '../../constants';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { FloorSelectService } from './services/floor-select.service';
import { FloorPlaceCounts } from './classes/floorPlaceCounts';
import { DeviceService } from '../../services/device.service';
import { ParkPlaceSelectGarageUserService } from '../park-place-select/services/park-place-select-garage-user.service';
import { FavouritesService } from './services/favourites.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-floor-select',
  templateUrl: './floor-select.component.html',
  styleUrls: ['./floor-select.component.scss'],
})
export class FloorSelectComponent implements OnInit, OnDestroy {
  freePlaceCount: FloorPlaceCounts;
  isMobile: boolean;
  refreshData: boolean;
  isGarageUser: boolean;

  constructor(
    private authService: AuthService,
    private floorSelectService: FloorSelectService,
    private router: Router,
    private parkPlaceSelectGarageUserService: ParkPlaceSelectGarageUserService,
    private favouriteService: FavouritesService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.refreshData = true;
    this.freePlaceCount = null;
    this.isGarageUser = this.authService.isGarageUser;

    this.resetFavouriteSelectMode();

    this.addTimers();
  }

  resetFavouriteSelectMode() {
    this.parkPlaceSelectGarageUserService
      .getSelectFavouriteParkingPlaceStatus()
      .pipe(first())
      .subscribe((status) => {
        if (status) {
          this.parkPlaceSelectGarageUserService.setSelectFavouriteParkingPlaceStatus(false);
          this.favouriteService.triggerOpenFavouriteModal();
        }
      });
  }

  logout() {
    this.authService.logout();

    if (!this.isGarageUser) {
      this.router.navigate([CONSTS.PAGE_URL.ADMIN_LOGIN]);
    } else {
      this.router.navigate([CONSTS.PAGE_URL.LOGIN]);
    }
  }

  get autoScale() {
    let windowWidth = window.innerWidth;
    let scale = 0;

    if (windowWidth <= 360) {
      scale = 620;
    } else if (windowWidth <= 450) {
      scale = 750;
    } else {
      scale = 850;
    }

    return scale;
  }

  addTimers() {
    if (this.isGarageUser) {
      // refresh reservationStatus for map intervally
      timer(0, CONSTS.WS_REFRESH_RESERVATION_CALL_INTERVAL)
        .pipe(takeWhile(() => this.refreshData))
        .subscribe(() => {
          this.parkPlaceSelectGarageUserService.getCurrentReservation(false, true).subscribe();
        });
    }

    // refresh reservationStatus for map intervally
    timer(0, CONSTS.WS_FLOOR_SELECT_CALL_INTERVAL)
      .pipe(takeWhile(() => this.refreshData))
      .subscribe(() => {
        if (this.isGarageUser) {
          this.floorSelectService.getFreePlaceCount(true).subscribe((result) => {
            this.freePlaceCount = <FloorPlaceCounts>result;
          });
        } else {
          this.floorSelectService.getFreePlaceAdminCount(true).subscribe((result) => {
            this.freePlaceCount = <FloorPlaceCounts>result;
          });
        }
      });
  }

  ngOnDestroy() {
    this.refreshData = false;
  }
}
