import { Component, Input, OnInit } from '@angular/core';
import { EditSpecialFlagModalData } from './classes/EditSpecialFlagModalData';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecialFlag } from '../../../../../classes/SpecialFlag';
import { SpecialFlagService } from '../../../services/special-flag.service';
import { EditSpecialFlagFormData } from './classes/EditSpecialFlagFormData';
import { Helper } from '../../../../../helper';

@Component({
  selector: 'app-edit-special-flag-modal',
  templateUrl: './edit-special-flag-modal.component.html',
  styleUrls: ['./edit-special-flag-modal.component.scss'],
})
export class EditSpecialFlagModalComponent implements OnInit {
  @Input() data: EditSpecialFlagModalData;

  tmpFlag: EditSpecialFlagFormData;
  flagIconName: string;
  editMode: boolean;
  saveClicked: boolean = false;

  constructor(public activeModal: NgbActiveModal, private specialFlagService: SpecialFlagService) {}

  ngOnInit() {
    this.editMode = this.data.editMode;

    if (this.editMode) {
      this.tmpFlag = { ...this.data.flag, icon: null };
      this.flagIconName = Helper.getFileNameFromPath(this.data.flag.icon);
    } else {
      this.tmpFlag = new EditSpecialFlagFormData();
    }
  }

  isEmpty(str: string) {
    return !str || 0 === str.length;
  }

  saveSpecialFlag(valid: boolean) {
    this.saveClicked = true;

    if (valid && this.flagIconName) {
      if (this.editMode) {
        this.specialFlagService.updateFlag(this.tmpFlag).subscribe({
          next: (flag: SpecialFlag) => {
            this.activeModal.close(flag);
          },
        });
      } else {
        this.specialFlagService.createFlag(this.tmpFlag).subscribe({
          next: (flag: SpecialFlag) => {
            this.activeModal.close(flag);
          },
        });
      }
    }
  }

  onFileChange(event: Event) {
    const flagIconFile = Array.from((event.target as HTMLInputElement).files)[0];

    this.tmpFlag.icon = flagIconFile;
    this.flagIconName = flagIconFile.name;
  }

  deleteFile(): void {
    this.tmpFlag.icon = null;
    this.flagIconName = null;
  }
}
