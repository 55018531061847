<div class="select-toggle">
    <app-dropdown-list
        [elementList]="roleList"
        [selectedElements]="selectedRoles"
        [hasError]="(saveClicked) && selectedRoles.length === 0"
        (elementClicked)="checkRole($event)"
        [title]="getSelectTitle()"
        [labelKey]="'name'">
    </app-dropdown-list>
</div>
