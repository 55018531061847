<app-alerts-tab #alertsTab></app-alerts-tab>
<app-admin *ngIf="authService.isAdmin" #adminMenu></app-admin>

<div *ngIf="isFloorSelect && authService.isAdmin"
     class="inner-footer-admin-floor row"
     [ngClass]="{ mobile: isMobile }">

    <!--  SPECIAL FLAGS  -->
    <app-clickable-image
        [title]="'open special flags'"
        [hoverClass]="'hover-bigger'"
        [imageHeight]="'2.5rem'"
        [imageSrc]="CONSTS.ICON_URL.SPECIAL_FLAGS"
        [imageText]="'special flags'"
        [columnDirection]="true"
        (onImageClick)="openAdminMenu(CONSTS.ADMIN_TAB.SPECIAL_FLAGS)">
    </app-clickable-image>

    <!-- REPORTS -->
    <app-clickable-image
        [title]="'open reports'"
        [hoverClass]="'hover-bigger'"
        [imageHeight]="'2.5rem'"
        [imageSrc]="CONSTS.ICON_URL.REPORTS"
        [imageText]="'reports'"
        [columnDirection]="true"
        (onImageClick)="openAdminMenu(CONSTS.ADMIN_TAB.REPORTS)">
    </app-clickable-image>

    <!-- LOGS -->
    <app-clickable-image
        [title]="'open logs'"
        [hoverClass]="'hover-bigger'"
        [imageHeight]="'2.5rem'"
        [imageSrc]="CONSTS.ICON_URL.LOGS"
        [imageText]="'logs'"
        [columnDirection]="true"
        (onImageClick)="openAdminMenu(CONSTS.ADMIN_TAB.LOGS)">
    </app-clickable-image>

    <!-- USERS -->
    <app-clickable-image
        [title]="'open users'"
        [hoverClass]="'hover-bigger'"
        [imageHeight]="'2.5rem'"
        [imageSrc]="CONSTS.ICON_URL.USERS"
        [imageText]="'users'"
        [columnDirection]="true"
        (onImageClick)="openAdminMenu(CONSTS.ADMIN_TAB.USERS)">
    </app-clickable-image>

    <app-version></app-version>
</div>

<div
    *ngIf="isParkPlaceSelect"
    class="inner-footer-admin row"
    [ngClass]="{ mobile: isMobile }">

    <div class="set-place-type col-3 col-md-4 col-xl-6">
        <div *ngIf="isAnySelected && !allSelectedHasAnyReservation"
             [tooltip]="'Can\'t change the type of a private parking spot, because it has private user.'"
             [delay]="500"
             [isDisabled]="!selectedHasPrivateUser"
             class="d-flex w-100 flex-column">
            <!-- SET PLACE TYPE -->
            <div class="title">Set Place Type</div>

            <div class="d-flex flex-row">
                <div class="d-flex position-relative" [class.radio-disabled]="selectedHasPrivateUser">

                    <div class="d-flex flex-column mr-3">
                        <div>
                            <input [checked]="isFirstSelectionType(CONSTS.PARKING_SLOT_TYPES.NONEY)"
                                   type="radio"
                                   id="non-ey"
                                   name="place_type"/>
                            <label (click)="setParkingSlotType(CONSTS.PARKING_SLOT_TYPES.NONEY)" for="non-ey">
                                Non-EY
                            </label>
                        </div>
                        <div>
                            <input [checked]="isFirstSelectionType(CONSTS.PARKING_SLOT_TYPES.VISITOR)"
                                   type="radio"
                                   id="visitor"
                                   name="place_type"/>
                            <label (click)="setParkingSlotType(CONSTS.PARKING_SLOT_TYPES.VISITOR)"
                                   for="visitor">
                                EY Visitor
                            </label>
                        </div>
                    </div>

                    <div class="d-flex flex-column mr-3">
                        <div>
                            <input [checked]="isFirstSelectionType(CONSTS.PARKING_SLOT_TYPES.NORMAL)"
                                   type="radio"
                                   id="normal"
                                   name="place_type"
                                   checked/>
                            <label (click)="setParkingSlotType(CONSTS.PARKING_SLOT_TYPES.NORMAL)"
                                   for="normal">
                                EY User Normal
                            </label>
                        </div>
                        <div>
                            <input [checked]="isFirstSelectionType(CONSTS.PARKING_SLOT_TYPES.PRIVATE)"
                                   type="radio"
                                   id="private"
                                   name="place_type"/>
                            <label (click)="setParkingSlotType(CONSTS.PARKING_SLOT_TYPES.PRIVATE)"
                                   for="private">
                                EY User Private
                            </label>
                        </div>
                    </div>

                    <button *ngIf="onlyOneSelected"
                            (click)="chooseSpecialFlag()"
                            class="choose-flag-button">
                        <span class="text-nowrap">Choose special flags</span>
                    </button>
                </div>
            </div>
        </div>

        <!-- FINALIZE PRE-RESERVED PLACE (USER PARKED) -->
        <div *ngIf="
                onlyOneSelected &&
                firstSelectedHasPreReservation &&
                !isSwapPlaceMode
             "
        >
            <app-clickable-image
                [title]="parkedImage.title"
                [imageHeight]="_bigSmallImageSize"
                [imageSrc]="CONSTS.ICON_URL.PARKED"
                [imageText]="parkedImage.text"
                (onImageClick)="finalizeOnePlace()"
            ></app-clickable-image>
        </div>

        <!-- PARKING-PLACE-SELECT-ICON -->
        <div *ngIf="!isAnySelected || isSwapPlaceMode"
             class="parking-place-select d-inline-block"
        >
            <app-clickable-image
                [title]="selectParkingPlacesImage.title"
                [imageHeight]="_bigImageSize"
                [imageSrc]="CONSTS.ICON_URL.PICK_PARKING_PLACE"
                [imageText]="selectParkingPlacesImage.text"
                [hoverClass]="'no-hover'"
            ></app-clickable-image>
        </div>
    </div>

    <div class="right-icons col-9 col-md-8 col-xl-6">
        <!-- PRE-BOOKING FOR ONE PLACE -->
        <div *ngIf="
                onlyOneSelected &&
                allSelectedIsReservable &&
                !isSwapPlaceMode
              "
        >
            <div class="right-icon-div">
                <app-clickable-image
                    [title]="managePreReservedTimesImage.title"
                    [imageHeight]="_smallImageSize"
                    [imageSrc]="CONSTS.ICON_URL.PRE_RESERVATION"
                    [imageText]="managePreReservedTimesImage.text"
                    (onImageClick)="showPreReservedTimes()"
                    [columnDirection]="true"
                ></app-clickable-image>
            </div>

            <app-admin-pre-reservation-tab [parkingSlot]="selectionStatus[0]">
            </app-admin-pre-reservation-tab>
        </div>

        <!-- CANCEL RESERVATION FOR ONE PARKING PLACE -->
        <div *ngIf="
                onlyOneSelected &&
                firstSelectedHasAnyReservation &&
                !firstSelectedHasBookedReservation &&
                !isSwapPlaceMode
              "
             class="right-icon-div"
        >
            <app-clickable-image
                [title]="firstSelectedHasFinalReservation
                        ? freeUpPlaceImage.title
                        : cancelReservationImage.title
                    "
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.CANCEL_RESERVATION"
                [imageText]="
                      firstSelectedHasFinalReservation
                        ? freeUpPlaceImage.text
                        : cancelReservationImage.text
                    "
                (onImageClick)="cancelOnePlace(firstSelectedHasFinalReservation)"
                [columnDirection]="true"
            ></app-clickable-image>
        </div>

        <!-- THEY TOOK MY PLACE FOR ONE PARKING PLACE -->
        <div *ngIf="onlyOneSelected && !nonEySelected && !isSwapPlaceMode"
             class="right-icon-div">
            
            <app-clickable-image
                [title]="theyTookMyPlaceImage.title"
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
                [imageText]="theyTookMyPlaceImage.text"
                (onImageClick)="tookMyPlaceOnePlace()"
                [columnDirection]="true">
            </app-clickable-image>
        </div>

        <!-- SWAP PARKING SLOT ICON -->
        <div *ngIf="
                onlyOneSelected &&
                firstSelectedHasAnyReservation &&
                !firstSelectedHasBookedReservation &&
                !firstSelectedHasMultiReservation &&
                !isSwapPlaceMode
              "
             class="right-icon-div"
        >
            <app-clickable-image
                [title]="changeParkingSlotImage.title"
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.SWAP_PLACE"
                [imageText]="changeParkingSlotImage.text"
                (onImageClick)="swapParkingSlot()"
                [columnDirection]="true"
            ></app-clickable-image>
        </div>

        <!-- RESERVE PLACES FOR MORE PARKING PLACE -->
        <div *ngIf="moreThanOneSelected && allSelectedIsReservable && !allSelectedHasAnyReservation"
             class="right-icon-div">
            <app-clickable-image
                [title]="reservePlacesImage.title"
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.SHOW_MY_RESERVATION"
                [imageText]="reservePlacesImage.text"
                (onImageClick)="reserveMorePlace()"
                [columnDirection]="true"
            ></app-clickable-image>
        </div>

        <!-- RESERVE PLACE FOR ONE PARKING PLACE -->
        <div *ngIf="
                onlyOneSelected &&
                allSelectedIsReservable &&
                !firstSelectedHasAnyReservation &&
                !isSwapPlaceMode
              "
             class="right-icon-div"
        >
            <app-clickable-image
                [title]="reservePlaceImage.title"
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.SHOW_MY_RESERVATION"
                [imageText]="reservePlaceImage.text"
                (onImageClick)="reserveOnePlace()"
                [columnDirection]="true"
            ></app-clickable-image>
        </div>

        <!-- CANCEL SWAP RESERVATION ICON -->
        <div *ngIf="onlyOneSelected && isSwapPlaceMode" class="right-icon-div">
            <app-clickable-image
                [title]="cancelReservationChangeImage.title"
                [imageHeight]="_miniImageSize"
                [imageSrc]="CONSTS.ICON_URL.CLOSE_WHITE"
                [imageText]="cancelReservationChangeImage.text"
                (onImageClick)="cancelSwap()"
                [columnDirection]="true"
            ></app-clickable-image>
        </div>
    </div>
</div>
