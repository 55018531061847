import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';
import { User } from '../../../classes/User';
import { Observable } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';
import { Overnights } from '../classes/Overnights';
import { UserEditFormData } from '../classes/UserEditFormData';
import { TookMyPlaceRow } from '../classes/TookMyPlaces';
import { ModalConfig } from '../../shared/classes/ModalConfig';
import { ModalService } from '../../../services/modal.service';
import { CellClickedEvent, GridOptions, ICellRendererParams, RowDataTransaction } from 'ag-grid-community';
import { GetQuickFilterTextParams } from 'ag-grid-community/dist/lib/entities/colDef';

@Injectable()
export class AdminMenuService {
  constructor(
    private httpService: HttpService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {}

  getUserList(): Observable<Array<User>> {
    return this.httpService.get(CONSTS.API_END_POINT.USER).pipe(
      map((result) => {
        return <Array<User>>result.users;
      })
    );
  }

  deleteUser(userId: number) {
    this.loaderService.showLoader();
    return this.httpService.delete(CONSTS.API_END_POINT.USER + '/' + userId);
  }

  updateUserStatus(user: User) {
    this.loaderService.showLoader();

    let data = {
      status: user.status ? 1 : 0,
    };
    return this.httpService.put(CONSTS.API_END_POINT.USER + '/' + user.id, data).pipe(
      map((result) => {
        return <User>result.user;
      })
    );
  }

  updateUserBanned(user: User, banned: boolean) {
    this.loaderService.showLoader();

    let data = {
      banned,
    };
    return this.httpService.put(CONSTS.API_END_POINT.USER + '/' + user.id, data).pipe(
      map((result) => {
        return <User>result.user;
      })
    );
  }

  updateUser(user: UserEditFormData) {
    this.loaderService.showLoader();
    return this.httpService.put(CONSTS.API_END_POINT.USER + '/' + user.id, user).pipe(
      map((result) => {
        return <User>result.user;
      })
    );
  }

  createUser(user: UserEditFormData) {
    this.loaderService.showLoader();
    return this.httpService.post(CONSTS.API_END_POINT.USER, user).pipe(
      map((result) => {
        return <User>result.user;
      })
    );
  }

  resetUserPassword(userID: number) {
    this.loaderService.showLoader();
    return this.httpService.get(CONSTS.API_END_POINT.USER + '/' + userID + '/reset-password');
  }

  getOverNightReport(fromDate: string, toDate: string): Observable<Array<Overnights>> {
    return this.httpService.get(CONSTS.API_END_POINT.REPORT + 'overnight/' + fromDate + '/' + toDate).pipe(
      map((result) => {
        let arr = Object.keys(result.overnights).map((key) => {
          return Object.assign({ expanded: false }, result.overnights[key]);
        });

        return <Array<Overnights>>arr;
      })
    );
  }

  getTookMyPlaceReports(
    fromDate: string,
    toDate: string,
    onlyValid: boolean = true
  ): Observable<Array<TookMyPlaceRow>> {
    return this.httpService
      .get(CONSTS.API_END_POINT.REPORT + 'took-my-place/' + fromDate + '/' + toDate, null, null, {
        onlyValid: onlyValid as unknown as string,
      })
      .pipe(
        map((result) => {
          let arr = Object.keys(result.took_my_places).map((key) => {
            return Object.assign({ expanded: false }, result.took_my_places[key]);
          });

          // return <Array<Overnights>>result.overnights;
          return <Array<TookMyPlaceRow>>arr;
        })
      );
  }

  switchBanUser(params: CellClickedEvent, gridOptions: GridOptions) {
    let config;
    const cellUser = params.data as User;

    if (cellUser.banned) {
      config = new ModalConfig(CONSTS.ICON_URL.ICON_ENABLE, CONSTS.MODAL.ENABLE_USER);
    } else {
      config = new ModalConfig(CONSTS.ICON_URL.ICON_BAN, CONSTS.MODAL.BAN_USER);
    }

    this.modalService.openConfirmModal(config).then(
      () => {
        this.updateUserBanned(cellUser, !cellUser.banned).subscribe({
          next: (user: User) => {
            cellUser.banned = user.banned;

            let transaction: RowDataTransaction = {
              update: [cellUser],
            };
            gridOptions.api.applyTransaction(transaction);
            gridOptions.api.redrawRows({ rowNodes: [params.node] });

            this.loaderService.hideLoader();
          },
          error: () => {
            this.loaderService.hideLoader();
          },
        });
      },
      () => {
        // reject ignored
      }
    );
  }

  banEnableCellRenderer(params: ICellRendererParams) {
    const user = <User>params.node.data;

    return (
      '<div data-id="ban-btn" class="ban-btn ' +
      (user.banned ? 'enable' : 'ban') +
      '">' +
      (user.banned ? 'enable' : 'ban') +
      '</div></div>'
    );
  }

  banEnableFilterText(params: GetQuickFilterTextParams) {
    const user = <User>params.node.data;
    return user.banned ? 'enable' : 'ban';
  }

  invalidateTookMyPlaceReport(
    id: number,
    valid: boolean,
    from: string,
    to: string,
    onlyValid: boolean,
    description: string
  ): Observable<TookMyPlaceRow> {
    this.loaderService.showLoader();
    return this.httpService.put(`took-my-place/${id}`, { valid, description, from, to, onlyValid });
  }
}
