import { Component, OnChanges, OnInit } from '@angular/core';
import { AdminMenuService } from '../../../services/admin-menu.service';
import { CommonReportComponent } from '../common-report/common-report.component';
import * as _ from 'underscore';
import { CellClickedEvent, GetRowIdParams, RowDataChangedEvent, RowDataTransaction } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { CellClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { AgGridHelper } from '../../../../../utils/ag-grid-helper.util';
import { TookMyPlaceDate, TookMyPlaceRow } from '../../../classes/TookMyPlaces';
import { CONSTS } from '../../../../../constants';
import { ModalService } from '../../../../../services/modal.service';
import { InvalidateReportModalComponent } from './components/invalidate-report-modal/invalidate-report-modal.component';
import { LoaderService } from '../../../../../services/loader.service';

@Component({
  selector: 'app-took-my-place-report',
  templateUrl: './took-my-place-report.component.html',
  styleUrls: ['./took-my-place-report.component.scss'],
})
export class TookMyPlaceReportComponent extends CommonReportComponent implements OnInit, OnChanges {
  constructor(
    protected adminMenuService: AdminMenuService,
    private modalService: ModalService,
    private loaderService: LoaderService
  ) {
    super(adminMenuService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.extendGridOptions();
  }

  protected override setQueryEndpoint() {
    this.queryEndpoint = this.adminMenuService.getTookMyPlaceReports(this.fromDate, this.toDate, this.onlyValid);
  }

  extendGridOptions() {
    let that = this;

    this.gridOptions = _.extend(this.gridOptions, {
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as TookMyPlaceRow;
        return rowData.id.toString();
      },
      onCellClicked: (params: CellClickedEvent) => {
        const element = params.event.target as HTMLElement;
        const reportId = Number(element.getAttribute('id'));

        const dataAttr = element.getAttribute('data-id');

        if (dataAttr) {
          this.stopPropagation(params.event);
        }

        if (dataAttr === 'ban-btn') {
          that.switchBanUser(params);
        } else if (dataAttr === 'withdraw') {
          this.withDrawInvalidation(reportId);
        } else if (dataAttr === 'invalidate') {
          this.invalidateReport(params, reportId);
        } else {
          that.hideTooltipsForRow(params.data);
          that.toggleRow(params);
        }
      },
      onRowDataChanged: (params: RowDataChangedEvent) => {
        params.api.getRenderedNodes().forEach((node) => {
          node.setRowHeight(this.DEFAULT_ROW_HEIGHT);
        });

        params.api.refreshClientSideRowModel();
      },
      columnDefs: [
        {
          headerName: '',
          width: 50,
          suppressSizeToFit: true,
          field: 'expanded',
          sortable: true,
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params: ICellRendererParams) => {
            let html =
              '<div title="show details" class="overnight-toggle-icon"></div>' +
              '<div class="full-width-row">' +
              '<div class="full-width-row-title">Took my place report dates</div> ' +
              '<table class="table">';

            for (let overnight of params.data.took_my_places) {
              html +=
                '<tr>' +
                '<td>' +
                overnight.created_at +
                '</td>' +
                '<td class="w-50">' +
                this.getValidationOptions(overnight) +
                '</td>' +
                '</tr>';
            }
            html += '</table>' + '</div>';

            that.showTooltipsForRow(params.data);

            return html;
          },
          cellClass: 'full-width-row-cell',
          cellClassRules: {
            expanded: (params: CellClassParams) => params.value,
          },
        },
        {
          headerName: 'Card number',
          field: 'card_number',
          suppressMovable: true,
          resizable: false,
          sortable: true,
        },
        AgGridHelper.plateNumberColDef,
        {
          headerName: 'Took my place Count',
          field: 'took_my_places_count',
          suppressMovable: true,
          resizable: false,
          sortable: true,
          cellRenderer: (params: ICellRendererParams) => {
            return params.value + ' times reported';
          },
        },
        {
          headerName: 'User name',
          field: 'name',
          suppressMovable: true,
          resizable: false,
          sortable: true,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'User Phone',
          field: 'phone',
          suppressMovable: true,
          resizable: false,
          sortable: true,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Manage User',
          field: 'banned',
          width: 150,
          resizable: false,
          suppressSizeToFit: true,
          cellRenderer: this.adminMenuService.banEnableCellRenderer,
          getQuickFilterText: this.adminMenuService.banEnableFilterText,
          sortable: true,
          filter: false,
        },
      ],
    });
  }

  protected toggleRow(params: CellClickedEvent) {
    if (!params.data.expanded) {
      // row height + padding + table header height + row heights
      params.node.setRowHeight(this.DEFAULT_ROW_HEIGHT + 10 + params.data.took_my_places_count * 43);
      params.data.expanded = true;
    } else {
      params.node.setRowHeight(this.DEFAULT_ROW_HEIGHT);
      params.data.expanded = false;
    }

    let transaction: RowDataTransaction = {
      update: [params.data],
    };

    this.gridOptions.api.applyTransaction(transaction);
    params.api.onRowHeightChanged();
  }

  private showTooltipsForRow(data: TookMyPlaceRow) {
    setTimeout(() => {
      $(`#took-my-place-table [row-id="${data.id}"]`).find('.took-my-place-icon').tooltip();
    });
  }

  protected hideTooltipsForRow(data: TookMyPlaceRow) {
    $(`#took-my-place-table [row-id="${data.id}"]`).find('.took-my-place-icon').tooltip('dispose');
  }

  private switchBanUser(params: CellClickedEvent) {
    this.adminMenuService.switchBanUser(params, this.gridOptions);
  }

  private getValidationOptions(overnight: TookMyPlaceDate): string {
    if (overnight.valid) {
      return (
        '<div class="button-cell">' +
        '<button id="' +
        overnight.id +
        '" class="validation-button rounded-pill invalidate px-4 c-pointer" data-id="invalidate">' +
        'invalidate' +
        '</button>' +
        '</div>'
      );
    } else {
      return (
        '<div class="button-cell">' +
        '<img alt="took_my_place_icon" data-placement="left" title="' +
        overnight.description +
        '" class="took-my-place-icon" src="' +
        CONSTS.ICON_URL.THEY_TOOK_MY_PLACE +
        '">' +
        '<button id="' +
        overnight.id +
        '" class="validation-button withdraw rounded-pill c-pointer" data-id="withdraw">' +
        'withdraw' +
        '</button> ' +
        '</div>'
      );
    }
  }

  private withDrawInvalidation(reportId: number) {
    this.adminMenuService
      .invalidateTookMyPlaceReport(reportId, true, this.fromDate, this.toDate, this.onlyValid, '')
      .subscribe({
        next: (result) => {
          this.updateRow(result);
        },
      });
  }

  private invalidateReport(params: CellClickedEvent, reportId: number) {
    let selectedReportId: TookMyPlaceDate;

    for (const report of params.data.took_my_places) {
      if (report.id === reportId) {
        selectedReportId = report;
      }
    }

    this.modalService
      .openCustomModal(
        InvalidateReportModalComponent,
        { reportId: selectedReportId.id, fromDate: this.fromDate, toDate: this.toDate, onlyValid: this.onlyValid },
        'edit-item-modal'
      )
      .then((result) => {
        this.updateRow(result);
      });
  }

  private updateRow(element: TookMyPlaceRow): void {
    const modifiedRow: TookMyPlaceRow = {
      ...element,
      expanded: true,
      took_my_places_count: element.took_my_places.length,
    };

    const transaction: RowDataTransaction = {
      update: [modifiedRow],
    };

    this.gridOptions.api.applyTransaction(transaction);

    this.extendGridOptions();

    this.gridOptions.api.refreshCells({ force: true });

    this.loaderService.hideLoader();
  }
}
