import { Component, ElementRef, ViewChild } from '@angular/core';
import { CONSTS } from '../../constants';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  showLoader: boolean = false;
  menuOpened: boolean = false;
  CONSTS = CONSTS;

  activeTab: string;

  @ViewChild('adminMenuContainer', { static: true }) adminMenuContainer: ElementRef;

  setActiveTab(activeTab: string) {
    if (this.activeTab === activeTab) {
      this.activeTab = null;
    }

    setTimeout(() => {
      this.activeTab = activeTab;
    });
  }

  openTab(activeTab: string) {
    this.showLoader = true;
    this.menuOpened = true;
    this.adminMenuContainer.nativeElement.style.top = 0;

    setTimeout(() => {
      this.setActiveTab(activeTab);
    }, 501);
  }

  hideTab() {
    this.menuOpened = false;
    this.adminMenuContainer.nativeElement.style.top = '100%';

    setTimeout(() => {
      this.activeTab = '';
    }, 501);
  }

  toggleTab(activeTab: string) {
    if (this.menuOpened) {
      this.hideTab();
    } else {
      this.openTab(activeTab);
    }
  }
}
