import { Injectable } from '@angular/core';
import { AuthService } from './modules/auth/auth.service';
import { appVersion } from '../assets/version';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {
  constructor(private authService: AuthService) {}

  public load(): Promise<boolean> {
    return this.authService.checkAuth();
  }

  getVersion() {
    return environment.name + ' - v' + appVersion.version;
  }
}
