<div class="inner-footer mobile">
    <div *ngIf="!isParkPlaceSelect">
        <div class="mobile-reception-call-container d-inline-block">
            <div class="mobile-login-reception-call d-inline-block">
                <div class="mobile-reception-call-text">reception</div>

                <div class="mobile-reception-call-image">
                    <a href="{{ loginReceptionImage.link }}">
                        <img alt="reception call"
                             [title]="loginReceptionImage.title"
                             src="{{ CONSTS.ICON_URL.RECEPTION_CALL }}"
                        />
                    </a>
                </div>

                <div class="mobile-reception-call-text">{{ receptionNumber }}</div>
            </div>

            <app-version [mobile]="true"></app-version>
        </div>
    </div>

    <!-- -----------------------
        * FLOOR-SELECT
        * ------------------------>
    <ng-container *ngIf="isFloorSelect">
        <!-- SHOW MY RESERVATION TEMPORARY ICON -->
        <div *ngIf="reservationStatusExist"
             class="show-my-parking-place-icon-mobile d-inline-block"
        >
            <app-clickable-image
                    [title]="showMyReservationImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
                    [imageText]=""
                    [showTextOnMobile]="true"
                    (onImageClick)="showMyReservation()"
            ></app-clickable-image>
        </div>
        <!-- SHOW MY RESERVATION PARKED ICON -->
        <div *ngIf="reservationStatusExist"
             class="show-my-parking-place-icon-mobile d-inline-block"
        >
            <app-clickable-image
                    [title]="showMyReservationImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.PARKED"
                    [imageText]=""
                    [showTextOnMobile]="true"
                    (onImageClick)="showMyReservation()"
            ></app-clickable-image>
        </div>

        <!-- I AM WITH BICYCLE ICON -->
        <div *ngIf="showIamWithBikeIcon"
             [class.i-am-with-bike-icon-mobile]="isMobile"
        >
            <app-clickable-image
                    [title]="bicycleImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.BICYCLE"
                    [imageText]="bicycleImage.text"
                    (onImageClick)="iAmWithBike(true)"
            ></app-clickable-image>
        </div>
        <!-- I AM WITH CAR ICON -->
        <div *ngIf="userWithBike" [class.i-am-with-bike-icon-mobile]="isMobile">
            <app-clickable-image
                    [title]="carImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.CAR"
                    [imageText]="carImage.text"
                    (onImageClick)="iAmWithBike(false)"
            ></app-clickable-image>
        </div>

        <!-- FAVOURITES-ICON -->
        <div *ngIf="!reservationStatusExist"
             [class.favourites-mobile]="isMobile"
             class="parked d-inline-block"
        >
            <app-clickable-image
                    [title]="favouritesImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.FAVOURITES"
                    [imageText]="favouritesImage.text"
                    (onImageClick)="openFavouritesModal()"
                    [columnDirection]="true"
            ></app-clickable-image>
        </div>
    </ng-container>

    <!-- -----------------------
        * PARK PLACE SELECT
        * ------------------------>
    <ng-container *ngIf="isParkPlaceSelect">
        <div class="aligner">
            <div class="reception-call-icon-container">
                <!-- RECEPTION CALL -->
                <div class="login-reception-call d-inline-block">
                    <app-clickable-image
                            [title]="loginReceptionImage.title"
                            [imageHeight]="_mediumImageSize"
                            [imageSrc]="CONSTS.ICON_URL.RECEPTION_CALL"
                            [imageText]="loginReceptionImage.text"
                            [imageLinkHref]="loginReceptionImage.link"
                    ></app-clickable-image>
                </div>
            </div>

            <ng-container *ngIf="!customMode">
                <!-- PARKING-PLACE-SELECT-ICON -->
                <div *ngIf="showParkPlaceSelect"
                     class="parking-place-select d-inline-block"
                     [ngClass]="{
                         'change-parking-slot': reservationStatus.changeParkingSlot
                     }"
                >
                    <app-clickable-image
                            [title]="pickParkingPlaceImage.title"
                            [imageHeight]="_bigImageSize"
                            [imageSrc]="CONSTS.ICON_URL.PICK_PARKING_PLACE"
                            [imageText]="
                              reservationStatus.changeParkingSlot
                                ? pickNewParkingPlaceImage.text
                                : pickParkingPlaceImage.text
                            "
                            [showTextOnMobile]="true"
                            [hoverClass]="'no-hover'"
                    ></app-clickable-image>
                </div>

                <!-- PARKED-ICON -->
                <div *ngIf="reservationStatusExist && showParked"
                     class="parked d-inline-block mobile-right-icon"
                >
                    <app-clickable-image
                            [title]="parkedImage.title"
                            [imageHeight]="_bigSmallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.PARKED"
                            [imageText]="parkedImage.text"
                            (onImageClick)="finalizeReservation()"
                    ></app-clickable-image>
                </div>
            </ng-container>

            <ng-container *ngIf="!favouriteSelect && reservationStatusExist">
                <!-- SWAP PARKING PLACE ICON -->
                <div *ngIf="showTheyTookMyPlace"
                     class="d-inline-block mobile-right-icon"
                >
                    <app-clickable-image
                            [title]="changeParkingSlotImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.SWAP_PLACE"
                            [imageText]="changeParkingSlotImage.text"
                            (onImageClick)="changeParkingSlot()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- THEY TOOK MY PLACE ICON -->
                <div *ngIf="showTheyTookMyPlace"
                     class="d-inline-block mobile-right-icon"
                >
                    <app-clickable-image
                            [title]="theyTookMyPlaceImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
                            [imageText]="theyTookMyPlaceImage.text"
                            (onImageClick)="theyTookMyPlace()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- I AM WITH BICYCLE ICON -->
                <div *ngIf="showIamWithBikeIcon && showTheyTookMyPlace"
                     class="d-inline-block mobile-right-icon"
                >
                    <app-clickable-image
                            [title]="bicycleImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.BICYCLE"
                            [imageText]="bicycleImage.text"
                            (onImageClick)="iAmWithBike(true)"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- CANCEL RESERVATION ICON -->
                <div *ngIf="showCancel" class="d-inline-block mobile-right-icon">
                    <app-clickable-image
                            [title]="cancelReservationImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.CANCEL_RESERVATION"
                            [imageText]="cancelReservationImage.text"
                            (onImageClick)="cancelReservation()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- CANCEL SWAP RESERVATION ICON -->
                <div *ngIf="showCancelSwap" class="d-inline-block mobile-right-icon">
                    <app-clickable-image
                            [title]="cancelReservationChangeImage.title"
                            [imageHeight]="_miniImageSize"
                            [imageSrc]="CONSTS.ICON_URL.CLOSE_WHITE"
                            [imageText]="cancelReservationChangeImage.text"
                            (onImageClick)="cancelChangeReservation()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>
            </ng-container>

            <ng-container *ngIf="favouriteSelect">
                <!-- FAVOURITE-PICK-PLACE-ICON -->
                <div class="parking-place-select d-inline-block">
                    <app-clickable-image
                            [title]="favouriteSelectOkImage.title"
                            [imageHeight]="_bigImageSize"
                            [showTextOnMobile]="true"
                            [imageSrc]="CONSTS.ICON_URL.PICK_PARKING_PLACE_BLUE"
                            [imageText]="favouriteSelectOkImageMobile.text"
                    ></app-clickable-image>
                </div>

                <div class="other-icon-container">
                    <!-- FAVOURITE CANCEL ICON -->
                    <div class="d-inline-block">
                        <app-clickable-image
                                [title]="favouriteCancelImage.title"
                                [imageHeight]="_smallImageSize"
                                [imageSrc]="CONSTS.ICON_URL.DELETE_BLACK"
                                [imageText]="favouriteCancelImage.text"
                                (onImageClick)="cancelAddFavourite()"
                                [columnDirection]="true"
                        ></app-clickable-image>
                    </div>
                </div>
            </ng-container>

            <!-- I AM WITH CAR ICON -->
            <div *ngIf="userWithBike" class="pps-with-car d-inline-block">
                <app-clickable-image
                        [title]="carImage.title"
                        [imageHeight]="_bigImageSize"
                        [imageSrc]="CONSTS.ICON_URL.CAR"
                        [imageText]="carImage.text"
                        (onImageClick)="iAmWithBike(false)"
                        [hoverClass]="'no-hover'"
                ></app-clickable-image>
            </div>
        </div>
    </ng-container>
</div>
