import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../classes/ModalConfig';
import { Helper } from '../../../../helper';
import { CONSTS } from '../../../../constants';
import { DeviceService } from '../../../../services/device.service';
import { ParkPlaceSelectCommonService } from '../../../park-place-select/services/park-place-select-common.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @Input() modalConfig: ModalConfig;
  @ViewChild('plateNumberInput') plateNumberInput: ElementRef;

  plateNumber: string = '';
  text: string;
  isMobile: boolean;

  CONSTS = CONSTS;

  constructor(
    public activeModal: NgbActiveModal,
    private ppsCommonService: ParkPlaceSelectCommonService,
    private modalService: NgbModal,
    private deviceService: DeviceService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    if (!this.modalConfig.confirmButtonText) this.modalConfig.confirmButtonText = 'YES';
    if (!this.modalConfig.cancelButtonText) this.modalConfig.cancelButtonText = 'NOT YET';
    if (!this.modalConfig.footerCustomClass) this.modalConfig.footerCustomClass = '';
  }

  confirmModal() {
    if (this.plateNumber) {
      if (Helper.validateByRegex(this.plateNumber, CONSTS.PATTERNS.PLATE_NUMBER_HUNGARY)) {
        this.checkPlateNumberExist();
      } else {
        // CUSTOM MODAL OPEN TO PREVENT CIRCULAR DEPENDENCY WITH MODAL-SERVICE
        let windowClass = '';
        if (this.isMobile) {
          windowClass += 'dark-modal mobile';
        }

        const modalRef = this.modalService.open(ConfirmModalComponent, {
          windowClass: windowClass,
          backdrop: 'static',
        });
        modalRef.componentInstance.modalConfig = new ModalConfig(
          CONSTS.ICON_URL.THEY_TOOK_MY_PLACE,
          CONSTS.MODAL.NOT_HUNGARIAN_PLATE
        );
        modalRef.result.then(() => {
          this.checkPlateNumberExist();
        });
      }
    } else {
      this.closeModal();
    }
  }

  checkPlateNumberExist() {
    this.ppsCommonService.validatePlateNumberExist(this.plateNumber).subscribe({
      next: (result) => {
        if (result['licence_plate_number']) {
          this.closeModal();
        }
      },
      error: () => {
        let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.PLATE_NUMBER_NOT_EXIST);

        this.openInnerConfirmModal(config).then(() => {
          this.closeModal();
        });
      },
    });
  }

  closeModal() {
    let data: { [key: string]: string } = {};

    if (this.modalConfig.plateNumberInput) {
      data['plateNumber'] = this.plateNumber;
    }
    if (this.modalConfig.textInput) {
      data['text'] = this.text;
    }

    this.activeModal.close(data);
  }

  jumpToParkingPlaceFloor() {
    this.activeModal.close({ jump_to_floor: true });
  }

  checkPlateNumberInput() {
    if (this.modalConfig.plateNumberInput) {
      return this.plateNumber && Helper.validateByRegex(this.plateNumber, CONSTS.PATTERNS.PLATE_NUMBER);
    } else {
      return true;
    }
  }

  iAmWithBike() {
    let config = new ModalConfig(CONSTS.ICON_URL.BICYCLE, CONSTS.MODAL.CONFIRM_WITH_BICYCLE);

    this.openInnerConfirmModal(config).then(() => {
      this.activeModal.close({ i_am_with_bike: true });
    });
  }

  openInnerConfirmModal(config: ModalConfig) {
    let windowClass = '';
    if (this.isMobile) {
      windowClass = ' dark-modal mobile ';
    }
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: windowClass,
      backdrop: 'static',
    });
    modalRef.componentInstance.modalConfig = config;

    return modalRef.result;
  }

  get showContainer() {
    return (
      this.modalConfig.text ||
      this.modalConfig.textInput ||
      this.modalConfig.plateNumberInput ||
      this.modalConfig.floorPlace ||
      this.modalConfig.subTitle ||
      this.modalConfig.licencePlateNumber ||
      this.modalConfig.showWithBicycle
    );
  }
}
