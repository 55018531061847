import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LicencePlateNumber } from '../../../classes/LicencePlateNumber';
import { CONSTS } from '../../../constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ParkingSlot } from '../classes/ParkingSlot';
import { Reservation } from '../classes/Reservation';
import { HttpService } from '../../../services/http.service';
import { LoaderService } from '../../../services/loader.service';
import { User } from '../../../classes/User';
import { PreReservation } from '../classes/PreReservation';
import { TheyTookMyPlaceResponse } from './classes/TheyTookMyPlaceResponse';

@Injectable()
export class ParkPlaceSelectAdminService {
  private selectionStatus = new BehaviorSubject<ParkingSlot[]>([]);
  private refreshParkingSlots = new Subject<Array<ParkingSlot>>();

  private preReservationTabOpened = new Subject<boolean>();

  public swapPlaceMode = new BehaviorSubject<boolean>(false);

  constructor(protected httpService: HttpService, protected loaderService: LoaderService) {}

  setSelectionStatus(status: Array<ParkingSlot>) {
    console.log('selection Status changed: ', status);

    this.selectionStatus.next(status);
  }

  getPreReservationTabOpened(): Observable<boolean> {
    return this.preReservationTabOpened.asObservable();
  }

  setPreReservationTabOpened(opened: boolean) {
    console.log('selection Status changed: ', opened);
    this.preReservationTabOpened.next(opened);
  }

  getSelectionStatus(): Observable<ParkingSlot[]> {
    return this.selectionStatus.asObservable();
  }

  getSelectionStatusSnapshot(): ParkingSlot[] {
    return this.selectionStatus.value;
  }

  setSwapPlaceMode(swapPlaceMode: boolean) {
    this.swapPlaceMode.next(swapPlaceMode);
  }

  getSwapPlaceMode(): Observable<boolean> {
    return this.swapPlaceMode.asObservable();
  }

  // admin chart listens for this and will hide loader after parking place refresh has finished!
  forceParkingSlotsRefresh(parkingSlots: Array<ParkingSlot> = null) {
    this.refreshParkingSlots.next(parkingSlots);

    if (parkingSlots !== null) {
      this.setSelectionStatus(parkingSlots);
    }
  }

  getRefreshParkingSlotsSub() {
    return this.refreshParkingSlots.asObservable();
  }

  getFreePrivatePlaces(): Observable<ParkingSlot[]> {
    return this.httpService.get(`${CONSTS.API_END_POINT.PARKING_SLOT}/free-private-places`);
  }

  getAdminParkingSlots(floor: number, ignoreError = false): Observable<Array<ParkingSlot>> {
    return this.httpService.get(CONSTS.API_END_POINT.PARKING_SLOT + '/' + floor, null, ignoreError).pipe(
      map((result) => {
        return <Array<ParkingSlot>>result.parking_slots;
      })
    );
  }

  getLicencePlateNumbers(role: string): Observable<Array<LicencePlateNumber>> {
    return this.httpService.get(CONSTS.API_END_POINT.LICENCE_PLATE_NUMBER + '/' + role).pipe(
      map((result) => {
        return <Array<LicencePlateNumber>>result.licence_plate_numbers;
      })
    );
  }

  getUsers(): Observable<Array<User>> {
    return this.httpService.get(CONSTS.API_END_POINT.USER).pipe(
      map((result) => {
        return <Array<User>>result.users;
      })
    );
  }

  reserveParkingSlot(
    parkingSlot: ParkingSlot,
    selectedUserId: number,
    is_final: boolean
  ): Observable<Reservation> {
    this.loaderService.showLoader();

    let data = {
      user_id: selectedUserId,
      parking_slot_id: parkingSlot.id,
      is_final: is_final,
    };
    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION, data).pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((reservation) => {
        this.updateParkingSlotReservation(parkingSlot, reservation);
      })
    );
  }

  reserveMultiParkingSlot(parkingSlot: ParkingSlot, selectedUserId: number): Observable<Reservation> {
    this.loaderService.showLoader();

    let data = {
      user_id: selectedUserId,
      is_final: true,
    };
    return this.httpService.put(CONSTS.API_END_POINT.RESERVATION + '/' + parkingSlot.reservation.id, data).pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((reservation) => {
        this.updateParkingSlotReservation(parkingSlot, reservation);
      })
    );
  }

  reserveNewVisitorParkingSlot(parkingSlot: ParkingSlot, visitor: User, is_final: boolean) {
    this.loaderService.showLoader();

    let data = {
      licence_plate_number: visitor.licence_plate_number,
      name: visitor.name,
      phone: visitor.phone,
      parking_slot_id: parkingSlot.id,
      is_final: is_final,
    };
    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION + '/visitor', data).pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((reservation) => {
        this.updateParkingSlotReservation(parkingSlot, reservation);
      })
    );
  }

  reserveNewVisitorMultiParkingSlot(parkingSlot: ParkingSlot, visitor: User) {
    this.loaderService.showLoader();

    let data = {
      licence_plate_number: visitor.licence_plate_number,
      name: visitor.name,
      phone: visitor.phone,
    };
    return this.httpService
      .put(CONSTS.API_END_POINT.RESERVATION + '/' + parkingSlot.reservation.id + '/multi', data)
      .pipe(
        map((result) => {
          return <Reservation>result.reservation;
        }),
        tap((reservation) => {
          this.updateParkingSlotReservation(parkingSlot, reservation);
        })
      );
  }

  finalizeAdminReservation(parkingSlot: ParkingSlot): Observable<Reservation> {
    this.loaderService.showLoader();

    return this.httpService
      .get(CONSTS.API_END_POINT.RESERVATION + '/' + parkingSlot.reservation.id + '/finalize')
      .pipe(
        map((result) => {
          return <Reservation>result.reservation;
        }),
        tap((reservation) => {
          this.updateParkingSlotReservation(parkingSlot, reservation);
        })
      );
  }

  updateParkingSlotReservation(parkingSlot: ParkingSlot, reservation: Reservation) {
    parkingSlot.reservation = reservation;
    this.forceParkingSlotsRefresh([parkingSlot]);
  }

  cancelAdminReservation(parkingSlot: ParkingSlot) {
    this.loaderService.showLoader();

    return this.httpService.delete(CONSTS.API_END_POINT.RESERVATION + '/' + parkingSlot.reservation.id).pipe(
      tap(() => {
        parkingSlot.reservation = null;

        this.forceParkingSlotsRefresh([parkingSlot]);
      })
    );
  }

  theyTookMyPlaceAdmin(licence_plate_number: string, slotId: number): Observable<TheyTookMyPlaceResponse> {
    let data = {
      parking_slot_id: slotId,
      licence_plate_number: licence_plate_number,
    };

    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION + '/place-taken', data).pipe(
      tap((result) => {
        if (result.reservation) {
          let parkingSlot = result.reservation.parking_slot;
          parkingSlot.reservation = result.reservation;

          this.forceParkingSlotsRefresh([result.parking_slot, parkingSlot]);
        }

        this.setSelectionStatus([result.parking_slot]);
      })
    );
  }

  setParkingSlotType(parkingSlotId: number, type: string) {
    this.loaderService.showLoader();

    let data = {
      type: type,
    };

    return this.httpService.put(CONSTS.API_END_POINT.PARKING_SLOT + '/' + parkingSlotId, data).pipe(
      tap((result) => {
        this.forceParkingSlotsRefresh([result.parking_slot]);
      })
    );
  }

  // ----------------
  // GROUP OPERATIONS
  // ----------------

  reserveMultiPlaces(parkingSlots: Array<ParkingSlot>, name: string) {
    let data = {
      name: name,
      parking_slot_ids: parkingSlots.map((parkingSlot: ParkingSlot) => {
        return parkingSlot.id;
      }),
    };

    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION + '/multi', data).pipe(
      tap((result) => {
        let resultParkingSlots = result.reservations.map((reservation: Reservation) => {
          let parkingSlot = reservation.parking_slot;
          parkingSlot.reservation = reservation;

          return parkingSlot;
        });

        this.forceParkingSlotsRefresh(resultParkingSlots);
      })
    );
  }

  setMultipleParkingSlotTypes(parkingSlots: Array<ParkingSlot>, type: string) {
    this.loaderService.showLoader();

    let data = {
      ids: parkingSlots.map((parkingSlot: ParkingSlot) => {
        return parkingSlot.id;
      }),
      type: type,
    };
    return this.httpService.post(CONSTS.API_END_POINT.PARKING_SLOT + '/update', data).pipe(
      tap((result) => {
        this.forceParkingSlotsRefresh(result.parking_slots);
      })
    );
  }

  swapParkingSlot(selectedParkingSlot: ParkingSlot, swapToParkingSlot: ParkingSlot): Observable<Reservation> {
    this.loaderService.showLoader();

    let data = {
      parking_slot_id: swapToParkingSlot.id,
    };

    return this.httpService
      .put(CONSTS.API_END_POINT.RESERVATION + '/' + selectedParkingSlot.reservation.id, data)
      .pipe(
        tap((result) => {
          let parkingSlot = result.reservation.parking_slot;
          parkingSlot.reservation = result.reservation;

          selectedParkingSlot.reservation = null;

          if (result.reservation) {
            this.forceParkingSlotsRefresh([parkingSlot, selectedParkingSlot]);
          }

          this.setSelectionStatus([]);

          this.setSwapPlaceMode(false);
          this.loaderService.hideLoader();
        }),
        map((result) => {
          return <Reservation>result.reservation;
        })
      );
  }

  getPreReservations(date: string, parkingSlotId: number): Observable<Array<PreReservation>> {
    return this.httpService
      .get(CONSTS.API_END_POINT.PRE_RESERVATION + '/' + date + '/parking-slot/' + parkingSlotId)
      .pipe(
        map((result) => {
          return <Array<PreReservation>>result.pre_reservations;
        })
      );
  }

  getPreReservationScheduled(parkingSlotId: number): Observable<Array<PreReservation>> {
    return this.httpService
      .get(CONSTS.API_END_POINT.PRE_RESERVATION + '/parking-slot/' + parkingSlotId + '/scheduled')
      .pipe(
        map((result) => {
          return <Array<PreReservation>>result.pre_reservations;
        })
      );
  }

  getAllPreReservationsByParkingSlots(date: string): Observable<Array<PreReservation>> {
    return this.httpService.get(CONSTS.API_END_POINT.PRE_RESERVATION + '/all/' + date).pipe(
      map((result) => {
        return <Array<PreReservation>>result.pre_reservations;
      })
    );
  }

  addPreReservation(preReservation: PreReservation): Observable<PreReservation> {
    this.loaderService.showLoader();

    return this.httpService.post(CONSTS.API_END_POINT.PRE_RESERVATION, preReservation).pipe(
      map((result) => {
        this.loaderService.hideLoader();
        return <PreReservation>result.pre_reservation;
      })
    );
  }

  updatePreReservation(preReservation: PreReservation): Observable<PreReservation> {
    this.loaderService.showLoader();

    return this.httpService
      .put(CONSTS.API_END_POINT.PRE_RESERVATION + '/' + preReservation.id, preReservation)
      .pipe(
        map((result) => {
          this.loaderService.hideLoader();
          return <PreReservation>result.pre_reservation;
        })
      );
  }

  deletePreReservation(preReservationId: number): Observable<PreReservation> {
    this.loaderService.showLoader();

    return this.httpService.delete(CONSTS.API_END_POINT.PRE_RESERVATION + '/' + preReservationId).pipe(
      tap(() => {
        this.loaderService.hideLoader();
      })
    );
  }
}
