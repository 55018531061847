import { D3GanttChartDataListItem } from './d3GanttChartDataListItem';
import { KeyOfType } from '../../../../../../classes/HelperTypes';

export class D3GanttConfig {
  floorNumber: number;
  hideXaxisText: boolean;
  field: {
    startDate: KeyOfType<D3GanttChartDataListItem, Date>;
    endDate: KeyOfType<D3GanttChartDataListItem, Date>;
    yAxisValue: KeyOfType<D3GanttChartDataListItem, any>;
  };
}
