import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../auth/auth.service';
import { ModalService } from '../../../../../../../services/modal.service';
import { ParkPlaceSelectCommonService } from '../../../../../services/park-place-select-common.service';
import * as d3 from 'd3';
import { ParkingSlot } from '../../../../../classes/ParkingSlot';
import { LoaderService } from '../../../../../../../services/loader.service';
import { ParkPlaceSelectAdminService } from '../../../../../services/park-place-select-admin.service';
import { ParkPlaceSelectGarageUserService } from '../../../../../services/park-place-select-garage-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTS } from '../../../../../../../constants';
import { DeviceService } from '../../../../../../../services/device.service';
import { environment } from '../../../../../../../../environments/environment';
import { FavouritesService } from '../../../../../../floor-select/services/favourites.service';
import { Tooltip } from '../../../../../../../classes/Tooltip';
import { d3Selection } from '../../../../all-pre-reservation-tab/d3-gantt-chart/classes/d3Selection';
import { MapChartIcons } from '../../../../../classes/map-chart-icons';

declare const $: JQueryStatic;

@Component({
  selector: 'app-map-chart-common',
  template: '<ng-content></ng-content>',
})
export class MapChartCommonComponent {
  @ViewChild('svgElement') svgElement: ElementRef;
  @ViewChild('svgGElement') svgGElement: ElementRef;
  @ViewChild('svgRectGElement') svgRectGElement: ElementRef;

  zoom: d3.ZoomBehavior<any, any>;
  zoomSub: Subscription;

  svgProps = {
    width: 0,
    height: 0,

    viewPortWidthScale: 0,
    viewPortHeightScale: 0,

    baseWidth: 2360,
    baseHeight: 1280,

    paddingY: 0,
    paddingX: 0,
  };

  isMobile: boolean;
  isAdminOrReceptionist: boolean;
  isGarageUser: boolean;
  isProduction: boolean;

  selectedElement: HTMLElement;
  selectedParkingSlot: number;

  tooltip: Tooltip = {
    reservation: null,
    flags: null,
    opacity: 1,
    top: '-100%',
    left: '-100%',
    display: 'none',
    endTime: '',
  };

  selectedInfoElement: HTMLElement;
  selectedInfoParkingSlot: number;

  tooltipParkingSlotInfo: Tooltip = {
    flags: null,
    opacity: 1,
    top: '-100%',
    left: '-100%',
    display: 'none',
  };

  // admin only tooltip
  selectedSwapPlace: ParkingSlot;
  selectedHtmlSwapPlace: HTMLElement;
  swapTooltip: Tooltip = {
    reservation: null,
    opacity: 1,
    top: '-100%',
    left: '-100%',
    display: 'none',
  };

  refreshData: boolean;
  d3RectGElement: d3Selection<SVGGElement>;

  floorName: string = null;
  mapContainer: JQuery<HTMLElement>;

  CONSTS = CONSTS;
  backend_url = environment.backend_url;

  previousParkingSlots: Array<ParkingSlot>;

  readonly TOP_RIGHT_ICON_WIDTH = 10;
  readonly TOP_RIGHT_ICON_HEIGHT = 11;
  readonly TOP_LEFT_ICON_WIDTH = 5;
  readonly TOP_LEFT_ICON_HEIGHT = 5;

  constructor(
    protected parkPlaceSelectAdminService: ParkPlaceSelectAdminService,
    protected parkPlaceSelectGarageUserService: ParkPlaceSelectGarageUserService,
    protected parkPlaceSelectCommonService: ParkPlaceSelectCommonService,
    protected favouriteService: FavouritesService,
    protected modalService: ModalService,
    protected authService: AuthService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected deviceService: DeviceService,
    protected loaderService: LoaderService,
    protected changeDetector: ChangeDetectorRef
  ) {}

  initCommon(floorNumber: number) {
    this.isMobile = this.deviceService.isMobile();
    this.loaderService.showLoader();
    this.isAdminOrReceptionist = this.authService.isAdminOrReceptionist;
    this.isGarageUser = this.authService.isGarageUser;
    this.refreshData = true;
    this.d3RectGElement = d3.select(this.svgRectGElement.nativeElement);
    this.mapContainer = $('.map-container.floor-' + floorNumber);

    this.svgProps['paddingY'] = this.isGarageUser ? 150 : 300;
    this.svgProps['paddingX'] = 300;
    this.isProduction = environment.production;
  }

  initMapCommon() {
    let svg = d3.select(this.svgElement.nativeElement);
    let svgG = d3.select(this.svgGElement.nativeElement);

    this.getSVGSize();

    this.zoom = d3
      .zoom()
      .scaleExtent([1, 8])
      .on('zoom', (event) => {
        event.transform.x = Math.min(
          this.svgProps.paddingX,
          Math.max(
            event.transform.x,
            (this.svgProps.width - this.svgProps.width * event.transform.k) * this.svgProps.viewPortWidthScale -
              this.svgProps.paddingX
          )
        );
        event.transform.y = Math.min(
          this.svgProps.paddingY,
          Math.max(
            event.transform.y,
            (this.svgProps.height - this.svgProps.height * event.transform.k) * this.svgProps.viewPortHeightScale -
              this.svgProps.paddingY
          )
        );

        svgG.attr('transform', event.transform);

        this.setTooltipPosition();
        this.setTooltipPosition(this.tooltipParkingSlotInfo, this.selectedInfoElement);
        this.setTooltipPosition(this.swapTooltip, this.selectedHtmlSwapPlace);
      });

    svg.call(this.zoom).on('dblclick.zoom', null);
  }

  initZoomSub() {
    // handle all zoom level change!
    this.zoomSub = this.parkPlaceSelectCommonService.getZoom().subscribe((factor) => {
      this.zoomByFactor(factor);
    });
  }

  zoomByFactor(factor: number) {
    let svg = d3.select(this.svgElement.nativeElement);
    this.zoom.scaleBy(svg, factor);
  }

  setSelectedParkingSlot(selected: HTMLElement, place: number) {
    this.selectedElement = selected;
    this.selectedParkingSlot = place;
  }

  showTooltip(
    endTime: string = null,
    tooltipObject = this.tooltip,
    selectedElement: HTMLElement = this.selectedElement
  ) {
    this.setTooltipPosition(tooltipObject, selectedElement);

    if (endTime) {
      tooltipObject.endTime = endTime;
    }
    tooltipObject.display = 'block';
  }

  hideTooltip(tooltipObject = this.tooltip, removeSelectedElement = true) {
    tooltipObject.display = 'none';
    tooltipObject.left = '-100%';
    tooltipObject.top = '-100%';

    if (removeSelectedElement) {
      this.setSelectedParkingSlot(null, null);
    }
  }

  setTooltipPosition(tooltipObject = this.tooltip, selectedElement: HTMLElement = this.selectedElement) {
    // safari fix. it scrolls html on touch
    let pageY = window.scrollY;

    if (selectedElement) {
      let BBox = selectedElement.getBoundingClientRect();
      tooltipObject.top = BBox.top + pageY + 'px';
      tooltipObject.left = BBox.left + BBox.width / 2 + 'px';
    }
  }

  addCustomIndicator(
    svgElement: d3Selection<any>,
    svg: string,
    x: number,
    y: number,
    transform: string,
    slotNumber: number,
    title: string
  ) {
    let rotate = 0;
    if (transform) {
      let transformParts = transform.split('rotate(');
      if (transformParts.length > 1) {
        rotate = parseFloat(transformParts[1]);
      }
    }

    let main = svgElement
      .append('g')
      .attr(
        'transform',
        'translate(' + x + ' ,' + y + ')scale(0.5)' + (rotate !== 0 ? 'rotate(' + rotate + ')' : '')
      )
      .attr('data-name', slotNumber);
    main.append('title').text(title);
    main.append('path').attr('fill', '#fff').attr('d', svg).style('cursor', 'help');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getSVGSize();
    this.setTooltipPosition();
    this.setTooltipPosition(this.swapTooltip, this.selectedHtmlSwapPlace);
  }

  getSVGSize() {
    // jquery is used because firefox don't get height from native element
    this.svgProps.width = $(this.svgElement.nativeElement).innerWidth();
    this.svgProps.height = $(this.svgElement.nativeElement).innerHeight();

    this.svgProps.viewPortWidthScale = this.svgProps.baseWidth / this.svgProps.width;
    this.svgProps.viewPortHeightScale = this.svgProps.baseHeight / this.svgProps.height;
  }

  addSVGText(
    svgElement: d3Selection<any>,
    x: number,
    y: number,
    transform: string,
    slotNumber: number,
    text: string,
    color: string,
    textAnchor: string = 'middle',
    fontSize: string = '10px',
    addDataName: string = null,
    title: string = null
  ): d3Selection<SVGTextElement> {
    let svg = svgElement
      .append('text')
      .attr('x', x + 'px')
      .attr('y', y + 'px')
      .style('fill', color)
      .style('font-size', fontSize)
      .style('font-family', 'EYInterstateBold')
      .style('font-weight', 700)
      .attr('text-anchor', textAnchor)
      .attr('alignment-baseline', 'middle')
      .attr('transform', transform)
      .attr('data-name', addDataName ? addDataName : slotNumber)
      .text(text);

    if (title) {
      svg.style('cursor', 'help');
      svg.append('title').text(title);
    }

    return svg;
  }

  setElementColorClass(selectedSVGElement: d3Selection<any>, className: string) {
    selectedSVGElement.classed('cian-blue-fill-color', className === 'cian-blue-fill-color');
    selectedSVGElement.classed('dark-green-fill-color', className === 'dark-green-fill-color');
    selectedSVGElement.classed('yellow-fill-color', className === 'yellow-fill-color');
    selectedSVGElement.classed('reserved-fill-color', className === 'reserved-fill-color');
  }

  floorNumberIsPageUrl(floorNumber: number) {
    return (
      (floorNumber === 1 && this.floorName === CONSTS.PAGE_URL_PARAM.FLOOR1) ||
      (floorNumber === 2 && this.floorName === CONSTS.PAGE_URL_PARAM.FLOOR2)
    );
  }

  destroyCommon() {
    this.svgProps = null;
    this.refreshData = false;

    d3.select('text[data-name]').remove(); // remove all appended texts
    d3.select('g[data-name]').remove(); // remove all appended indicators, will be only avaible on admin
  }

  placeSpecialFlagIconToSpot(
    svgElement: d3Selection<any>,
    SVGParkingSlot: d3Selection<any>,
    slotNumber: number
  ): void {
    this.addCustomIndicator(
      svgElement,
      MapChartIcons.flagIndicatorSvg,
      parseInt(SVGParkingSlot.attr('x')) +
        parseInt(SVGParkingSlot.attr('width')) -
        (this.TOP_RIGHT_ICON_WIDTH + 5),
      parseInt(SVGParkingSlot.attr('y')) + (this.TOP_RIGHT_ICON_HEIGHT + 9),
      SVGParkingSlot.attr('transform'),
      slotNumber,
      'this spot has special flags'
    );
  }

  placePrivateUserIconToSpot(
    svgElement: d3Selection<any>,
    SVGParkingSlot: d3Selection<any>,
    slotNumber: number
  ): void {
    this.addCustomIndicator(
      svgElement,
      MapChartIcons.privateUserIndicatorSvg,
      parseInt(SVGParkingSlot.attr('x')) + this.TOP_LEFT_ICON_WIDTH,
      parseInt(SVGParkingSlot.attr('y')) + this.TOP_LEFT_ICON_HEIGHT + 15,
      SVGParkingSlot.attr('transform'),
      slotNumber,
      'this spot has a private user'
    );
  }
}
