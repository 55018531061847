import { AfterViewInit, Component } from '@angular/core';
import { MapChartGarageUserComponent } from '../map-chart/map-chart-garage-user/map-chart-garage-user.component';

@Component({
  selector: 'app-map-chart-floor-1',
  templateUrl: './map-chart-floor-1.component.html',
  styleUrls: ['../map-chart/map-chart-common/map-chart-common.component.scss'],
})
export class MapChartFloor1Component extends MapChartGarageUserComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.init();
  }
}
