import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ParkingSlot } from '../../classes/ParkingSlot';
import { ModalService } from '../../../../services/modal.service';
import { ParkPlaceSelectAdminService } from '../../services/park-place-select-admin.service';
import { PreReservation } from '../../classes/PreReservation';
import { Subscription } from 'rxjs';
import { CellClickedEvent, GridOptions, RowDataTransaction, GetRowIdParams } from 'ag-grid-community';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { AdminPreReservationEditModalComponent } from './components/admin-pre-reservation-edit-modal/admin-pre-reservation-edit-modal.component';
import { ModalConfig } from '../../../shared/classes/ModalConfig';
import * as moment from 'moment';
import { CONSTS } from '../../../../constants';

@Component({
  selector: 'app-admin-pre-reservation-tab',
  templateUrl: './admin-pre-reservation-tab.component.html',
  styleUrls: ['./admin-pre-reservation-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPreReservationTabComponent implements OnInit, OnDestroy {
  @Input() parkingSlot: ParkingSlot;

  @ViewChild('preReservationTabContainer', { static: true }) alertTabContainer: ElementRef;

  preReservations: Array<PreReservation> = [];
  showLoader: boolean = true;
  CONSTS = CONSTS;
  gridOptions: GridOptions;

  TAB_WIDTH = '60rem';

  preReservationTabOpened: boolean;
  preReservationTabOpenedSub: Subscription;

  lastSelectedDate: Date;

  dataModified: boolean = false;
  showScheduled: boolean = true;

  getPreReservationListSub: Subscription;

  constructor(
    private modalService: ModalService,
    private parkPlaceSelectAdminService: ParkPlaceSelectAdminService
  ) {}

  ngOnInit() {
    let that = this;

    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      rowHeight: 50,
      headerHeight: 50,
      rowData: [],
      onGridReady: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as PreReservation;
        return rowData.id.toString();
      },
      onCellClicked: (params: CellClickedEvent) => {
        const eventTarget = params.event.target as HTMLElement;

        if (eventTarget.className === 'edit-cell-icon') {
          this.updatePreReservation(params.data);
        } else if (eventTarget.className === 'delete-cell-icon') {
          this.deletePreReservation(params.data.id);
        }
      },
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          cellClass: 'col-grey-color',
          sortable: false,
        },
        {
          headerName: 'Start',
          field: 'from_time',
          cellClass: 'col-grey-color',
          sortable: false,
        },
        {
          headerName: 'End',
          field: 'to_time',
          sortable: false,
          cellClass: 'col-grey-color',
        },
        {
          headerName: '',
          field: 'id',
          width: 110,
          resizable: false,
          sortable: false,
          filter: false,
          suppressSizeToFit: true,
          cellRenderer: () => {
            return (
              '<div class="edit-cell">' +
              '<img class="edit-cell-icon" title="edit pre-reservation" src="/assets/images/edit_icon.svg" />' +
              '</div>' +
              '<div class="delete-cell">' +
              '<img class="delete-cell-icon" title="remove pre-reservation" src="/assets/images/delete_icon.svg">' +
              '</div>'
            );
          },
        },
      ],
    };

    this.preReservationTabOpenedSub = this.parkPlaceSelectAdminService
      .getPreReservationTabOpened()
      .subscribe((opened) => {
        this.showLoader = true;

        this.preReservationTabOpened = opened;
        this.alertTabContainer.nativeElement.style.left = opened ? 'calc(100vw - ' + this.TAB_WIDTH + ')' : '100%';

        this.getPreReservationScheduledList();
      });
  }

  switchScheduleMode() {
    this.getPreReservationScheduledList();
  }

  switchHistoryMode() {
    this.getPreReservationList(this.lastSelectedDate ? this.lastSelectedDate : new Date());
  }

  getPreReservationList(selectedDate: Date) {
    this.showScheduled = false;

    const dateFormatted = moment(selectedDate).format(CONSTS.DATE_FORMAT);

    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
    }

    this.clearGetListSubscription();
    this.getPreReservationListSub = this.parkPlaceSelectAdminService
      .getPreReservations(dateFormatted, this.parkingSlot.id)
      .subscribe({
        next: (preReservations: Array<PreReservation>) => {
          this.setGridData(preReservations);
        },
        error: () => {
          this.setGridData([]);
        },
      });

    this.lastSelectedDate = selectedDate;
  }

  getPreReservationScheduledList() {
    this.showScheduled = true;

    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
    }

    this.clearGetListSubscription();
    this.getPreReservationListSub = this.parkPlaceSelectAdminService
      .getPreReservationScheduled(this.parkingSlot.id)
      .subscribe({
        next: (preReservations: Array<PreReservation>) => {
          this.setGridData(preReservations);
        },
        error: () => {
          this.setGridData([]);
        },
      });
  }

  closeTab() {
    this.parkPlaceSelectAdminService.setPreReservationTabOpened(false);

    if (this.dataModified) {
      this.parkPlaceSelectAdminService.forceParkingSlotsRefresh();
    }

    this.clearGetListSubscription();
  }

  setGridData(data: Array<PreReservation>) {
    this.preReservations = data;

    if (this.gridOptions.api) {
      this.gridOptions.api.setRowData(data);
    } else {
      this.gridOptions.rowData = data;
    }

    this.showLoader = false;
  }

  addPreReservation() {
    this.openModal(null, this.parkingSlot, false);
  }

  updatePreReservation(preReservation: PreReservation) {
    this.openModal(preReservation, this.parkingSlot, true);
  }

  deletePreReservation(preReservationId: number) {
    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.CANCEL_RESERVATION, CONSTS.MODAL.CANCEL_PRE_RESERVATION))
      .then(
        () => {
          this.parkPlaceSelectAdminService.deletePreReservation(preReservationId).subscribe(() => {
            let transaction: RowDataTransaction = {
              remove: [{ id: preReservationId }],
            };
            this.gridOptions.api.applyTransaction(transaction);
            this.dataModified = true;
          });
        },
        () => {
          // reject ignored
        }
      );
  }

  openModal(preReservation: PreReservation, parkingSlot: ParkingSlot, editMode: boolean) {
    let initDate = !this.showScheduled && !editMode ? this.lastSelectedDate : null;

    this.modalService
      .openCustomModal(
        AdminPreReservationEditModalComponent,
        {
          parkingSlot: parkingSlot,
          preReservation: preReservation,
          initDate: initDate,
        },
        'admin-reservation-modal'
      )
      .then(
        (newPreReservation: PreReservation) => {
          let transaction: RowDataTransaction;

          if (editMode) {
            transaction = {
              update: [newPreReservation],
            };
          } else {
            transaction = {
              add: [newPreReservation],
            };
          }
          this.gridOptions.api.applyTransaction(transaction);
          this.dataModified = true;
        },
        () => {
          // reject ignored
        }
      );
  }

  clearGetListSubscription() {
    if (this.getPreReservationListSub) {
      this.getPreReservationListSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.clearGetListSubscription();
    this.preReservationTabOpenedSub.unsubscribe();
  }
}
