import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tooltip } from '../../../../../../../classes/Tooltip';
import { CONSTS } from '../../../../../../../constants';
import { ReservationStatus } from '../../../../../classes/ReservationStatus';

@Component({
  selector: 'app-tooltip-garage-user-reservation',
  templateUrl: './tooltip-garage-user-reservation.component.html',
  styleUrls: ['./tooltip-garage-user-reservation.component.scss'],
})
export class TooltipGarageUserReservationComponent {
  @Input() tooltipData: Tooltip;
  @Input() floorNumber: number;
  @Input() reservationStatus: ReservationStatus;
  @Output() onTimeEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExtendReservation: EventEmitter<any> = new EventEmitter<any>();

  CONSTS = CONSTS;

  timeEndEvent() {
    this.onTimeEnd.emit();
  }

  extendEvent() {
    this.onExtendReservation.emit();
  }
}
