export const environment = {
  name: 'testing-ey',
  production: true,
  backend_url: 'https://parkingapi.eyapps.hu/api/',
  firebase: {
    apiKey: 'AIzaSyA6-8hAGE1FByzRvB5OjXzG97gFyLbjNQw',
    authDomain: 'ey-parking-testing.firebaseapp.com',
    projectId: 'ey-parking-testing',
    storageBucket: 'ey-parking-testing.appspot.com',
    messagingSenderId: '674847850844',
    appId: '1:674847850844:web:476b7c730ef9142c618199',
  },
};
