<app-modal-header [activeModal]="activeModal"></app-modal-header>

<form #userForm="ngForm">
    <div class="modal-body">
        <div class="form-container">
            <div class="park-place-modal-title">
                <div class="park-place-floor">Floor -{{ data.floor }}</div>
                <div class="park-place-slot">{{ data.slot_number }}</div>
            </div>

            <app-loader
                [showLoader]="!list.garageUser.LPNList || !list.visitor.LPNList">
            </app-loader>

            <div class="row">
                <div class="aligner col-12 title">
                    <div *ngIf="isUserTypeSelect">
                        {{ data.reservation && data.reservation.multi ? "Finalize" : "Reserve" }} this place for:
                    </div>

                    <div *ngIf="!isUserTypeSelect">
                        {{ data.reservation && data.reservation.multi ? "Finalize" : "Reserve" }} for:
                    </div>
                </div>

                <div class="aligner col-12">
                    <div *ngIf="isGarageUserSelect"
                         class="reserve-for-garage-user select-container">
                        <ng-select
                            name="garage-user-list"
                            [bindLabel]="'label'"
                            [bindValue]="'value'"
                            [placeholder]="'Choose user'"
                            [clearable]="false"
                            [items]="list.garageUser.userList"
                            [(ngModel)]="selectedIds.garageUser.userId"
                            (change)="selectUser($event)"
                        ></ng-select>
                    </div>

                    <div
                        *ngIf="isVisitorSelect"
                        class="reserve-for-visitor select-container">
                        <ng-select
                            name="visitor-list"
                            [bindLabel]="'label'"
                            [bindValue]="'value'"
                            [placeholder]="'Choose user'"
                            [clearable]="false"
                            [items]="list.visitor.userList"
                            [(ngModel)]="selectedIds.visitor.userId"
                            (change)="selectUser($event)">
                        </ng-select>
                    </div>
                </div>
            </div>

            <div *ngIf="!isUserTypeSelect" class="user-details row">

                <div class="aligner col-12">
                    <input name="name"
                           [ngClass]="{
                              'has-error': name.errors?.required && (name.dirty || name.touched)
                           }"
                           required
                           #name="ngModel"
                           placeholder="User Name"
                           class="form-control"
                           [(ngModel)]="selectedUser.name"
                           [disabled]="!newVisitor"
                    />
                </div>

                <div class="aligner col-6">
                    <div *ngIf="!newVisitor">
                        <ng-select
                            *ngIf="isGarageUserSelect"
                            name="garage-user-LPN-list"
                            [placeholder]="'Choose plate number'"
                            [bindValue]="'value'"
                            [bindLabel]="'label'"
                            [clearable]="false"
                            [typeToSearchText]="'Type to search...'"
                            [items]="list.garageUser.LPNList"
                            [(ngModel)]="selectedIds.garageUser.LPNId"
                            (change)="selectUserByPlate($event)"
                        ></ng-select>

                        <ng-select
                            *ngIf="isVisitorSelect"
                            name="visitor-LPN-list"
                            [placeholder]="'Choose plate number'"
                            [bindValue]="'value'"
                            [bindLabel]="'label'"
                            [clearable]="false"
                            [items]="list.visitor.LPNList"
                            [(ngModel)]="selectedIds.visitor.LPNId"
                            (change)="selectUserByPlate($event)"
                        ></ng-select>
                    </div>

                    <div *ngIf="newVisitor">
                        <input [ngClass]="{
                                'has-error':
                                    licence_plate_number.errors &&
                                    (licence_plate_number.dirty || licence_plate_number.touched)
                                }"
                               #licence_plate_number="ngModel"
                               class="form-control"
                               name="licence_plate_number"
                               required
                               [pattern]="CONSTS.PATTERNS.PLATE_NUMBER"
                               [placeholder]="CONSTS.PLATE_NUMBER_FORMAT"
                               [(ngModel)]="selectedUser.licence_plate_number"
                               [disabled]="!newVisitor"
                        />
                    </div>
                </div>
                <div class="aligner col-6">
                    <input
                        [ngClass]="{
                              'has-error': phone.errors && (phone.dirty || phone.touched)
                            }"
                        #phone="ngModel"
                        name="phone"
                        class="form-control"
                        required
                        placeholder="+36201234567"
                        [pattern]="CONSTS.PATTERNS.PHONE"
                        [(ngModel)]="selectedUser.phone"
                        [disabled]="!newVisitor"/>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div *ngIf="isUserTypeSelect" class="select-user-type">

            <div (click)="showVisitorSelect()" class="modal-button-link">
                <img [src]="CONSTS.ICON_URL.VISITOR" alt="visitor"/>
                <a>VISITOR</a>
            </div>

            <div (click)="showGarageUserSelect()" class="modal-button-link">
                <img [src]="CONSTS.ICON_URL.USER" alt="user"/>
                <a>REGISTERED USER</a>
            </div>
        </div>

        <div *ngIf="!isUserTypeSelect" class="reserve">

            <div class="modal-button-link">
                <app-clickable-image
                    [imageHeight]="'3rem'"
                    [imageSrc]="CONSTS.ICON_URL.CANCEL_RESERVATION"
                    [imageText]="'cancel'"
                    (onImageClick)="showUserTypeSelect()"
                    [columnDirection]="true"
                    [disableAnimation]="true"
                ></app-clickable-image>
            </div>

            <div *ngIf="!(data.reservation && this.data.reservation.multi)"
                 class="modal-button-link">
                <app-clickable-image
                    [disabled]="!userForm.valid || !isUserExist"
                    [imageHeight]="'3rem'"
                    [imageSrc]="CONSTS.ICON_URL.SHOW_MY_RESERVATION"
                    [imageText]="'reserve'"
                    (onImageClick)="reserveParkingSlot(false)"
                    [columnDirection]="true"
                    [disableAnimation]="true"
                ></app-clickable-image>
            </div>

            <div class="modal-button-link">
                <app-clickable-image
                    [disabled]="!userForm.valid || !isUserExist"
                    [imageHeight]="'3rem'"
                    [imageSrc]="CONSTS.ICON_URL.PARKED"
                    [imageText]="'arrived'"
                    (onImageClick)="reserveParkingSlot(true)"
                    [columnDirection]="true"
                    [disableAnimation]="true"
                ></app-clickable-image>
            </div>
        </div>
    </div>
</form>
