import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FooterCommonComponent } from '../footer-common/footer-common.component';
import { ParkingSlot } from '../../../modules/park-place-select/classes/ParkingSlot';
import { CONSTS } from '../../../constants';
import { AdminReservationModalComponent } from '../../../modules/park-place-select/components/admin-reservation-modal/admin-reservation-modal.component';
import { ModalConfig } from '../../../modules/shared/classes/ModalConfig';
import { AdminComponent } from '../../../modules/admin/admin.component';
import { FloorPlaceCounts } from '../../../modules/floor-select/classes/floorPlaceCounts';
import { ClickableImageConfig } from '../../../modules/shared/components/clickable-image/classes/clickableImageConfig';
import { ModalConfigConsts } from '../../../modules/shared/classes/ModalConfigConsts';
import { TheyTookMyPlaceResponse } from '../../../modules/park-place-select/services/classes/TheyTookMyPlaceResponse';
import { AddFlagToParkingSlotModalComponent } from '../../../modules/park-place-select/components/add-flag-to-parking-slot-modal/add-flag-to-parking-slot-modal.component';

@Component({
  selector: 'app-footer-admin',
  templateUrl: './footer-admin.component.html',
  styleUrls: ['./footer-admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterAdminComponent extends FooterCommonComponent implements OnInit {
  @ViewChild(AdminComponent) adminMenu: AdminComponent;

  reservePlaceImage: ClickableImageConfig;
  reservePlacesImage: ClickableImageConfig;
  selectParkingPlacesImage: ClickableImageConfig;
  managePreReservedTimesImage: ClickableImageConfig;

  freeUpPlaceImage: ClickableImageConfig;
  swapPlaceMode: boolean;

  allSelectedIsReservable: boolean;
  allSelectedHasAnyReservation: boolean;

  selectionStatus: ParkingSlot[] = [];

  ngOnInit() {
    this.initCommon();

    this.parkPlaceSelectAdminService.getSwapPlaceMode().subscribe((swapPlaceMode) => {
      this.swapPlaceMode = swapPlaceMode;
    });

    this.parkPlaceSelectAdminService.getSelectionStatus().subscribe((selectionStatus) => {
      this.selectionStatus = selectionStatus;

      this.setAllSelectedIsReservable();
      this.setAllSelectedHasAnyReservation();
    });

    this.parkedImage = {
      text: 'User Parked',
      link: '',
      title: 'finalize reservation',
    };

    this.reservePlaceImage = {
      text: 'reserve place',
      link: '',
      title: 'reserve parking place',
    };

    this.reservePlacesImage = {
      text: 'reserve places',
      link: '',
      title: 'reserve parking places',
    };

    this.theyTookMyPlaceImage = {
      text: 'someone took this place',
      link: '',
      title: 'report somebody took this place',
    };

    this.selectParkingPlacesImage = {
      text: 'Select Parking Places',
      link: '',
      title: 'select parking place',
    };

    this.freeUpPlaceImage = {
      text: 'Free up place',
      link: '',
      title: 'free up place',
    };

    this.managePreReservedTimesImage = {
      text: 'booking',
      link: '',
      title: 'booking',
    };
  }

  get onlyOneSelected() {
    return this.selectionStatus.length === 1;
  }

  get nonEySelected(): boolean {
    return this.selectionStatus[0].type === CONSTS.PARKING_SLOT_TYPES.NONEY;
  }

  get moreThanOneSelected() {
    return this.selectionStatus.length > 1;
  }

  get isAnySelected() {
    return this.selectionStatus.length > 0;
  }

  get selectedHasPrivateUser(): boolean {
    return !!this.selectionStatus[0].private_user;
  }

  get firstSelectedHasAnyReservation() {
    return this.isAnySelected ? !!this.selectionStatus[0].reservation : false;
  }

  get firstSelectedHasMultiReservation() {
    return this.isAnySelected ? !!this.selectionStatus[0].reservation?.multi : false;
  }

  get firstSelectedHasBookedReservation() {
    if (this.isAnySelected) {
      return this.selectionStatus[0].reservation?.pre_reserved;
    } else {
      return false;
    }
  }

  get firstSelectedHasPreReservation() {
    if (this.isAnySelected) {
      return this.selectionStatus[0].reservation?.is_final === false;
    } else {
      return false;
    }
  }

  get firstSelectedHasFinalReservation() {
    if (this.isAnySelected) {
      return !!this.selectionStatus[0].reservation?.is_final;
    } else {
      return false;
    }
  }

  private setAllSelectedIsReservable() {
    if (this.isAnySelected) {
      this.allSelectedIsReservable = !this.selectionStatus.find(
        (selected) =>
          selected.type === CONSTS.PARKING_SLOT_TYPES.PRIVATE || selected.type === CONSTS.PARKING_SLOT_TYPES.NONEY
      );
    } else {
      this.allSelectedIsReservable = false;
    }
  }

  private setAllSelectedHasAnyReservation() {
    if (this.isAnySelected) {
      this.allSelectedHasAnyReservation = !!this.selectionStatus.find((selected) => selected.reservation);
    } else {
      this.allSelectedHasAnyReservation = false;
    }
  }

  openAdminMenu(activeTab: string) {
    this.adminMenu.toggleTab(activeTab);
  }

  isFirstSelectionType(type: string) {
    return this.selectionStatus.length > 0 ? this.selectionStatus[0].type === type : false;
  }

  finalizeOnePlace() {
    let reservation = this.selectionStatus[0].reservation;

    if (reservation.multi) {
      this.reserveOnePlace();
    } else {
      let modalConfig: Partial<ModalConfigConsts> = CONSTS.MODAL.FINALIZE_RESERVATION;

      if (reservation.user.licence_plate_numbers) {
        modalConfig['LICENCE_PLATE_NUMBER'] = reservation.user.licence_plate_numbers[0].licence_plate_number;

        this.modalService.openConfirmModal(new ModalConfig(CONSTS.ICON_URL.PARKED, modalConfig)).then(
          () => {
            this.parkPlaceSelectAdminService.finalizeAdminReservation(this.selectionStatus[0]).subscribe();
          },
          () => {
            // reject ignored
          }
        );
      } else {
        this.modalService.openAlertModal('missing_licence_plate_number');
      }
    }
  }

  reserveOnePlace() {
    this.modalService.openCustomModal(
      AdminReservationModalComponent,
      this.selectionStatus[0],
      'admin-reservation-modal'
    );
  }

  reserveMorePlace() {
    this.modalService.openConfirmModal(new ModalConfig(null, CONSTS.MODAL.RESERVE_FOR_GROUP)).then(
      (result) => {
        this.parkPlaceSelectAdminService.reserveMultiPlaces(this.selectionStatus, result.text).subscribe();
      },
      () => {
        // reject ignored
      }
    );
  }

  cancelOnePlace(isFinal: boolean) {
    this.modalService
      .openConfirmModal(
        new ModalConfig(
          CONSTS.ICON_URL.CANCEL_RESERVATION,
          isFinal ? CONSTS.MODAL.FREE_UP_RESERVATION : CONSTS.MODAL.CANCEL_ADMIN_RESERVATION
        )
      )
      .then(
        () => {
          this.parkPlaceSelectAdminService.cancelAdminReservation(this.selectionStatus[0]).subscribe();
        },
        () => {
          // refect ignored
        }
      );
  }

  tookMyPlaceOnePlace() {
    let slotId = this.selectionStatus[0].id;

    this.modalService
      .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.THEY_TOOK_MY_PLACE_ADMIN))
      .then(
        (result) => {
          this.parkPlaceSelectAdminService
            .theyTookMyPlaceAdmin(result.plateNumber, slotId)
            .subscribe((response: TheyTookMyPlaceResponse) => {
              this.handleTheyTookMyPlaceResult(response, (newParkingSlot: ParkingSlot) => {
                // if user discarded his new place,
                // remove the given reservation and display choose new parking place!

                if (response.reservation) {
                  this.parkPlaceSelectAdminService.cancelAdminReservation(newParkingSlot).subscribe(() => {
                    let config = new ModalConfig(
                      CONSTS.ICON_URL.THEY_TOOK_MY_PLACE,
                      CONSTS.MODAL.SELECT_NEW_PARKING_PLACE_ADMIN
                    );
                    config.licencePlateNumber =
                      response.reservation.user.licence_plate_numbers[0].licence_plate_number;

                    this.modalService.openConfirmModal(config);
                  });
                }
              });

              this.parkPlaceSelectAdminService.forceParkingSlotsRefresh();
            });
        },
        () => {
          // reject ignored
        }
      );
  }

  setParkingSlotType(type: string) {
    if (this.selectionStatus.length === 1) {
      this.parkPlaceSelectAdminService.setParkingSlotType(this.selectionStatus[0].id, type).subscribe();
    } else {
      this.parkPlaceSelectAdminService.setMultipleParkingSlotTypes(this.selectionStatus, type).subscribe();
    }
  }

  swapParkingSlot() {
    this.loaderService.showLoader();

    this.floorSelectService.getFreePlaceAdminCount().subscribe({
      next: (result) => {
        let freePlaceCount = <FloorPlaceCounts>result;

        if (freePlaceCount.free_places.floor_1 > 0 || freePlaceCount.free_places.floor_2 > 0) {
          this.parkPlaceSelectAdminService.setSwapPlaceMode(true);

          let config = new ModalConfig(CONSTS.ICON_URL.SWAP_PLACE, CONSTS.MODAL.SELECT_NEW_PARKING_PLACE);
          this.modalService.openConfirmModal(config);
        } else {
          let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.HAVE_TO_LEAVE);

          this.modalService.openConfirmModal(config);
        }

        this.loaderService.hideLoader();
      },
      error: () => {
        this.loaderService.hideLoader();
      },
    });
  }

  cancelSwap() {
    this.parkPlaceSelectAdminService.setSwapPlaceMode(false);
  }

  get isSwapPlaceMode() {
    return this.swapPlaceMode;
  }

  showPreReservedTimes() {
    this.parkPlaceSelectAdminService.setPreReservationTabOpened(true);
  }

  chooseSpecialFlag(): void {
    this.modalService.openCustomModal(AddFlagToParkingSlotModalComponent).then(() => {
      this.parkPlaceSelectAdminService.forceParkingSlotsRefresh([this.selectionStatus[0]]);
    });
  }
}
