export class AgGridColumnContent {
  public static crudActionsCell() {
    return (
      '<div class="d-flex align-items-center justify-content-start"><div class="edit-cell">' +
      '<img class="edit-cell-icon normal-icon" title="edit flag" src="/assets/images/edit_icon.svg" alt="edit_icon"/>' +
      '<img class="edit-cell-icon hover-icon" title="edit flag" src="/assets/images/edit_white_icon.svg" alt="edit_icon"/>' +
      '</div>' +
      '<div class="delete-cell">' +
      '<img class="delete-cell-icon normal-icon" title="remove flag" src="/assets/images/delete_icon.svg" alt="delete-icon">' +
      '<img class="delete-cell-icon hover-icon" title="remove flag" src="/assets/images/delete_white_icon.svg" alt="delete-icon">' +
      '</div>'
    );
  }
}
