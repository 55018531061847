export class LicencePlateNumber {
  id?: number;
  licence_plate_number: string;
  primary?: boolean;
  user_id?: number;

  constructor(licence_plate_number: string = null) {
    this.licence_plate_number = licence_plate_number;
  }
}
